<div class="mat-elevation-z8">
  <mat-table matSort
             matSortActive="createdOn"
             matSortDirection="desc"
             multiTemplateDataRows
             [dataSource]="dataSource"
             aria-label="Booking failures">
    <ng-container matColumnDef="bookingRefNumber">
      <mat-header-cell *matHeaderCellDef
                       mat-sort-header>Booking Ref.</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.bookingRefNumber}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="channel">
      <mat-header-cell *matHeaderCellDef
                       mat-sort-header>Channel</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.channel}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="createdOn">
      <mat-header-cell *matHeaderCellDef
                       mat-sort-header>Created On</mat-header-cell>
      <mat-cell *matCellDef="let row">{{format(row.createdOn, 'yyyy-MM-dd HH:mm:ss')}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="lastRetryDate">
      <mat-header-cell *matHeaderCellDef
                       mat-sort-header>Last Retry</mat-header-cell>
      <mat-cell *matCellDef="let row">{{format(row.lastRetryDate, 'yyyy-MM-dd HH:mm:ss')}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="retryCount">
      <mat-header-cell *matHeaderCellDef
                       mat-sort-header>Retry Count</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.retryCount}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="status">
      <mat-header-cell *matHeaderCellDef
                       mat-sort-header>Status</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.status}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="expandedDetail">
      <mat-cell *matCellDef="let row"
                [attr.colspan]="displayedColumns.length">
        <div [@detailExpand]="row.id === expandedBookingFailure?.id ? 'expanded' : 'collapsed'"
             class="expandable-cell full-width-element">
          <booking-failure-logs-table *ngIf="row.id === expandedBookingFailure?.id"
                                      [bookingFailureLogs]="expandedBookingFailure.logs">
          </booking-failure-logs-table>
        </div>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"
             [class.expanded]="row.id === expandedBookingFailure?.id"
             (click)="onRowClick(row)"></mat-row>
    <mat-row *matRowDef="let row; columns: ['expandedDetail']"
             [class.collapsed]="row.id !== expandedBookingFailure?.id"
             class="expanded-row">
    </mat-row>
  </mat-table>

  <mat-paginator #paginator
                 [length]="dataSource?.data.length"
                 [pageIndex]="0"
                 [pageSize]="25"
                 [pageSizeOptions]="[10, 25, 50, 100]">
  </mat-paginator>
</div>
