<div class="mat-elevation-z1">
  <mat-table [dataSource]="dataSource"
             aria-label="Sendings">
    <ng-container matColumnDef="dateSent">
      <mat-header-cell *matHeaderCellDef>Date sent</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.dateSent}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="customerEmail">
      <mat-header-cell *matHeaderCellDef>Email</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.customerEmail}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="paymentUrl">
      <mat-header-cell *matHeaderCellDef>Url</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.paymentUrl}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="paymentStatus">
      <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.sendingStatus}}</mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>
</div>
