import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Activity } from '../models/activity';
import { GetActivityRequest } from '../models/get-activity-request';
import { MeetingType } from '../models/meeting-type.enum';
import { Place } from '../models/place';
import { BundleEditApiService } from './bundle-edit-api.service';

@Injectable({
  providedIn: 'root'
})
export class InvSysDataService {
  private _loadedData = new Map<number, Activity>();
  private _isLoading = new Map<number, boolean>();

  public get loadedData(): Map<number, Activity> {
    return this._loadedData;
  }

  public get isLoading(): Map<number, boolean> {
    return this._isLoading;
  }

  constructor(private bundleEditApiService: BundleEditApiService) { }

  loadActivity(params: GetActivityRequest): Observable<Activity> {
    this.isLoading.set(params.id, true);

    return this.bundleEditApiService.getActivity(params).pipe(
      map((res: Activity) => {
        this.isLoading.set(res.id, false);

        const { customPickupAllowed, customDropoffAllowed, places, meetingType } = res;

        const additionalPickupOptions = this.getAdditionalPickupOptions(customPickupAllowed, meetingType);
        const additionalDropoffOptions = this.getAdditionalDropoffOptions(customDropoffAllowed);
        const newPlaces = {
          pickupPlaces: [...additionalPickupOptions, ...places.pickupPlaces],
          dropoffPlaces: [...additionalDropoffOptions, ...places.dropoffPlaces]
        };
        const newActivity = { ...res, places: newPlaces };

        this.loadedData.set(res.id, newActivity);

        return newActivity;
      })
    );
  }

  loadActivities(paramsArray: GetActivityRequest[]): Observable<Activity[]> {
    this._isLoading = new Map(paramsArray.map((i): [number, boolean] => [i.id, true]));

    return this.bundleEditApiService.getActivities(paramsArray).pipe(
      tap((res: Activity[]) => {
        res.forEach(i => {
          this.isLoading.set(i.id, false);
          this.loadedData.set(i.id, i);
        });
      }));
  }

  private getAdditionalPickupOptions(customPickupAllowed: boolean, meetingType: MeetingType): Place[] {
    const options = [];

    if (meetingType === MeetingType.MeetOrPickup) {
      const dontNeedPickupOption = { id: -1, title: 'I don\'t need pickup' } as Place;
      options.push(dontNeedPickupOption);
    }

    if (customPickupAllowed) {
      const customPlaceOption = { id: -2, title: 'I will write down the address' } as Place;
      options.push(customPlaceOption);
    }

    return options;
  }

  private getAdditionalDropoffOptions(customDropoffAllowed: boolean): Place[] {
    const options = [];

    const dontNeedDropoffOption = { id: -1, title: 'I don\'t need dropoff' } as Place;
    options.push(dontNeedDropoffOption);

    if (customDropoffAllowed) {
      const customPlaceOption = { id: -2, title: 'I will write down the address' } as Place;
      options.push(customPlaceOption);
    }

    return options;
  }
}
