<form [formGroup]="form">
  <div class="go-back-section">
    <button fxFlex="0 0 40px" mat-icon-button matTooltip="Go back" class="go-back-button" (click)="goBack()">
      <mat-icon class="go-back-icon">keyboard_backspace</mat-icon>
    </button>
    <p class="mat-h1 go-back-label"><strong>{{ site?.name || 'New channel' }}</strong></p>
  </div>

  <div class="actions-line">
    <div>
      <a mat-flat-button class="navigation-button" matTooltip="Go to Calendar section"
        (click)="navigateToAnchor('calendar')">
        Calendar
      </a>
      <a mat-flat-button class="navigation-button" matTooltip="Go to Booking section"
        (click)="navigateToAnchor('booking')">
        Booking
      </a>
      <a mat-flat-button class="navigation-button" matTooltip="Go to Payment section"
        (click)="navigateToAnchor('payment')">
        Payment
      </a>
    </div>

    <div>
      <button (click)="$event.preventDefault()" mat-raised-button [matMenuTriggerFor]="chSettings" color="primary"
        aria-label="More menu">
        <mat-icon class="channel-settings-icon">settings</mat-icon>
        <span>Channel settings</span>
      </button>
      <mat-menu #chSettings="matMenu">
        <button mat-menu-item (click)="openRichDialog(successPage)">
          <mat-icon>check</mat-icon>
          <span>Success Page</span>
        </button>
        <button mat-menu-item (click)="openRichDialog(cancelPage)">
          <mat-icon>cancel</mat-icon>
          <span>Cancel Page</span>
        </button>
        <button mat-menu-item (click)="openRichDialog(termsAndConditions)">
          <mat-icon>insert_drive_file</mat-icon>
          <span>Terms and Conditions</span>
        </button>
      </mat-menu>
    </div>
  </div>

  <div class="form-sub-section booking-form">
    <calendar-card-form [calendarSettingsForm]="calendarSettings" [onSubmit]="onSubmitCalendar">
    </calendar-card-form>

    <booking-card-form [bookingSettingsForm]="bookingSettings" [onSubmit]="onSubmitBooking">
    </booking-card-form>

    <payment-card-form [paymentSettingsForm]="paymentSettings" [onSubmit]="onSubmitPayment">
    </payment-card-form>
  </div>
</form>
