<div class="mat-elevation-z8">
  <mat-table
    matSort
    matSortActive="travelDate"
    matSortDirection="desc"
    [dataSource]="dataSource"
    aria-label="Elements"
  >
    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
      <mat-cell *matCellDef="let row">
        <mat-button-toggle
          appearance="legacy"
          class="circle-toggle"
          matTooltip="Enable/disable booking"
          [checked]="!row.disabled"
          (change)="onEnabledChange($event.source.checked, row.id)"
          [class.button-spinner]="row.isLoading"
          [class.mat-button-toggle-checked]="!row.disabled"
        >
        </mat-button-toggle>
        <a
          mat-icon-button
          matTooltip="Edit booking"
          color="primary"
          target="_blank"
          [routerLink]="[row.id]"
          [disabled]="row.isLoading"
        >
          <mat-icon aria-label="Edit">edit</mat-icon>
        </a>
        <a
          mat-icon-button
          matTooltip="Email invoice"
          color="primary"
          target="_blank"
          [disabled]="row.isLoading"
          [class.button-disabled]="row.buttonDisabled"
          (click)="onEmailInvoice(row.id)"
        >
          <mat-icon aria-label="Send Invoice">input</mat-icon>
        </a>
        <button
          *ngIf="row.canBeSynced"
          mat-icon-button
          matTooltip="Synchronize booking"
          [class.button-spinner]="row.isLoading"
          [disabled]="row.isLoading"
          (click)="onSynchroniseBooking(row.id)"
        >
          <mat-icon aria-label="Sync">cloud_download</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="travelDate">
      <mat-header-cell *matHeaderCellDef mat-sort-header
        >Travel Date</mat-header-cell
      >
      <mat-cell *matCellDef="let row">{{ row.travelDate }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="bookingDate">
      <mat-header-cell *matHeaderCellDef mat-sort-header
        >Booking Date</mat-header-cell
      >
      <mat-cell *matCellDef="let row">{{ row.bookingDate }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="channel">
      <mat-header-cell *matHeaderCellDef mat-sort-header
        >Channel</mat-header-cell
      >
      <mat-cell *matCellDef="let row">{{ row.channel }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="totalAmount">
      <mat-header-cell *matHeaderCellDef mat-sort-header
        >Total Amount</mat-header-cell
      >
      <mat-cell *matCellDef="let row">{{ row.totalAmount }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="paidAmount">
      <mat-header-cell *matHeaderCellDef mat-sort-header
        >Paid Amount</mat-header-cell
      >
      <mat-cell *matCellDef="let row">{{ row.paidAmount }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="currency">
      <mat-header-cell *matHeaderCellDef mat-sort-header
        >Currency</mat-header-cell
      >
      <mat-cell *matCellDef="let row">{{ row.currency }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="paymentStatus">
      <mat-header-cell *matHeaderCellDef mat-sort-header
        >Payment Status</mat-header-cell
      >
      <mat-cell *matCellDef="let row">{{ row.paymentStatus }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="dueDate">
      <mat-header-cell *matHeaderCellDef mat-sort-header
        >Due Date</mat-header-cell
      >
      <mat-cell *matCellDef="let row">{{ row.dueDate }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="customerName">
      <mat-header-cell *matHeaderCellDef mat-sort-header
        >Customer Name</mat-header-cell
      >
      <mat-cell *matCellDef="let row">{{ row.customerName }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="customerEmail">
      <mat-header-cell *matHeaderCellDef mat-sort-header
        >Customer Email</mat-header-cell
      >
      <mat-cell *matCellDef="let row">{{ row.customerEmail }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="salesPerson">
      <mat-header-cell *matHeaderCellDef mat-sort-header
        >Sales Person</mat-header-cell
      >
      <mat-cell *matCellDef="let row">{{ row.salesPerson }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="bookingRefNumberInInvSystem">
      <mat-header-cell *matHeaderCellDef mat-sort-header
        >Ext. Booking Ref.</mat-header-cell
      >
      <mat-cell *matCellDef="let row">{{
        row.bookingRefNumberInInvSystem
      }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="bookingRefNumber">
      <mat-header-cell *matHeaderCellDef mat-sort-header
        >Booking Ref.</mat-header-cell
      >
      <mat-cell *matCellDef="let row">{{ row.bookingRefNumber }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="manuallyCreated">
      <mat-header-cell *matHeaderCellDef mat-sort-header
        >Manually Created</mat-header-cell
      >
      <mat-cell *matCellDef="let row">
        <mat-icon *ngIf="row.manuallyCreated" color="primary">done</mat-icon>
        <mat-icon *ngIf="!row.manuallyCreated" color="warn">clear</mat-icon>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row
      *matRowDef="let row; columns: displayedColumns"
      [class.inactive]="row.cancelled"
    ></mat-row>
  </mat-table>

  <mat-paginator
    #paginator
    [length]="dataSource?.data.length"
    [pageIndex]="0"
    [pageSize]="50"
    [pageSizeOptions]="[25, 50, 100, 250]"
  >
  </mat-paginator>
</div>
