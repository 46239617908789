import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InventorySystem } from 'src/app/shared/models/inventory-system.enum';
import { BundleItemType } from '../../models/bundle-item-type.enum';
import { GetActivityRequest } from '../../models/get-activity-request';
import { ItemModalData } from '../../models/item-modal-data';
import { InvSysDataService } from '../../services/inv-sys-data.service';

@Component({
  selector: 'item-modal',
  templateUrl: './item-modal.component.html',
  styleUrls: ['./item-modal.component.scss']
})
export class ItemModalComponent {

  request = { invSys: InventorySystem.Bokun, type: BundleItemType.Activity } as GetActivityRequest;
  bundleItemTypes = BundleItemType;
  inventorySystems = InventorySystem;

  get isLoading(): boolean {
    return this.invSysDataService.isLoading.get(this.request.id);
  }

  constructor(
    public dialogRef: MatDialogRef<ItemModalComponent>,
    private invSysDataService: InvSysDataService,
    @Inject(MAT_DIALOG_DATA) public data: ItemModalData
  ) { }

  onOkClick(): void {
    this.invSysDataService.loadActivity(this.request).subscribe(res => this.dialogRef.close(res));
  }
}
