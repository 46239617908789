import { Component, Input } from '@angular/core';
import {
  FormControl,
  FormGroup
} from '@angular/forms';
import { ANALYTICS_CURRENCY_OPTIONS, CULTURE_CODES, CURRENCY_OPTIONS, LANG_OPTIONS, PAYMENT_METHOD_OPTIONS, PAYMENT_PROVIDER_OPTIONS, PAYPAL_ACCOUNT_TYPE_OPTIONS } from '../../models/form-constants';

@Component({
  selector: 'booking-card-form',
  templateUrl: './booking-card-form.component.html',
  styleUrls: ['../channel-edit-form/channel-edit-form.component.scss'],
})
export class BookingEditFormComponent {
  @Input() bookingSettingsForm: FormGroup;
  @Input() onSubmit: () => void;

  currencyEnum = CURRENCY_OPTIONS;
  languageEnum = LANG_OPTIONS;
  culturesEnum = CULTURE_CODES;
  analyticsCurrencies = ANALYTICS_CURRENCY_OPTIONS;
  paymentMethods = PAYMENT_METHOD_OPTIONS;
  paymentProviders = PAYMENT_PROVIDER_OPTIONS;
  paypalAccounts = PAYPAL_ACCOUNT_TYPE_OPTIONS;

  constructor() {}

  get language(): FormControl {
    return this.bookingSettingsForm.get('lang') as FormControl;
  }

  getCultureCodes() {
    return this.culturesEnum[this.language.value];
  }
}
