<mat-form-field>
  <input matInput
         [matDatepicker]="createdOnFromPicker"
         [disabled]="isDisabled"
         [(ngModel)]="filters.createdOnFrom"
         placeholder="Created from">
  <mat-datepicker-toggle matSuffix
                         [for]="createdOnFromPicker"></mat-datepicker-toggle>
  <mat-datepicker #createdOnFromPicker></mat-datepicker>
</mat-form-field>
<mat-form-field>
  <input matInput
         [matDatepicker]="createdOnToPicker"
         [disabled]="isDisabled"
         [(ngModel)]="filters.createdOnTo"
         placeholder="Created to">
  <mat-datepicker-toggle matSuffix
                         [for]="createdOnToPicker"></mat-datepicker-toggle>
  <mat-datepicker #createdOnToPicker></mat-datepicker>
</mat-form-field>
<mat-form-field>
  <mat-label>Booking Ref</mat-label>
  <input matInput
         placeholder="Enter text..."
         [disabled]="isDisabled"
         [(ngModel)]="filters.bookingRefNumber">
</mat-form-field>
<mat-form-field>
  <mat-label>Status</mat-label>
  <mat-select placeholder="Select status..."
              multiple
              [disabled]="isDisabled"
              [(ngModel)]="filters.bookingRetryStatuses">
    <mat-option *ngFor="let status of bookingFailureStatuses | keyvalue"
                [value]="status.value">{{status.value}}
    </mat-option>
  </mat-select>
</mat-form-field>

<button type="submit"
        mat-stroked-button
        color="primary"
        [disabled]="isDisabled"
        [class.button-spinner]="isDisabled"
        (click)="onFilterClick()">
  Filter
</button>
