<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <p class="mat-h2"><strong>Booking</strong></p>
  <div class="form-sub-section booking-form">
    <div fxLayout="row">
      <mat-form-field fxFlex="grow" floatLabel="always">
        <mat-label>Title</mat-label>
        <input
          matInput
          placeholder="Enter title"
          formControlName="title"
          [required]="booking.canBeSynced ? false : true"
        />
      </mat-form-field>
      <mat-form-field fxFlex="grow" floatLabel="always">
        <mat-label>Booking ref.</mat-label>
        <input
          matInput
          placeholder="Enter booking ref."
          formControlName="bookingRefNumber"
        />
      </mat-form-field>
      <mat-form-field
        *ngIf="booking.canBeSynced"
        fxFlex="grow"
        floatLabel="always"
      >
        <mat-label>Ext. booking ref.</mat-label>
        <input
          matInput
          placeholder="Enter ext. booking ref."
          matTooltip="This booking number can usually be obtained from inventory system."
          formControlName="bookingRefNumberInInvSystem"
        />
      </mat-form-field>
    </div>

    <div fxLayout="row">
      <channels
        fxFlex="grow"
        [bookingChannel]="channel"
        (channelChanged)="onChannelChange($event)"
      ></channels>
      <mat-form-field fxFlex="grow" floatLabel="always">
        <mat-label>Inventory system</mat-label>
        <mat-select
          formControlName="inventorySystem"
          [required]="booking.canBeSynced"
        >
          <mat-option [value]="''"></mat-option>
          <mat-option
            *ngFor="let invSys of invSysEnum | keyvalue"
            [value]="invSys.value"
          >
            {{ invSys.value }}
          </mat-option>
        </mat-select>
        <mat-error>This field is required.</mat-error>
      </mat-form-field>
    </div>

    <div fxLayout="row">
      <mat-form-field fxFlex="grow" floatLabel="always">
        <mat-label>Travel date</mat-label>
        <input
          matInput
          required
          [matDatepicker]="travelDatePicker"
          formControlName="travelDate"
        />
        <mat-error>This field is required.</mat-error>
        <mat-datepicker-toggle
          matSuffix
          [for]="travelDatePicker"
        ></mat-datepicker-toggle>
        <mat-datepicker #travelDatePicker></mat-datepicker>
      </mat-form-field>
      <mat-form-field fxFlex="grow" floatLabel="always">
        <mat-label>Booking date</mat-label>
        <input
          matInput
          required
          [matDatepicker]="bookingDatePicker"
          formControlName="bookingDate"
        />
        <mat-error>This field is required.</mat-error>
        <mat-datepicker-toggle
          matSuffix
          [for]="bookingDatePicker"
        ></mat-datepicker-toggle>
        <mat-datepicker #bookingDatePicker></mat-datepicker>
      </mat-form-field>
    </div>

    <div>
      <div fxLayout="row">
        <mat-form-field fxFlex="1 1 50%" floatLabel="always">
          <mat-label>Due date</mat-label>
          <input
            matInput
            placeholder="Enter number of days before travel date..."
            type="number"
            required
            formControlName="dueDate"
          />
          <mat-error>This field is required.</mat-error>
        </mat-form-field>
        <span
          *ngIf="travelDate.value && dueDate.value"
          fxFlex="grow"
          fxLayout="row"
          fxLayoutAlign="start center"
        >
          <mat-icon fxFlex="0 0 40px" class="arrow">arrow_right_alt </mat-icon>
          <span matTooltip="Travel date minus your entered number in days.">
            {{ format(subDays(travelDate.value, dueDate.value), "yyyy-MM-dd") }}
          </span>
        </span>
      </div>
      <div fxLayout="row">
        <mat-form-field fxFlex="1 1 50%" floatLabel="always">
          <mat-label>Date of remaining balance email</mat-label>
          <input
            matInput
            placeholder="Enter number of days before travel date..."
            type="number"
            formControlName="remainingBalanceEmailDate"
          />
        </mat-form-field>
        <span
          *ngIf="travelDate.value && remainingBalanceEmailDate.value"
          fxFlex="grow"
          fxLayout="row"
          fxLayoutAlign="start center"
        >
          <mat-icon fxFlex="0 0 40px" class="arrow">arrow_right_alt </mat-icon>
          <span matTooltip="Travel date minus your entered number in days.">
            {{
              format(
                subDays(travelDate.value, remainingBalanceEmailDate.value),
                "yyyy-MM-dd"
              )
            }}
          </span>
        </span>
      </div>
    </div>

    <div fxLayout="row">
      <mat-form-field fxFlex="grow" floatLabel="always">
        <mat-label>Customer name</mat-label>
        <input
          matInput
          placeholder="Enter customer name..."
          formControlName="customerName"
        />
      </mat-form-field>
      <mat-form-field fxFlex="grow" floatLabel="always">
        <mat-label>Customer email</mat-label>
        <input
          matInput
          required
          placeholder="Enter customer email..."
          formControlName="customerEmail"
        />
        <mat-error *ngIf="customerEmail.hasError('required')"
          >This field is required.</mat-error
        >
        <mat-error *ngIf="customerEmail.hasError('email')"
          >Wrong format.</mat-error
        >
      </mat-form-field>
    </div>

    <div fxLayout="row">
      <mat-form-field fxFlex="grow" floatLabel="always">
        <mat-label>Sales person</mat-label>
        <input
          matInput
          placeholder="Enter sales person name..."
          formControlName="salesPerson"
        />
      </mat-form-field>
      <mat-form-field fxFlex="grow" floatLabel="always">
        <mat-label>Sales person email</mat-label>
        <input
          matInput
          placeholder="Enter sales person email..."
          formControlName="salesPersonEmail"
        />
      </mat-form-field>
    </div>

    <div fxLayout="row">
      <mat-form-field fxFlex="1 1 50%" floatLabel="always">
        <mat-label>Paid amount</mat-label>
        <input
          matInput
          placeholder="Enter amount..."
          type="number"
          formControlName="paidAmount"
        />
      </mat-form-field>
    </div>

    <div fxLayout="row">
      <mat-form-field fxFlex="grow" floatLabel="always">
        <mat-label>Payment gateway</mat-label> 
        <mat-select
          formControlName="paymentGateway"
          (selectionChange)="pgChange($event.value)"
        >
          <mat-option
            *ngFor="let pg of paymentGateways"
            [value]="pg.value"
          >
            {{ pg.description }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field fxFlex="grow" floatLabel="always">
        <mat-label>Currency</mat-label>
        <mat-select formControlName="currency" required>
          <ng-container *ngIf="isBorgunSelected; else stripeCurrencies">
            <mat-option
              *ngFor="let currency of borgunCurrency"
              [value]="currency"
            >
              {{ currency }}
            </mat-option>
          </ng-container>

          <ng-template #stripeCurrencies>
            <ng-container>
              <mat-option
                *ngFor="let currency of aacomCurrencies"
                [value]="currency"
              >
                {{ currency }}
              </mat-option>
            </ng-container>
          </ng-template>
        </mat-select>
        <mat-error>This field is required.</mat-error>
      </mat-form-field>
    </div>

    <div>
      <mat-form-field fxFlex="1 1 50%" floatLabel="always">
        <mat-label>Pricing currency</mat-label>
        <mat-select formControlName="pricingCurrency" required>
          <ng-container *ngIf="isBorgunSelected; else stripePricingCurrencies">
            <mat-option
              *ngFor="let currency of borgunCurrency"
              [value]="currency"
            >
              {{ currency }}
            </mat-option>
          </ng-container>

          <ng-template #stripePricingCurrencies>
            <ng-container>
              <mat-option
                *ngFor="let currency of aacomCurrencies"
                [value]="currency"
              >
                {{ currency }}
              </mat-option>
            </ng-container>
          </ng-template>
        </mat-select>
      </mat-form-field>
    </div>

    <div fxLayout="row">
      <mat-checkbox
        *ngIf="booking.canBeSynced"
        fxLayout="row"
        fxLayoutAlign="center center"
        color="primary"
        formControlName="showInvoiceLinesFromInvSys"
      >
        Show invoice lines
      </mat-checkbox>
      <mat-checkbox
        fxLayout="row"
        fxLayoutAlign="center center"
        color="primary"
        formControlName="autoCancelInInvSys"
      >
        Booking auto-cancel
      </mat-checkbox>
    </div>

    <p *ngIf="!booking.canBeSynced" class="mat-h3"><strong>Items</strong></p>
    <div *ngIf="!booking.canBeSynced" formArrayName="items">
      <div
        *ngFor="
          let item of items.controls;
          let itemIndex = index;
          let isLast = last
        "
        [formGroupName]="itemIndex"
        fxLayout="row"
        fxLayout.xs="column"
        fxLayoutAlign="space-between"
      >
        <mat-form-field fxFlex="grow">
          <mat-label>Title</mat-label>
          <input matInput formControlName="title" required />
        </mat-form-field>
        <mat-form-field fxFlex="grow">
          <mat-label>Quantity</mat-label>
          <input matInput type="number" formControlName="quantity" required />
        </mat-form-field>
        <mat-form-field fxFlex="grow">
          <mat-label>Amount per quantity</mat-label>
          <input
            matInput
            type="number"
            formControlName="amountPerQuantity"
            required
          />
        </mat-form-field>
        <mat-form-field fxFlex="grow">
          <mat-label>VAT</mat-label>
          <input matInput type="number" formControlName="vatRate" />
        </mat-form-field>
        <mat-checkbox
          fxLayout="row"
          fxLayoutAlign="center center"
          color="primary"
          formControlName="vatIncluded"
        >
          VAT included
        </mat-checkbox>

        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="0 0 120px">
          <button
            mat-icon-button
            color="primary"
            matTooltip="Save item"
            type="button"
            [disabled]="item.invalid || !booking.id"
            (click)="saveItem(item.value)"
          >
            <mat-icon aria-label="Save item">save</mat-icon>
          </button>
          <button
            type="button"
            color="warn"
            mat-icon-button
            matTooltip="Remove item"
            [disabled]="items.length === 1"
            (click)="removeItem(itemIndex, item.value)"
          >
            <mat-icon>clear</mat-icon>
          </button>
          <button
            *ngIf="isLast"
            type="button"
            mat-icon-button
            color="primary"
            matTooltip="Add item"
            (click)="addItem()"
          >
            <mat-icon>add</mat-icon>
          </button>
        </div>
      </div>
    </div>

    <p class="mat-h3"><strong>Totals</strong></p>
    <div class="form-sub-section" fxLayout="row" fxLayoutGap="5px">
      <p *ngIf="booking.totalAmount">
        <strong>Total amount: </strong>{{ booking.totalAmount }}
      </p>
      <p *ngIf="booking.amountExclVat">
        | <strong>Total amount excluding VAT: </strong
        >{{ booking.amountExclVat }}
      </p>
      <p *ngIf="booking.vatAmount">
        | <strong>Total VAT amount: </strong>{{ booking.vatAmount }}
      </p>
    </div>
  </div>

  <mat-card-actions align="end">
    <button
      mat-raised-button
      color="primary"
      type="button"
      matTooltip="Send email invoice"
      [disabled]="
        form.invalid || form.disabled || !booking.id || booking.buttonDisabled
      "
      [class.button-spinner]="form.disabled || booking.buttonDisabled"
      (click)="onEmailInvoice(booking.id)"
    >
      Send invoice
    </button>
    <button
      mat-raised-button
      color="accent"
      type="submit"
      matTooltip="Save booking"
      [disabled]="form.invalid || form.disabled"
      [class.button-spinner]="form.disabled"
    >
      SAVE
    </button>
  </mat-card-actions>
</form>
