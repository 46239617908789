<ng-template #loading>
  <div class="spinner-container">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>

<div fxLayout="row"
     fxLayoutAlign="space-between center">
  <mat-form-field fxFlex="1 1 33%">
    <mat-label>Search</mat-label>
    <input matInput
           matTooltip="Searches for the entered phrase in extra image titles, flags, keywords and urls"
           placeholder="Enter a search phrase..."
           (input)="onFilter($event.target.value)">
  </mat-form-field>
  <button mat-raised-button
          [disabled]="isLoading"
          matTooltip="Create new extra image mapping"
          color="accent"
          (click)="onAddExtraImage()">
    NEW
  </button>
</div>

<div>
  <extra-images-table *ngIf="!isLoading; else loading"
                      [extraImages]="filteredExtraImages"
                      [form]="form"
                      (saved)="onSaveExtraImage($event)">
  </extra-images-table>
</div>
