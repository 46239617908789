<div class="header-title">
  Channels
  <button mat-button color="primary" class="add-a-channel-button" (click)="addChannelClick()">
    <span>+ Add a channel</span>
  </button>
</div>

<mat-card *ngFor="let setting of getSortedApiSettings()" class="settings-card">
  {{setting.name}}
  <div>
    <button mat-icon-button color="primary" matTooltip="Edit channel" [routerLink]="[setting.id]">
      <mat-icon aria-label="Edit">edit</mat-icon>
    </button>
    <button fxFlex="0 0 40px" color="warn" mat-icon-button matTooltip="Delete channel"
      (click)="onDeleteChannelClick(setting.id)">
      <mat-icon aria-label="Delete">clear</mat-icon>
    </button>
  </div>
</mat-card>