<div class="mat-elevation-z1">
  <mat-table [dataSource]="dataSource"
             aria-label="Logs">
    <ng-container matColumnDef="date">
      <mat-header-cell *matHeaderCellDef>Date sent</mat-header-cell>
      <mat-cell *matCellDef="let row">{{format(row.date, 'yyyy-MM-dd HH:mm:ss')}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="message">
      <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.message}}</mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>
</div>
