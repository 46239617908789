import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { paymentStatuses } from 'src/app/shared/models/payment-statuses';
import { CreationMethod } from '../../models/creation-method.enum';
import { GetBookingsRequest } from '../../models/get-bookings-request';

@Component({
  selector: 'bookings-table-filters',
  templateUrl: './bookings-table-filters.component.html',
  styleUrls: ['./bookings-table-filters.component.scss']
})
export class BookingsTableFiltersComponent implements OnInit {
  @Input() isDisabled: boolean;
  @Output() filtered = new EventEmitter<GetBookingsRequest>();

  filters = new GetBookingsRequest();
  paymentStatuses = paymentStatuses;
  creationMethod = CreationMethod;

  constructor() { }

  ngOnInit(): void { }

  onFilterClick(): void {
    const newFilters = { ...this.filters, disabled: this.filters.disabled[1] ? undefined : [this.filters.disabled[0]] };
    this.filtered.emit(newFilters);
  }
}
