import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Sending } from '../../models/sending';

@Component({
  selector: 'sendings-table',
  templateUrl: './sendings-table.component.html',
  styleUrls: ['./sendings-table.component.scss']
})
export class SendingsTableComponent implements OnInit {
  @Input() sendings: Sending[];
  @ViewChild(MatTable) table: MatTable<Sending>;

  dataSource: MatTableDataSource<Sending>;
  displayedColumns = ['dateSent', 'customerEmail', 'paymentUrl', 'paymentStatus'];

  constructor() { }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource<Sending>(this.sendings);
  }
}
