<form [formGroup]="form"
      (ngSubmit)="onSubmit()">
  <div mat-dialog-content>
    <p>Please fill in your preferred synchronization parameters below:</p>

    <mat-form-field>
      <input matInput
             required
             [matDatepicker]="dateFromPicker"
             placeholder="Date From"
             formControlName="dateFrom"
             (dateChange)="validateDate()">
      <mat-error *ngIf="dateFrom.hasError('required')">This field is required.</mat-error>
      <mat-error *ngIf="dateFrom.hasError('dateFromHigherThanDateTo')">Please choose an earlier date.</mat-error>
      <mat-error *ngIf="dateFrom.hasError('dateRangeExceeded')">Date range must not exceed 14 days.</mat-error>
      <mat-datepicker-toggle matSuffix
                             [for]="dateFromPicker"></mat-datepicker-toggle>
      <mat-datepicker #dateFromPicker></mat-datepicker>
    </mat-form-field>

    <mat-form-field>
      <input matInput
             required
             [matDatepicker]="dateToPicker"
             placeholder="Date To"
             formControlName="dateTo"
             (dateChange)="validateDate()">
      <mat-error *ngIf="dateTo.hasError('required')">This field is required.</mat-error>
      <mat-error *ngIf="dateTo.hasError('dateFromHigherThanDateTo')">Please choose a later date.</mat-error>
      <mat-error *ngIf="dateTo.hasError('dateRangeExceeded')">Date range must not exceed 14 days.</mat-error>
      <mat-datepicker-toggle matSuffix
                             [for]="dateToPicker"></mat-datepicker-toggle>
      <mat-datepicker #dateToPicker></mat-datepicker>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Date Type</mat-label>
      <mat-select required
                  formControlName="dateType">
        <mat-option *ngFor="let type of dateTypeEnum | keyvalue"
                    [value]="type.value">
          {{type.key}}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="dateType.hasError('required')">This field is required.</mat-error>
    </mat-form-field>
  </div>

  <div mat-dialog-actions
       align="center">
    <button mat-button
            type="button"
            [disabled]="form.disabled"
            mat-dialog-close>Cancel</button>
    <button mat-button
            color="primary"
            type="submit"
            [disabled]="form.disabled || form.invalid"
            [class.button-spinner]="form.disabled">Synchronize</button>
  </div>
</form>
