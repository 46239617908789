import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard, MsalRedirectComponent } from '@azure/msal-angular';
import { ApiSettingsComponent } from './api-settings/api-settings.component';
import { ChannelEditFormComponent } from './api-settings/components/channel-edit-form/channel-edit-form.component';
import { BookingEditComponent } from './booking-edit/booking-edit.component';
import { BookingFailuresComponent } from './booking-failures/booking-failures.component';
import { BundleEditComponent } from './bundle-edit/bundle-edit.component';
import { BundlesComponent } from './bundles/bundles.component';
import { ExtraImagesComponent } from './extra-images/extra-images.component';
import { HomeComponent } from './home/home.component';
import { PaymentPortalComponent } from './payment-portal/payment-portal.component';
import { CanDeactivateRouteGuard } from './shared/guards/can-deactivate-route.guard';

const routes: Routes = [
  {
    path: '',
    canActivateChild: [MsalGuard],
    children: [
      {
        path: '',
        component: HomeComponent,
      },
      {
        path: 'bundles',
        component: BundlesComponent,
      },
      {
        path: 'bundles/:id',
        component: BundleEditComponent,
        canDeactivate: [CanDeactivateRouteGuard],
      },
      {
        path: 'payments',
        component: PaymentPortalComponent,
      },
      {
        path: 'payments/:id',
        component: BookingEditComponent,
      },
      {
        path: 'extra-images',
        component: ExtraImagesComponent,
      },
      {
        path: 'booking-failures',
        component: BookingFailuresComponent,
      },
      {
        path: 'api-settings',
        component: ApiSettingsComponent,
      },
      {
        path: 'api-settings/:id',
        component: ChannelEditFormComponent,
      }
    ],
  },
  {
    path: 'auth',
    component: MsalRedirectComponent,
  },
];

const isIframe = window !== window.parent && !window.opener;

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    initialNavigation: !isIframe ? "enabledNonBlocking" : "disabled",
    relativeLinkResolution: 'legacy'
}),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
