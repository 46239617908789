import { InventorySystem } from 'src/app/shared/models/inventory-system.enum';
import { BundleItemExtra } from './bundle-item-extra';
import { BundleItemPricingCategory } from './bundle-item-pricing-category';
import { BundleItemStartTime } from './bundle-item-start-time';
import { BundleItemType } from './bundle-item-type.enum';
import { BundlePlaceSelectionType } from './bundle-place-selection-type.enum';
import { ExtraSelectionType } from './extra-selection-type.enum';

export class BundleItem {
  id: number;
  position: number;
  dayNo: number;
  invSys: InventorySystem = InventorySystem.Bokun;
  type: BundleItemType = BundleItemType.Activity;
  idInInvSys: number = null;
  titleInInvSys: string = null;
  customTitle: string = null;
  inheritPickupListFromBundle: boolean = null;
  inheritDropoffListFromBundle: boolean = null;
  pickupSelectionType: BundlePlaceSelectionType = BundlePlaceSelectionType.Independent;
  pickupPlaceIdInInvSys: number = null;
  pickupPlaceTitleInInvSys: string = null;
  customPickupPlaceDescription: string = null;
  dropoffSelectionType: BundlePlaceSelectionType = BundlePlaceSelectionType.Independent;
  dropoffPlaceIdInInvSys: number = null;
  dropoffPlaceTitleInInvSys: string = null;
  customDropoffPlaceDescription: string = null;
  discount: number = null;
  latitude: number = null;
  longitude: number = null;
  skipOptionalExtras: boolean = null;
  active = true;
  extraSelectionType: ExtraSelectionType = ExtraSelectionType.All;
  startingTimes: BundleItemStartTime[] = [];
  pricingCategories: BundleItemPricingCategory[];
  extras: BundleItemExtra[] = [];
  skipDiscountForExtras = true;
  skipDiscountForPickupAndDropoff = true;
  attachVoucherToEmail = true;
  hideDayNoForExtras = false;
  customized = false;
  customizedTime = null;

  constructor(
    position: number,
    dayNo: number,
    titleInInvSys: string,
    idInInvSys: number,
    pricingCategories: BundleItemPricingCategory[]
  ) {
    this.position = position;
    this.dayNo = dayNo;
    this.titleInInvSys = titleInInvSys;
    this.idInInvSys = idInInvSys;
    this.pricingCategories = pricingCategories;
  }
}
