import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BookingFailureStatus } from '../../models/booking-failure-status.enum';
import { GetBookingFailuresRequestFilters } from '../../models/get-booking-failures-request-filters';

@Component({
  selector: 'booking-failures-table-filters',
  templateUrl: './booking-failures-table-filters.component.html',
  styleUrls: ['./booking-failures-table-filters.component.scss']
})
export class BookingFailuresTableFiltersComponent implements OnInit {
  @Input() isDisabled: boolean;
  @Output() filtered = new EventEmitter<GetBookingFailuresRequestFilters>();

  filters = new GetBookingFailuresRequestFilters();
  bookingFailureStatuses = BookingFailureStatus;

  ngOnInit(): void {
  }

  onFilterClick(): void {
    this.filtered.emit(this.filters);
  }
}
