import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from '../app-routing.module';
import { SharedApiService } from '../shared/services/shared-api.service';
import { SharedModule } from '../shared/shared.module';
import { BundlesComponent } from './bundles.component';
import { BundlesTableComponent } from './components/bundles-table/bundles-table.component';
import { BundlesApiService } from './services/bundles-api.service';
import { BundlesTableFiltersComponent } from './components/bundles-table-filters/bundles-table-filters.component';

@NgModule({
  providers: [
    BundlesApiService,
    SharedApiService
  ],
  declarations: [
    BundlesComponent,
    BundlesTableComponent,
    BundlesTableFiltersComponent,
  ],
  imports: [
    AppRoutingModule,
    CommonModule,
    SharedModule,
    FlexLayoutModule,
    FormsModule
  ],
  exports: []
})
export class BundlesModule { }
