import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BooleanModalComponent } from 'src/app/shared/components/boolean-modal/boolean-modal.component';
import { BooleanModalData } from 'src/app/shared/models/boolean-modal-data';
import { Site } from '../../models/site';
import { SiteApiService } from '../../services/site-api.service';
import { CreateChannelModalComponent } from '../create-channel-modal/create-channel-modal.component';

@Component({
  selector: 'channels-settings',
  templateUrl: './channels-settings.component.html',
  styleUrls: ['./channels-settings.component.scss'],
})
export class EditChannelsSettingsComponent {
  @Input() apiSettings: Site[];
  @Input() updateApiSettingsListCallback: () => void;

  constructor(
    private dialog: MatDialog,
    private siteService: SiteApiService,
    private router: Router,
    private route: ActivatedRoute,
    private siteApiService: SiteApiService,
    ) {}

  getSortedApiSettings(): Site[] {
    if (!this.apiSettings) {
      return [];
    }

    return this.apiSettings.sort((x, y) => (x.createdOn?.getTime() || 0) < (y.createdOn?.getTime() || 0) ? 1 : -1);
  }

  addChannelClick(): void {
    const dialogRef = this.dialog.open(CreateChannelModalComponent, {
      width: '350px',
    });

    dialogRef.afterClosed().subscribe(result => {
      let name, url;
      if (result) {
        name = result.name;
        url = result.url;
      }

      if (!name || !url) {
        return;
      }

      this.siteApiService.createSite({
        name,
        url,
      }).subscribe(
        (site: Site) => { 
          this.router.navigate([`${site[0].id}`], { relativeTo: this.route });
         }
      );
    });
  }

  onDeleteChannelClick(id: string) {
    const channel = this.apiSettings.find(x => x.id === id);

    if (!id || !channel) {
      console.error(`No channel was found by id ${id} on delete!`);
    }

    const question = `Are you sure you want to delete the channel ${channel.name}? You will not be able to undo this action.`;
    const yesOption = `Delete the channel`;
    const noOption = `Back`;
    const data = new BooleanModalData(question, yesOption, noOption);

    const dialogRef = this.dialog.open(BooleanModalComponent, {
      width: '300px',
      data,
    });

    dialogRef.afterClosed().subscribe((res: boolean) => {
      // TODO: extend BooleanModalComponent to not use this trick to switch 'Yes' and 'No' buttons positions
      if (res === false) {
        this.siteService
          .deleteSite(id)
          .subscribe((_) => {
            this.updateApiSettingsListCallback();
          });
      }
    });
  }
}
