<form [formGroup]="form"
      (ngSubmit)="onSubmit()">
  <div mat-dialog-content>
    <p>Please fill in your preferred form initialization parameters below:</p>
    <mat-form-field>
      <mat-label>Form Type</mat-label>
      <mat-select formControlName="formType"
                  matTooltip="Choose the type of booking you want to create"
                  required
                  (selectionChange)="onFormTypeChange()">
        <mat-option *ngFor="let type of formTypeEnum | keyvalue"
                    [value]="type.value">
          {{type.key}}
        </mat-option>
      </mat-select>
      <mat-error>This field is required.</mat-error>
    </mat-form-field>

    <mat-form-field *ngIf="formType.value === formTypeEnum.Synchronized">
      <mat-label>Inventory System</mat-label>
      <mat-select formControlName="inventorySystem"
                  matTooltip="Choose inventory system"
                  required>
        <mat-option *ngFor="let invSys of invSysEnum | keyvalue"
                    [value]="invSys.value">
          {{invSys.key}}
        </mat-option>
      </mat-select>
      <mat-error>This field is required.</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Booking Account</mat-label>
      <mat-select formControlName="bookingAccount"
                  matTooltip="Choose booking account"
                  [(ngModel)]="selectedBookingAccount"
                  required>
        <mat-option *ngFor="let bookingAccount of bookingAccounts"
                    [value]="bookingAccount.accountName">
          {{bookingAccount.accountName}}
        </mat-option>
      </mat-select>
      <mat-error>This field is required.</mat-error>
    </mat-form-field>

    <mat-form-field *ngIf="formType.value === formTypeEnum.Synchronized">
      <mat-label>Booking No.</mat-label>
      <input matInput
             placeholder="Enter id..."
             required
             formControlName="bookingNo">
      <mat-error>This field is required.</mat-error>
    </mat-form-field>
  </div>

  <div mat-dialog-actions
       align="center">
    <button mat-button
            type="button"
            [disabled]="form.disabled"
            mat-dialog-close>Cancel</button>
    <button mat-button
            color="primary"
            type="submit"
            [disabled]="form.disabled || form.invalid"
            [class.button-spinner]="form.disabled">Initialize</button>
  </div>
</form>
