import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
} from '@angular/forms';
import {Location} from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { WidgetEditApiService } from '../../services/widget-edit-api.service';
import { Site } from '../../models/site';
import { WidgetsConstants } from '../../models/widgets-contants';
import { SiteApiService } from '../../services/site-api.service';
import { Widget } from '../../models/widget';
import { MatSnackBar } from '@angular/material/snack-bar';
import {TextEditorData} from "../../models/text-editor-data";
import {RichTextEditorComponent} from "../rich-text-editor/rich-text-editor";
import { TextChannelDataApiService } from '../../services/text-channel-data-api.service';

@Component({
  selector: 'channel-edit-form',
  templateUrl: './channel-edit-form.component.html',
  styleUrls: ['./channel-edit-form.component.scss'],
})
export class ChannelEditFormComponent implements OnInit {
  @Input() site: Site;
  @Output() formSubmitted = new EventEmitter<Site>();

  isSaveButtonDisabled: boolean = false;
  routeId: string = this.route.snapshot.paramMap.get('id');
  widgets: { [key: string]: Widget }  = {}

  calendarSettings = this.fb.group({
    description: [''],
    selector: [''],
    invsys: [''],
    eventID: [''],
    currency: [''],
    lang: [''],
    culture: [''],
    apiURL: [''],
    domainPath: [''],
    wp: [''],
    cartURL: [''],
    loadingText: [''],
    showStartTimeRateTitle: [false],
    showStartTimesAsDropdown: [false],
    analyticsCurrency: [''],
    googleAnalyticsDisabled: [false],
    facebookPixelCurrency: [''],
    facebookDisabled: [false],
    tapfiliateDisabled: [false],
    bingTrackingCurrency: ['']
  })

  bookingSettings = this.fb.group({
    description: [''],
    selector: [''],
    currency: [''],
    lang: [''],
    culture: [''],
    apiURL: [''],
    domainPath: [''],
    wp: [''],
    cartURL: [''],
    toursPageUrl: [''],
    email: [''],
    upsellStepEnabled: [false],
    paymentProvider: [''],
    paypalAccountType: [''],
    paymentMethod: [''],
    is3dSecureEnabled: [false],
    paypalEnabled: [false],
    alipayEnabled: [false],
    analyticsCurrency: [''],
    googleAnalyticsDisabled: [false],
    facebookPixelCurrency: [''],
    facebookDisabled: [false],
    tapfiliateDisabled: [false],
    woopraDisabled: [false],
    bingTrackingCurrency: [''],
  });

  paymentSettings = this.fb.group({
    description: [''],
    selector: [''],
    paymentRequestGuid: [''],
    lang: [''],
    culture: [''],
    apiURL: [''],
    domainPath: [''],
    wp: [''],
    toursPageUrl: [''],
    email: [''],
    paymentProvider: [''],
    paymentMethod: [''],
    is3dSecureEnabled: [false],
    paypalEnabled: [false],
    paypalAccountType: [''],
    alipayEnabled: [false],
    analyticsCurrency: [''],
    googleAnalyticsDisabled: [false],
    facebookPixelCurrency: [''],
    facebookDisabled: [false],
    tapfiliateDisabled: [false],
    woopraDisabled: [false],
    bingTrackingCurrency: [''],
  });

  form = this.fb.group({
    id: [null],
    calendarSettings: this.calendarSettings,
    bookingSettings: this.bookingSettings,
    paymentSettings: this.paymentSettings,
  });

  defaultCalendarWidgetSettings = JSON.stringify({
    invsys: 'Bokun',
    analyticsCurrency: 'USD',
    facebookPixelCurrency: 'USD',
    bingTrackingCurrency: 'USD'
  });

  defaultBookingWidgetSettings = JSON.stringify({
    invsys: 'Bokun',
    analyticsCurrency: 'USD',
    facebookPixelCurrency: 'USD',
    bingTrackingCurrency: 'USD',
    email: 'info@extremeiceland.is',
    paymentMethod: 'CARD',
    paymentProvider: 'STRIPE'
  });

  defaultPaymentWidgetSettings = JSON.stringify({
    invsys: 'Bokun',
    analyticsCurrency: 'USD',
    facebookPixelCurrency: 'USD',
    bingTrackingCurrency: 'USD',
    paymentMethod: 'CARD',
    paymentProvider: 'STRIPE'
  });

  constructor(
    private fb: FormBuilder,
    private widgetEditApiService: WidgetEditApiService,
    private siteApiService: SiteApiService,
    private textChannelDataApiService: TextChannelDataApiService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private location: Location,
    private snackBar: MatSnackBar,
  ) {}

  termsAndConditions: TextEditorData = {
    title: 'Terms and conditions',
    getDataCallback: (id, lang) => this.textChannelDataApiService.getTermsByLanguage(id, lang),
    updateDataCallback: (id, lang, textContent) => this.textChannelDataApiService.updateTermsByLanguage(id, lang, textContent),
    shouldShowFacebookField: false,
  };
  successPage: TextEditorData = {
    title: 'Success page',
    getDataCallback: (id, lang) => this.textChannelDataApiService.getSuccessPageByLanguage(id, lang),
    updateDataCallback: (id, lang, textContent) => this.textChannelDataApiService.updateSuccessPageByLanguage(id, lang, textContent),
    shouldShowFacebookField: true,
  };
  cancelPage: TextEditorData = {
    title: 'Cancel page',
    getDataCallback: (id, lang) => this.textChannelDataApiService.getCancelPageByLanguage(id, lang),
    updateDataCallback: (id, lang, textContent) => this.textChannelDataApiService.updateCancelPageByLanguage(id, lang, textContent),
    shouldShowFacebookField: false,
  };

  ngOnInit(): void {
    this.siteApiService
      .getSite(this.routeId)
      .subscribe(x => {
        this.site = x[0];

        this.widgets[WidgetsConstants.PaymentWidgetName] = this.site.widgets.find(x => x.name.toLowerCase() === WidgetsConstants.PaymentWidgetName) || {};
        this.widgets[WidgetsConstants.BookingWidgetName] = this.site.widgets.find(x => x.name.toLowerCase() === WidgetsConstants.BookingWidgetName) || {};
        this.widgets[WidgetsConstants.CalendarWidgetName] = this.site.widgets.find(x => x.name.toLowerCase() === WidgetsConstants.CalendarWidgetName) || {};

        const formObjectModel = {
          id: this.site.id,
          name: this.site.name,
          paymentSettings: {
            ...this.widgets[WidgetsConstants.PaymentWidgetName],
            ...JSON.parse(this.widgets[WidgetsConstants.PaymentWidgetName]?.params || this.defaultPaymentWidgetSettings)
          },
          bookingSettings: {
            ...this.widgets[WidgetsConstants.BookingWidgetName],
            ...JSON.parse(this.widgets[WidgetsConstants.BookingWidgetName]?.params || this.defaultBookingWidgetSettings)
          },
          calendarSettings: {
            ...this.widgets[WidgetsConstants.CalendarWidgetName],
            ...JSON.parse(this.widgets[WidgetsConstants.CalendarWidgetName]?.params || this.defaultCalendarWidgetSettings)
          },
        }

        this.initForm(formObjectModel);
      })
  }

  onSubmit(widgetName?: string): void {
    const formValue = this.form.getRawValue();
    this.form.disable();

    const callback = (widget?: Widget) => {
      this.form.enable();

      this.site.widgets = this.site.widgets.filter(x => x.name.toLowerCase() !== widget.name.toLowerCase());
      this.site.widgets.push(widget);
      this.widgets[widgetName] = widget

      if (widget) {
        this.initForm(widget);
        this.formSubmitted.emit(this.site);
      }
    };

    const formValues = formValue[widgetName + 'Settings'];
    const keysWithValue = Object.keys(formValues).filter(x => !!formValues[x]);
    const filteredObject = {};
    keysWithValue.forEach(x => filteredObject[x] = formValues[x]);

    const hasCurrentWidget = this.site?.widgets?.some(x => x.name.toLowerCase() === widgetName.toLowerCase());

    if (hasCurrentWidget) {
      this.widgetEditApiService.editWidget(this.widgets[widgetName], filteredObject).subscribe(
        (res: Widget) => callback(res),
        (err) => { this.showErrorSnack(err); console.log(err); this.form.enable(); }
      );
    }
    else {
      this.widgetEditApiService.createWidget(this.routeId, { name: widgetName }, filteredObject).subscribe(
        (res: Widget) => callback(res[0]),
        (err) => { this.showErrorSnack(err); console.log(err); this.form.enable(); }
      );
    }
  }

  onSubmitCalendar = () => this.onSubmit(WidgetsConstants.CalendarWidgetName);
  onSubmitBooking = () => this.onSubmit(WidgetsConstants.BookingWidgetName);
  onSubmitPayment = () => this.onSubmit(WidgetsConstants.PaymentWidgetName);

  navigateToAnchor(anchorId: string): void {
    const viewElement = document.getElementById('sidenav-content');
    const scrollToElement = document.getElementById(anchorId);

    const rect = scrollToElement.getBoundingClientRect();
    const top = rect.top;

    const headerSize = document.getElementById('toolbar-header')?.offsetHeight || 0;

    viewElement.scrollTop = top - scrollToElement.offsetHeight - headerSize;
  }

  private initForm(formModel): void {
    if (!formModel) {
      console.warn('Trying to initialize form with empty settings!');
      return;
    }

    this.form.patchValue(formModel);
  }

  goBack(): void {
    this.location.back();
  }

  private showErrorSnack(message: string): void {
    this.snackBar.open(
      message,
      'Close',
      { duration: 2000, panelClass: ['error-snack'] }
    );
  }

  openRichDialog(data: TextEditorData) {
    data.siteId = this.routeId;
    this.dialog.open(RichTextEditorComponent, {
      width: '80%',
      height: '85%',
      disableClose: true,
      data
    });
  }

  disableSaveSettingsButton() {
    this.isSaveButtonDisabled = true;
    setTimeout(() => {
      this.isSaveButtonDisabled = false;
    }, 10000);
  }
}
