import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { format, subDays } from 'date-fns';
import { BooleanModalComponent } from 'src/app/shared/components/boolean-modal/boolean-modal.component';
import { BooleanModalData } from 'src/app/shared/models/boolean-modal-data';
import { Channel } from 'src/app/shared/models/channel.enum';
import { Currency } from 'src/app/shared/models/currency.enum';
import { InventorySystem } from 'src/app/shared/models/inventory-system.enum';
import { PaymentGateway } from 'src/app/shared/models/payment-gateways.enum';
import { Booking } from '../../models/booking';
import { BookingForm } from '../../models/booking-form';
import { BookingItem } from '../../models/booking-item';
import { BookingRequestData } from '../../models/booking-request-data';
import { BookingEditApiService } from '../../services/booking-edit-api-service';
import { PaymentPortalApiService } from '../../../payment-portal/services/payment-portal-api.service';

@Component({
  selector: 'booking-edit-form',
  templateUrl: './booking-edit-form.component.html',
  styleUrls: ['./booking-edit-form.component.scss'],
})
export class BookingEditFormComponent implements OnInit {
  @Input() booking: Booking;
  @Output() formSubmitted = new EventEmitter<Booking>();

  form = this.fb.group({
    id: [null],
    title: [''],
    travelDate: ['', Validators.required],
    bookingDate: ['', Validators.required],
    dueDate: ['', Validators.required],
    remainingBalanceEmailDate: [''],
    channel: ['', Validators.required],
    totalAmount: [''],
    paidAmount: [''],
    currency: ['', Validators.required],
    customerName: [''],
    customerEmail: [
      '',
      Validators.compose([Validators.required, Validators.email]),
    ],
    bookingRefNumberInInvSystem: [null],
    bookingRefNumber: [null],
    inventorySystem: [''],
    paymentGateway: ['', Validators.required],
    salesPerson: [''],
    salesPersonEmail: [''],
    cancelled: [false],
    disabled: [false],
    paymentStatus: [''],
    showInvoiceLinesFromInvSys: [false],
    autoCancelInInvSys: [true],
    pricingCurrency: ['', Validators.required],
    items: this.fb.array([
      this.fb.group({
        title: ['', Validators.required],
        quantity: [1, Validators.required],
        amountPerQuantity: [null, Validators.required],
        vatRate: [null],
        vatIncluded: [false],
      }),
    ]),
  });

  invSysEnum = InventorySystem;
  channelEnum = Channel;
  aacomCurrencies = [
    Currency.CanadianDollar,
    Currency.UnitedStatesDollar,
    Currency.IcelandicKrona,
  ];
  generalCurrencies = [Currency.IcelandicKrona];
  borgunCurrency = [Currency.UnitedStatesDollar, Currency.IcelandicKrona];
  paymentGateways = [
    PaymentGateway.Stripe,
    PaymentGateway.StripeIs,
    PaymentGateway.Borgun,
    PaymentGateway.BorgunYdt,
  ];
  subDays = subDays;
  format = format;

  get isBorgunSelected(): boolean {
    let pg = this.getPaymentGateway.value;
    if (pg == '' || pg == undefined) return false;

    return (pg as string) == PaymentGateway.Borgun.value ||
      (pg as string) == PaymentGateway.BorgunYdt.value;
  }

  get travelDate(): UntypedFormControl {
    return this.form.get('travelDate') as UntypedFormControl;
  }

  get pricingCurrency(): UntypedFormControl {
    return this.form.get('pricingCurrency') as UntypedFormControl;
  }

  get bookingDate(): UntypedFormControl {
    return this.form.get('bookingDate') as UntypedFormControl;
  }

  get channel(): UntypedFormControl {
    return this.form.get('channel') as UntypedFormControl;
  }

  get getPaymentGateway(): UntypedFormControl {
    return this.form.get('paymentGateway') as UntypedFormControl;
  }

  get inventorySystem(): UntypedFormControl {
    return this.form.get('inventorySystem') as UntypedFormControl;
  }

  get dueDate(): UntypedFormControl {
    return this.form.get('dueDate') as UntypedFormControl;
  }

  get remainingBalanceEmailDate(): UntypedFormControl {
    return this.form.get('remainingBalanceEmailDate') as UntypedFormControl;
  }

  get totalAmount(): UntypedFormControl {
    return this.form.get('totalAmount') as UntypedFormControl;
  }

  get paidAmount(): UntypedFormControl {
    return this.form.get('paidAmount') as UntypedFormControl;
  }

  get currency(): UntypedFormControl {
    return this.form.get('currency') as UntypedFormControl;
  }

  get bookingRefNumberInInvSystem(): UntypedFormControl {
    return this.form.get('bookingRefNumberInInvSystem') as UntypedFormControl;
  }

  get bookingRefNumber(): UntypedFormControl {
    return this.form.get('bookingRefNumber') as UntypedFormControl;
  }

  get customerEmail(): UntypedFormControl {
    return this.form.get('customerEmail') as UntypedFormControl;
  }

  get customerName(): UntypedFormControl {
    return this.form.get('customerName') as UntypedFormControl;
  }

  get title(): UntypedFormControl {
    return this.form.get('title') as UntypedFormControl;
  }

  get items(): UntypedFormArray {
    return this.form.get('items') as UntypedFormArray;
  }

  set items(items: UntypedFormArray) {
    this.form.setControl('items', items);
  }

  constructor(
    private paymentPortalApiService: PaymentPortalApiService,
    private fb: UntypedFormBuilder,
    private bookingEditApiService: BookingEditApiService,
    private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    if (this.booking.canBeSynced)
      this.inventorySystem.setValidators([Validators.required]);

    this.initForm(this.booking);
  }

  onSubmit(): void {
    const formValue = this.form.getRawValue();
    const data = {
      ...formValue,
      dueDate: format(
        subDays(this.travelDate.value, this.dueDate.value),
        'yyyy-MM-dd'
      ),
      remainingBalanceEmailDate: format(
        subDays(this.travelDate.value, this.remainingBalanceEmailDate.value),
        'yyyy-MM-dd'
      ),
      travelDate: format(formValue.travelDate, 'yyyy-MM-dd'),
      bookingDate: format(formValue.bookingDate, 'yyyy-MM-dd'),
    } as BookingRequestData;

    this.form.disable();

    const callback = (res?: BookingForm) => {
      this.form.enable();

      if (res) {
        this.initForm(res);
        this.formSubmitted.emit(res);
      }
    };

    if (!data.id)
      this.bookingEditApiService.saveBooking(data).subscribe(
        (res: BookingForm) => {
          callback(res);
          this.router.navigate([`../${res.id}`], { relativeTo: this.route });
        },
        () => callback()
      );
    else
      this.bookingEditApiService.editBooking(data).subscribe(
        (res: BookingForm) => callback(res),
        () => callback()
      );
  }

  onChannelChange(value: Channel): void {
    if (value === Channel.AdventuresCom) {
      this.currency.reset();
      this.currency.markAllAsTouched();
    }
  }

  pgChange(value: PaymentGateway): void {
    if (value === PaymentGateway.Borgun.value ||
        value === PaymentGateway.BorgunYdt.value) {
      this.currency.reset();
      this.currency.setValue(Currency.UnitedStatesDollar);
      this.currency.markAllAsTouched();
      this.pricingCurrency.reset();
      this.pricingCurrency.setValue(Currency.UnitedStatesDollar);
      this.pricingCurrency.markAllAsTouched();
    } else if (
      value === PaymentGateway.Stripe.value ||
      value === PaymentGateway.StripeIs.value
    ) {
      this.currency.reset();
      this.currency.setValue(Currency.IcelandicKrona);
      this.currency.markAllAsTouched();
      this.pricingCurrency.reset();
      this.pricingCurrency.setValue(Currency.IcelandicKrona);
      this.pricingCurrency.markAllAsTouched();
    }
  }

  addItem(): void {
    const newItemFormGroup = this.fb.group({
      title: ['', Validators.required],
      quantity: [1, Validators.required],
      amountPerQuantity: [null, Validators.required],
      vatRate: [null],
      vatIncluded: [false],
    });

    this.items.push(newItemFormGroup);
  }

  removeItem(itemIndex: number, item: BookingItem): void {
    if (this.booking.id && item.id) {
      const question = 'Are you sure you want to remove this booking item?';
      const data = new BooleanModalData(question);

      const dialogRef = this.dialog.open(BooleanModalComponent, {
        width: '300px',
        data,
      });

      dialogRef.afterClosed().subscribe((res: boolean) => {
        if (res) {
          this.bookingEditApiService
            .deleteBookingItem(this.booking.id, item.id)
            .subscribe((booking: Booking) => this.initForm(booking));
        }
      });
    } else this.items.removeAt(itemIndex);
  }

  saveItem(item: BookingItem): void {
    if (item.id)
      this.bookingEditApiService
        .editBookingItem(this.booking.id, item.id, item)
        .subscribe((booking: Booking) => this.initForm(booking));
    else
      this.bookingEditApiService
        .createBookingItem(this.booking.id, item)
        .subscribe((booking: Booking) => this.initForm(booking));
  }

  private initForm(booking?: Booking): void {
    this.disableSynchronizedFields();

    if (booking) {
      this.booking = booking;
      this.form.patchValue(booking);

      if (booking.items) {
        const bookingItemFormGroups = booking.items.map((i) =>
          this.fb.group(i)
        );
        const bookingItemsFormArray = this.fb.array(bookingItemFormGroups);
        this.items = bookingItemsFormArray;
      }
    }
  }

  private disableSynchronizedFields(): void {
    if (this.booking.canBeSynced) {
      this.travelDate.disable();
      this.totalAmount.disable();
      this.paidAmount.disable();
      this.currency.disable();
      this.customerName.disable();
      this.customerEmail.disable();
      this.bookingRefNumberInInvSystem.disable();
      this.bookingRefNumber.disable();
      this.bookingDate.disable();
      this.inventorySystem.disable();
      this.getPaymentGateway.disable();
      this.pricingCurrency.disable();
    }
  }

  onEmailInvoice(bookingId: number): void {
    if (!this.booking.buttonDisabled || !bookingId) {
      this.disableBookingButton(this.booking);
      this.paymentPortalApiService.emailInvoice(bookingId);
    }
  }

  disableBookingButton(booking: Booking) {
    booking.buttonDisabled = true;
    setTimeout(() => {
      booking.buttonDisabled = false;
    }, 10000);
  }
}
