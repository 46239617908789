import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { BooleanModalComponent } from 'src/app/shared/components/boolean-modal/boolean-modal.component';
import { BooleanModalData } from 'src/app/shared/models/boolean-modal-data';
import { SharedApiService } from 'src/app/shared/services/shared-api.service';
import { BundlesTableRow } from '../../models/bundles-table-row';
import { BundlesTableFilter } from '../../models/bundles-table-filter';

@Component({
  selector: 'bundles-table',
  templateUrl: './bundles-table.component.html',
  styleUrls: ['./bundles-table.component.scss']
})
export class BundlesTableComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() bundles: BundlesTableRow[];
  @Output() bundleDisabled = new EventEmitter<number>();
  @Output() bundleEnabled = new EventEmitter<number>();
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatTable) table: MatTable<BundlesTableRow>;

  dataSource: MatTableDataSource<BundlesTableRow>;
  displayedColumns = ['id', 'title', 'category', 'description', 'active', 'actions'];

  constructor(
    private sharedApiService: SharedApiService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.dataSource = new MatTableDataSource<BundlesTableRow>(this.bundles);
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  ngOnChanges() {
    if (this.table) {
      this.dataSource.data = this.bundles;
      this.table.renderRows();
    }
  }

  onEnableClick(id: number): void {
    const foundBundle = this.bundles.find(i => i.id === id);
    foundBundle.isLoading = true;

    this.sharedApiService.enableBundle(id).subscribe(() => {
      foundBundle.isLoading = false;
      this.bundleEnabled.emit(id);
    });
  }

  onDisableClick(bundle: BundlesTableRow): void {
    const closeModalCallback = () => {
      bundle.isLoading = true;

      this.sharedApiService.disableBundle(bundle.id).subscribe(() => {
        bundle.isLoading = false;
        this.bundleDisabled.emit(bundle.id);
      });
    };

    this.openDeactivateBundleDialog(bundle.title, closeModalCallback);
  }

  private openDeactivateBundleDialog(bundleTitle: string, callback: () => void): void {
    const data = new BooleanModalData(`Are you sure you want to deactivate bundle ${bundleTitle}?`);

    const dialogRef = this.dialog.open(BooleanModalComponent, {
      width: '360px',
      data
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res)
        callback();
    });
  }
}
