import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { SharedApiService } from 'src/app/shared/services/shared-api.service';
import { BundleCategory } from '../../../shared/models/bundle-category';
import { map } from 'rxjs/operators';

@Component({
  selector: 'bundle-category',
  templateUrl: './bundle-category.component.html',
  styleUrls: ['./bundle-category.component.scss']
})
export class BundleCategoryComponent implements OnInit {
  @Input() bundleCategory: UntypedFormControl;
  @Input() isDisabled: UntypedFormControl;

  bundleCategories: BundleCategory[];

  constructor(private sharedApiService: SharedApiService) { }

  ngOnInit(): void {
    this.sharedApiService.getBundleCategories().pipe(
      map(res => [{ id: 0, templateVariable: '', title: '' }, ...res])
    ).subscribe(res => this.bundleCategories = res);
  }

  compareCategoryOptionToSelection(option: BundleCategory, selection: BundleCategory): boolean {
    return option && selection && option.id === selection.id;
  }
}
