import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { SharedApiService } from '../shared/services/shared-api.service';
import { SharedModule } from '../shared/shared.module';
import { BundleEditComponent } from './bundle-edit.component';
import { BundleCategoryComponent } from './components/bundle-category/bundle-category.component';
import { BundleFormComponent } from './components/bundle-form/bundle-form.component';
import { BundleItemComponent } from './components/bundle-item/bundle-item.component';
import { ItemModalComponent } from './components/item-modal/item-modal.component';
import { BundleEditApiService } from './services/bundle-edit-api.service';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  providers: [
    BundleEditApiService,
    SharedApiService
  ],
  declarations: [
    BundleEditComponent,
    BundleFormComponent,
    BundleItemComponent,
    ItemModalComponent,
    BundleCategoryComponent
  ],
  imports: [
    SharedModule,
    FlexLayoutModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    NgxMaskModule.forRoot(options)
  ]
})
export class BundleEditModule { }
