import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { format } from 'date-fns';
import { BooleanModalComponent } from 'src/app/shared/components/boolean-modal/boolean-modal.component';
import { BooleanModalData } from 'src/app/shared/models/boolean-modal-data';
import { Booking } from '../../models/booking';
import { PaymentLinkModalData } from '../../models/payment-link-modal-data';
import { Sending } from '../../models/sending';
import { TablePaymentLink } from '../../models/table-payment-link';
import { BookingEditApiService } from '../../services/booking-edit-api-service';
import { PaymentLinkModalComponent } from '../payment-link-modal/payment-link-modal.component';

@Component({
  selector: 'payment-links-table',
  templateUrl: './payment-links-table.component.html',
  styleUrls: ['./payment-links-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class PaymentLinksTableComponent implements OnInit, OnChanges {
  @Input() paymentLinks: TablePaymentLink[];
  @Input() booking: Booking;
  @ViewChild(MatTable) table: MatTable<TablePaymentLink>;

  dataSource: MatTableDataSource<TablePaymentLink>;
  displayedColumns = ['id', 'guid', 'channel', 'depositPercentage', 'expirationDate', 'actions'];
  expandedPaymentLink: TablePaymentLink | null;
  activeSendings: Sending[];
  isLoading: boolean;

  format = format;

  constructor(
    public dialog: MatDialog,
    private bookingEditApiService: BookingEditApiService
  ) { }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource<TablePaymentLink>(this.paymentLinks);
  }

  ngOnChanges() {
    if (this.table) {
      this.renderTable();
    }
  }

  onNewPaymentLink(): void {
    const data = new PaymentLinkModalData(this.booking.id);

    const dialogRef = this.dialog.open(PaymentLinkModalComponent, {
      width: '360px',
      data
    });

    dialogRef.afterClosed().subscribe((res: TablePaymentLink) => {
      if (res) {
        this.paymentLinks.push(res);
        this.renderTable();
      }
    });
  }

  onEditPaymentLink(event: MouseEvent, link: TablePaymentLink): void {
    event.stopPropagation();
    let data = new PaymentLinkModalData(this.booking.id);
    data = { ...data, expirationDate: link.expirationDate, depositPercentage: link.depositPercentage, id: link.id };

    const dialogRef = this.dialog.open(PaymentLinkModalComponent, {
      width: '360px',
      data
    });

    dialogRef.afterClosed().subscribe((res: TablePaymentLink) => {
      if (res) {
        const foundPaymentLinkIndex = this.paymentLinks.findIndex(i => i.id === res.id);

        if (foundPaymentLinkIndex !== -1) {
          this.paymentLinks[foundPaymentLinkIndex] = res;
          this.renderTable();
        }
      }
    });
  }

  onSendPaymentLink(event: MouseEvent, id: number): void {
    event.stopPropagation();
    const foundPaymentLink = this.paymentLinks.find(i => i.id === id);
    foundPaymentLink.isLoading = true;

    this.bookingEditApiService.sendPaymentLink(this.booking.id, id).subscribe(() => {
      foundPaymentLink.isLoading = false;
      this.expandedPaymentLink = null;
      this.activeSendings = [];
    }, (err) => foundPaymentLink.isLoading = false, () => foundPaymentLink.isLoading = false);
  }

  onDisablePaymentLink(event: MouseEvent, id: number): void {
    event.stopPropagation();
    const closeModalCallback = () => {
      const foundPaymentLink = this.paymentLinks.find(i => i.id === id);
      foundPaymentLink.isLoading = true;

      this.bookingEditApiService.disablePaymentLink(this.booking.id, id).subscribe(() => {
        foundPaymentLink.isLoading = false;
        foundPaymentLink.disabled = true;
      }, (err) => foundPaymentLink.isLoading = false, () => foundPaymentLink.isLoading = false);
    };

    this.openDisablePaymentLinkDialog(closeModalCallback);
  }

  onEnablePaymentLink(event: MouseEvent, id: number): void {
    event.stopPropagation();
    const foundPaymentLink = this.paymentLinks.find(i => i.id === id);
    foundPaymentLink.isLoading = true;

    this.bookingEditApiService.enablePaymentLink(this.booking.id, id).subscribe(() => {
      foundPaymentLink.isLoading = false;
      foundPaymentLink.disabled = false;
    }, (err) => foundPaymentLink.isLoading = false, () => foundPaymentLink.isLoading = false);
  }

  onRowClick(row: TablePaymentLink): void {
    if (this.expandedPaymentLink?.id !== row.id) {
      this.isLoading = true;
      this.bookingEditApiService.getPaymentLinkSendings(this.booking.id, row.id).subscribe(res => {
        this.isLoading = false;
        this.activeSendings = res;
        this.expandedPaymentLink = this.expandedPaymentLink === row ? null : row;
      });
    } else
      this.expandedPaymentLink = this.expandedPaymentLink === row ? null : row;
  }

  private openDisablePaymentLinkDialog(callback: () => void): void {
    const data = new BooleanModalData('Are you sure you want to disable this payment link?');

    const dialogRef = this.dialog.open(BooleanModalComponent, {
      width: '360px',
      data
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res)
        callback();
    });
  }

  private renderTable(): void {
    this.dataSource.data = this.paymentLinks;
    this.table.renderRows();
  }
}
