import { Component, Inject } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CreateChannelDialogData } from './create-channel-modal-data';

@Component({
  selector: 'create-channel-modal',
  templateUrl: './create-channel-modal.component.html',
  styleUrls: ['./create-channel-modal.component.scss']
})
export class CreateChannelModalComponent {
  constructor(
    public dialogRef: MatDialogRef<CreateChannelModalComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: CreateChannelDialogData) { }

  createChannelForm = this.fb.group({
    name: [null],
    url: [null],
  });

  onNoClick(): void {
    this.dialogRef.close(null);
  }

  onOkClick(): void {
    this.dialogRef.close(this.createChannelForm.getRawValue());
  }
}
