import { Component, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'edit-global-settings',
  templateUrl: './edit-global-settings.component.html',
  styleUrls: ['./edit-global-settings.component.scss'],
})
export class EditGlobalSettingsComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
