<div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
  <mat-slide-toggle color="primary"
                    [disabled]="isDisabled"
                    (change)="onFilter()"
                    [(ngModel)]="filter.shouldShowInactive">
    Show inactive bundles
  </mat-slide-toggle>
  <mat-form-field>
    <mat-label>Search</mat-label>
    <input matInput
           placeholder="Enter a search phrase..."
           matTooltip="Searches for the entered phrase in bundle titles and descriptions"
           (input)="onFilter()"
           [disabled]="isDisabled"
           [(ngModel)]="filter.searchPhrase">
  </mat-form-field>
  <mat-form-field>
    <mat-label>Category</mat-label>
    <mat-select placeholder="Select bundle category..."
                multiple
                [disabled]="isDisabled || !bundleCategories"
                (selectionChange)="onFilter()"
                [class.button-spinner]="!bundleCategories"
                [(ngModel)]="filter.categories">
      <mat-option [value]="null"></mat-option>
      <mat-option *ngFor="let category of bundleCategories"
                  [value]="category">{{category.title}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
