export const BOOKING_SYSTEM_OPTIONS = [
    { value: 'Bokun', label: 'Bokun' },
    { value: 'Bundle', label: 'Bundle' },
    { value: 'Rezdy', label: 'Rezdy' },
];

export const CURRENCY_OPTIONS = [
    { value: 'ISK', label: 'ISK' },
    { value: 'USD', label: 'USD' },
    { value: 'AUD', label: 'AUD' },
    { value: 'CAD', label: 'CAD' },
    { value: 'EUR', label: 'EUR' },
    { value: 'GBP', label: 'GBP' },
    { value: 'TWD', label: 'TWD' },
    { value: 'SGD', label: 'SGD' },
    { value: 'HKD', label: 'HKD' },
    { value: 'CNY', label: 'CNY' },
    { value: 'NOK', label: 'NOK' },
    { value: 'DKK', label: 'DKK' },
];

export const ANALYTICS_CURRENCY_OPTIONS = [
    { value: 'USD', label: 'USD' },
    { value: 'EUR', label: 'EUR' },
];

export const LANG_OPTIONS = [
    { value: 'en', label: 'English' },
    { value: 'zh', label: 'Chinese' },
    { value: 'fr', label: 'French' },
    { value: 'de', label: 'German' },
    { value: 'ko', label: 'Korean' },
    { value: 'is', label: 'Icelandic' },
];

/** Source: https://www.science.co.il/language/Locale-codes.php */
export const CULTURE_CODES = {
    en: [
        { label: 'English - United States', value: 'en-US' },
        { label: 'English - Canada', value: 'en-CA' },
        // { label: 'English - Great Britain', value: 'en-gb' },
        // { label: 'English - Australia', value: 'en-au' },
        // { label: 'English - Ireland', value: 'en-ie' },
        // { label: 'English - India', value: 'en-in' },
        // { label: 'English - New Zealand', value: 'en-nz' },
        // { label: 'English - Caribbean', value: 'en-cb' },
        // { label: 'English - Jamaica', value: 'en-jm' },
        // { label: 'English - Phillippines', value: 'en-ph' },
        // { label: 'English - Belize', value: 'en-bz' },
        // { label: 'English - Southern Africa', value: 'en-za' },
        // { label: 'English - Trinidad', value: 'en-tt' },
    ],
    zh: [
        { label: 'Chinese - China', value: 'zh' },
        // { label: 'Chinese - China', value: 'zh-cn' },
        // { label: 'Chinese - Hong Kong SAR', value: 'zh-hk' },
        // { label: 'Chinese - Macau SAR', value: 'zh-mo' },
        // { label: 'Chinese - Singapore', value: 'zh-sg' },
        // { label: 'Chinese - Taiwan', value: 'zh-tw' },
    ],
    fr: [{ label: 'French - France', value: 'fr' }],
    de: [
        { label: 'German - Germany', value: 'de' },
        // { label: 'German - Austria', value: 'de-AT' },
        // { label: 'German - Switzerland', value: 'de-CH' },
    ],
    ko: [{ label: 'Korean - Korea', value: 'ko' }],
    is: [{ label: 'Icelandic - Iceland', value: 'is-IS' }],
};

export const PAYMENT_METHOD_OPTIONS = [
    { value: 'CARD', label: 'Credit card' },
    { value: 'ALIPAY', label: 'AliPay' },
    { value: 'PAYPAL', label: 'Paypal' },
];

export const PAYMENT_PROVIDER_OPTIONS = [
    { value: 'BORGUN', label: 'Borgun (AA)' },
    { value: 'BORGUNYDT', label: 'Borgun (YDT)' },
    { value: 'STRIPE', label: 'Stripe (COM)' },
    { value: 'STRIPEIS', label: 'Stripe (IS)' },
];

export const PAYPAL_ACCOUNT_TYPE_OPTIONS = [
    { value: 'Canada', label: 'Canada' },
    { value: 'Iceland', label: 'Iceland' },
];