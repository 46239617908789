export class BooleanModalData {
  question: string;
  leftButtonText: string;
  rightButtonText: string;

  constructor(question: string, leftButtonText: string = 'No', rightButtonText: string = 'Yes') {
    this.question = question;
    this.leftButtonText = leftButtonText;
    this.rightButtonText = rightButtonText;
  }
}
