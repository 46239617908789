import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { format } from 'date-fns';
import { BookingFailureLog } from '../../models/booking-failure-log';

@Component({
  selector: 'booking-failure-logs-table',
  templateUrl: './booking-failure-logs-table.component.html',
  styleUrls: ['./booking-failure-logs-table.component.scss']
})
export class BookingFailureLogsTableComponent implements OnInit {
  @Input() bookingFailureLogs: BookingFailureLog[];
  @ViewChild(MatTable) table: MatTable<BookingFailureLog>;

  dataSource: MatTableDataSource<BookingFailureLog>;
  displayedColumns = ['date', 'message'];
  format = format;

  constructor() { }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource<BookingFailureLog>(this.bookingFailureLogs);
  }
}
