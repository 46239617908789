import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Widget } from '../models/widget';

@Injectable({
  providedIn: 'root',
})
export class WidgetEditApiService {
  private serverUrl = environment.serverUrl;

  constructor(private http: HttpClient, private snackBar: MatSnackBar) {}
 
  editWidget(widget: Widget, params: any): Observable<Widget> {
    const widgetBody = widget;
    widgetBody.params = JSON.stringify(params);
    
    return this.http.put<Widget>(`${this.serverUrl}/api/widget`, widgetBody).pipe(
      tap(() => this.showSuccessSnack('Widget settings saved'))
    );
  }
 
  createWidget(siteId: string, widget: Widget, filteredObject: any): Observable<Widget> {
    const widgetBody = widget;
    widgetBody.params = JSON.stringify(filteredObject);
    
    return this.http.post<Widget>(`${this.serverUrl}/api/site/${siteId}/widgets`, [widgetBody]).pipe(
      tap(() => this.showSuccessSnack('Widget settings saved'))
    );
  }

  private showSuccessSnack(message: string): void {
    this.snackBar.open(
      message,
      'Close',
      { duration: 2000, panelClass: ['success-snack'] }
    );
  }
}
