<ng-template #loading>
  <div class="spinner-container">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>

<div fxLayout="row"
     fxLayoutAlign="space-between center">
  <bundles-table-filters [isDisabled]="isLoading"
                         (filtered)="onBundlesTableFilter($event)"></bundles-table-filters>
  <button mat-raised-button
          [disabled]="isLoading"
          matTooltip="Create new bundle"
          color="accent"
          class="main-action-btn"
          [routerLink]="['new']">NEW</button>
</div>

<div>
  <bundles-table *ngIf="!isLoading; else loading"
                 [bundles]="filteredBundles"
                 (bundleEnabled)="onBundleEnabled($event)"
                 (bundleDisabled)="onBundleDisabled($event)">
  </bundles-table>
</div>
