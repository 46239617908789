<div fxLayout="row"
     fxLayoutAlign="space-between">
  <p class="mat-h2"><strong>Payment Links</strong></p>
  <div>
    <button mat-raised-button
            color="primary"
            matTooltip="New payment link"
            (click)="onNewPaymentLink()">NEW</button>
  </div>
</div>
<mat-progress-bar *ngIf="isLoading"
                  mode="query"
                  color="primary">
</mat-progress-bar>
<div class="mat-elevation-z8 sub-section">
  <mat-table [dataSource]="dataSource"
             multiTemplateDataRows
             aria-label="Payment Links">
    <ng-container matColumnDef="id">
      <mat-header-cell *matHeaderCellDef>Id</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.id}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="guid">
      <mat-header-cell *matHeaderCellDef>Guid</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.guid}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="channel">
      <mat-header-cell *matHeaderCellDef>Channel</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.channel}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="depositPercentage">
      <mat-header-cell *matHeaderCellDef>Deposit percentage</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.depositPercentage}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="expirationDate">
      <mat-header-cell *matHeaderCellDef>Expiration date</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.expirationDate ? format(row.expirationDate, 'yyyy-MM-dd') : ''}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
      <mat-cell *matCellDef="let row">
        <button mat-icon-button
                matTooltip="Edit payment link"
                color="primary"
                (click)="onEditPaymentLink($event, row)"
                [disabled]="row.isLoading">
          <mat-icon aria-label="Edit">edit</mat-icon>
        </button>
        <button mat-button
                color="primary"
                matTooltip="Send payment link"
                (click)="onSendPaymentLink($event, row.id)"
                [disabled]="row.isLoading || row.disabled || booking.cancelled || booking.disabled || booking.paymentStatus.id === 3 || booking.paymentStatus.id === 4"
                [class.button-spinner]="row.isLoading">Send</button>
        <button *ngIf="row.disabled"
                mat-button
                color="primary"
                matTooltip="Enable payment link"
                (click)="onEnablePaymentLink($event, row.id)"
                [disabled]="row.isLoading"
                [class.button-spinner]="row.isLoading">Enable</button>
        <button *ngIf="!row.disabled"
                mat-button
                color="warn"
                matTooltip="Disable payment link"
                [disabled]="row.isLoading"
                (click)="onDisablePaymentLink($event, row.id)"
                [class.button-spinner]="row.isLoading">Disable</button>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="expandedDetail">
      <mat-cell *matCellDef="let row"
                [attr.colspan]="displayedColumns.length">
        <div [@detailExpand]="row == expandedPaymentLink ? 'expanded' : 'collapsed'"
             class="expandable-cell full-width-element">
          <sendings-table *ngIf="row == expandedPaymentLink && activeSendings"
                          [sendings]="activeSendings"></sendings-table>
        </div>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"> </mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"
             (click)="onRowClick(row)">
    </mat-row>
    <mat-row *matRowDef="let row; columns: ['expandedDetail']"
             [@detailExpand]="row == expandedPaymentLink ? 'expanded' : 'collapsed'"
             class="expanded-row">
    </mat-row>
  </mat-table>
</div>
