<mat-card>
  <div *ngIf="isLoading"
       class="spinner-container">
    <mat-spinner></mat-spinner>
  </div>

  <mat-card-header *ngIf="booking">
    <div mat-card-avatar>
      <mat-icon class="booking-icon">description</mat-icon>
    </div>
    <mat-card-title fxLayout="row"
                    fxLayoutGap="10px">
      <ng-template #newBooking>
        <span fxLayout="row inline"
              fxLayoutAlign="start center">
          New Booking
        </span>
      </ng-template>
      <span *ngIf="booking.id; else newBooking"
            fxLayout="row inline"
            fxLayoutAlign="start center">Booking {{booking.bookingRefNumberInInvSystem}}</span>
      <mat-button-toggle *ngIf="booking.id"
                         appearance="legacy"
                         matTooltip="Activate/deactivate booking"
                         [checked]="!booking.disabled"
                         (change)="onBookingActiveToggle(!booking.disabled)"
                         [disabled]="isBookingDisabledLoading"
                         [class.button-spinner]="isBookingDisabledLoading"
                         [class.mat-button-toggle-checked]="!booking.disabled">
        {{booking.disabled ? 'Disabled' : 'Enabled'}}
      </mat-button-toggle>
      <tag text="CANCELLED"
           *ngIf="booking.cancelled">
      </tag>
    </mat-card-title>
    <mat-card-subtitle *ngIf="routeId !== 'new'">
      <span><strong>{{booking.paymentStatus.title}}</strong></span>
      <span *ngIf="booking.bookingRefNumber"> | Booking ref.: {{booking.bookingRefNumber}}</span>
      <span *ngIf="booking.bookingRefNumberInInvSystem"> | Ext. booking ref.:
        {{booking.bookingRefNumberInInvSystem}}</span>
    </mat-card-subtitle>
  </mat-card-header>

  <div *ngIf="booking">
    <booking-edit-form [booking]="booking"
                       (formSubmitted)="onBookingEditFormSubmit($event)">
    </booking-edit-form>

    <div class="container">
      <payment-links-table *ngIf="!!booking.id"
                           [paymentLinks]="booking.paymentLinks"
                           [booking]="booking">
      </payment-links-table>
    </div>

    <div class="container">
      <payments-table [payments]="booking.payments"></payments-table>
    </div>
  </div>
</mat-card>
