import { BundleCategory } from '../../shared/models/bundle-category';
import { BundleDay } from './bundle-day';
import { PricingCategory } from './pricing-category';

export class Bundle {
  id: number;
  title: string;
  description: string;
  active: boolean;
  days: BundleDay[];
  discount?: number;
  pickupRequired: boolean;
  dropoffRequired: boolean;
  pricingCategories: PricingCategory[];
  category: BundleCategory;

  constructor() {
    this.id = 0;
    this.title = '';
    this.description = '';
    this.active = true;

    const day = {
      dayNo: 1,
      items: []
    } as BundleDay;
    this.days = [day];

    const pricingCategory = {
      id: 1
    } as PricingCategory;
    this.pricingCategories = [pricingCategory];
  }
}
