import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { Bundle } from 'src/app/bundle-edit/models/bundle';
import { BooleanModalComponent } from 'src/app/shared/components/boolean-modal/boolean-modal.component';
import { BooleanModalData } from 'src/app/shared/models/boolean-modal-data';
import { Activity } from '../../models/activity';
import { BundleItem } from '../../models/bundle-item';
import { BundleItemPricingCategory } from '../../models/bundle-item-pricing-category';
import { ItemModalData } from '../../models/item-modal-data';
import { PricingCategory } from '../../models/pricing-category';
import { InvSysDataService } from '../../services/inv-sys-data.service';
import { BundleItemComponent } from '../bundle-item/bundle-item.component';
import { ItemModalComponent } from '../item-modal/item-modal.component';

@Component({
  selector: 'bundle-form',
  templateUrl: './bundle-form.component.html',
  styleUrls: ['./bundle-form.component.scss']
})
export class BundleFormComponent implements OnInit, OnDestroy {
  @ViewChildren(BundleItemComponent) private bundleItems: QueryList<BundleItemComponent>;
  @Input() isNew: boolean;
  @Input() bundle: Bundle;
  @Input() isSubmitLoading: boolean;
  @Output() formSubmitted = new EventEmitter<null>();

  bundleForm: UntypedFormGroup;

  get formValue(): Bundle {
    return this.bundleForm.getRawValue();
  }

  get bundleCategory(): UntypedFormControl {
    return this.bundleForm.get('category') as UntypedFormControl;
  }

  get pricingCategories(): UntypedFormArray {
    return this.bundleForm.get('pricingCategories') as UntypedFormArray;
  }

  set pricingCategories(categories: UntypedFormArray) {
    this.bundleForm.setControl('pricingCategories', categories);
  }

  get days(): UntypedFormArray {
    return this.bundleForm.get('days') as UntypedFormArray;
  }

  set days(days: UntypedFormArray) {
    this.bundleForm.setControl('days', days);
  }

  get discount(): UntypedFormControl {
    return this.bundleForm.get('discount') as UntypedFormControl;
  }

  private formValueChanges: Subscription;

  constructor(
    private fb: UntypedFormBuilder,
    private invSysDataService: InvSysDataService,
    private cdk: ChangeDetectorRef,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.loadBundleForm(this.bundle);
    this.formValueChanges = this.bundleForm.valueChanges.subscribe(() => this.cdk.detectChanges());
  }

  ngOnDestroy(): void {
    this.formValueChanges.unsubscribe();
  }

  onDefaultPricingCategoryChange(categoryIndex: number): void {
    this.pricingCategories.controls.forEach((fg: UntypedFormGroup, i: number) => {
      if (i !== categoryIndex)
        fg.get('defaultCategory').setValue(false);
    });
  }

  addPricingCategory(): void {
    const highestBundleCategoryId = Math.max(...this.pricingCategories.value.map((i: PricingCategory) => i.id));
    const newPricingCategory = this.fb.group({
      id: [highestBundleCategoryId + 1],
      title: ['', Validators.required],
      maxAge: [null],
      minAge: [null],
      defaultCategory: [false]
    });

    this.pricingCategories.push(newPricingCategory);

    this.days.controls.forEach((d: UntypedFormGroup) => {
      const itemsFormArray = d.get('items') as UntypedFormArray;

      itemsFormArray.controls.forEach((i: UntypedFormGroup) => {
        const pricingCategories = i.get('pricingCategories') as UntypedFormArray;
        const newBundleItemPricingCategory = this.fb.group({
          bundlePricingCategoryId: [highestBundleCategoryId + 1],
          pricingCategoryIdInInvSys: [null, Validators.required],
          pricingCategoryTitleInInvSys: [null],
        });

        pricingCategories.push(newBundleItemPricingCategory);
      });
    });

    this.bundleItems.toArray().forEach(i => i.onLoadItemData());
  }

  removePricingCategory(index: number, id: number): void {
    this.pricingCategories.removeAt(index);

    this.days.controls.forEach((d: UntypedFormGroup) => {
      const items = d.get('items') as UntypedFormArray;

      items.controls.forEach((i: UntypedFormGroup) => {
        const pricingCategories = i.get('pricingCategories') as UntypedFormArray;
        const itemCategoryIndex = pricingCategories.value.findIndex((c: BundleItemPricingCategory) => c.bundlePricingCategoryId === id);

        pricingCategories.removeAt(itemCategoryIndex);
      });
    });
  }

  addDay(dayIndex: number): void {
    const question = `Do you want to add a new day before or after Day ${dayIndex + 1}`;
    const data = new BooleanModalData(question, 'Before', 'After');

    const dialogRef = this.dialog.open(BooleanModalComponent, {
      width: '300px',
      data
    });

    dialogRef.afterClosed().subscribe((isAfter: boolean) => {
      if (isAfter) {
        const newDayIndex = dayIndex + 1;
        const newDay = this.fb.group({
          dayNo: [newDayIndex],
          items: this.fb.array([])
        });

        this.days.insert(newDayIndex, newDay);
        this.setDayNoPropsAccordingToIndex();
      }
      else if (isAfter === false) {
        const newDay = this.fb.group({
          dayNo: [dayIndex],
          items: this.fb.array([])
        });

        this.days.insert(dayIndex, newDay);
        this.setDayNoPropsAccordingToIndex();
      }
    });
  }

  addLastDay(): void {
    const newDay = this.fb.group({
      dayNo: [this.days.controls.length + 1],
      items: this.fb.array([])
    });

    this.days.push(newDay);
  }

  removeDay(index: number): void {
    const question = 'Are you sure you want to remove this day? Items under this day will be removed as well.';
    const data = new BooleanModalData(question);

    const dialogRef = this.dialog.open(BooleanModalComponent, {
      width: '300px',
      data
    });

    dialogRef.afterClosed().subscribe((res: boolean) => {
      if (res) {
        this.days.removeAt(index);

        this.setDayNoPropsAccordingToIndex();
      }
    });
  }

  addItem(day: UntypedFormGroup): void {
    const items = day.get('items') as UntypedFormArray;

    const dialogRef = this.dialog.open(ItemModalComponent, {
      width: '300px',
      data: {
        showTypeSelect: true
      } as ItemModalData
    });

    dialogRef.afterClosed().subscribe((res: Activity) => {
      if (res) {
        const pricingCategories: BundleItemPricingCategory[] = this.formValue.pricingCategories.map(i => ({
          bundlePricingCategoryId: i.id,
          pricingCategoryIdInInvSys: null,
          pricingCategoryTitleInInvSys: null,
          occupancy: null
        } as BundleItemPricingCategory));
        const pricingCategoryFormGroups = pricingCategories.map(i => this.fb.group(i));
        const pricingCategoriesFormArray = this.fb.array(pricingCategoryFormGroups);

        const position = Math.max(...items.value.map((i: BundleItem) => i.position), 0) + 1;
        const item = new BundleItem(position, day.value.dayNo, res.title, res.id, pricingCategories);
        const itemFormGroup = this.fb.group(item);

        itemFormGroup.setControl('pricingCategories', pricingCategoriesFormArray);
        itemFormGroup.get('extras').setValue([]);
        itemFormGroup.get('startingTimes').setValue([]);
        itemFormGroup.markAllAsTouched();

        items.push(itemFormGroup);
      }
    });
  }

  removeItem(day: UntypedFormGroup, itemIndex: number): void {
    const question = 'Are you sure you want to remove this bundle item?';
    const data = new BooleanModalData(question);

    const dialogRef = this.dialog.open(BooleanModalComponent, {
      width: '300px',
      data
    });

    dialogRef.afterClosed().subscribe((res: boolean) => {
      if (res) {
        const items = day.get('items') as UntypedFormArray;
        items.removeAt(itemIndex);

        this.setPositionPropAccordingToIndex(items);
      }
    });
  }

  onSubmitClick(): void {
    // TODO: maybe move this to service?
    this.days.controls.forEach((day: UntypedFormGroup) => {
      const items = day.get('items') as UntypedFormArray;

      items.controls.sort((a: UntypedFormGroup, b: UntypedFormGroup) => {
        const { active: aActive, startingTimes: aStartingTimes, customized: aCustomized, customizedTime: aCustomizedTime } = a.value;
        const { active: bActive, startingTimes: bStartingTimes, customized: bCustomized, customizedTime: bCustomizedTime } = b.value;

        if ((!aActive || !bActive) && (aActive || bActive)) {
          return Number(bActive) - Number(aActive);
        }
        if (aCustomized && bCustomized)
          return aCustomizedTime > bCustomizedTime ? 1 : -1;
        else if (aCustomized)
          return aCustomizedTime > bStartingTimes[0].startTimeInInvSys ? 1 : -1;
        else if (bCustomized)
          return aStartingTimes[0].startTimeInInvSys > bCustomizedTime ? 1 : -1;

        return aStartingTimes[0].startTimeInInvSys > bStartingTimes[0].startTimeInInvSys ? 1 : -1;
      });

      this.setPositionPropAccordingToIndex(items);
    });

    this.formSubmitted.emit();
  }

  private setDayNoPropsAccordingToIndex(): void {
    this.days.controls.forEach((d: UntypedFormGroup, index) => {
      const newDayNo = index + 1;
      const dayDayNo = d.get('dayNo') as UntypedFormControl;
      dayDayNo.setValue(newDayNo);

      const items = d.get('items') as UntypedFormArray;

      items.controls.forEach((i: UntypedFormGroup) => {
        const itemDayNo = i.get('dayNo');
        itemDayNo.setValue(newDayNo);
      });
    });
  }

  private setPositionPropAccordingToIndex(items: UntypedFormArray): void {
    items.controls.forEach((item, index) => {
      const position = item.get('position');

      position.setValue(index + 1);
    });
  }

  private loadBundleForm(bundle: Bundle): void {
    this.bundleForm = this.fb.group({
      title: ['', Validators.required],
      description: [''],
      discount: [''],
      category: [],
      pricingCategories: this.fb.array([
        this.fb.group({
          defaultCategory: [false],
          id: [0],
          maxAge: [null],
          minAge: [null],
          title: ['', Validators.required]
        })
      ]),
      pickupRequired: [false],
      dropoffRequired: [false],
      days: this.fb.array([
        this.fb.group({
          dayNo: [1],
          items: this.fb.array([])
        })
      ])
    });

    if (!this.isNew) {
      const { pricingCategories, days } = this.bundle;
      const categoryFormGroups = pricingCategories.map(i => this.fb.group(i));
      const categoriesFormArray = this.fb.array(categoryFormGroups);
      this.pricingCategories = categoriesFormArray;

      const dayFormGroups = days.map(i => this.fb.group(i));
      const daysFormArray = this.fb.array(dayFormGroups);

      daysFormArray.controls.forEach((i: UntypedFormGroup, dayIndex: number) => {
        const itemFormGroups = days[dayIndex].items.map((item: BundleItem) => this.fb.group(item));

        itemFormGroups.forEach((g: UntypedFormGroup, itemIndex) => {
          const pricingCategoryFormGroups = days[dayIndex].items[itemIndex].pricingCategories.map(category => this.fb.group(category));
          const pricingCategoriesFormArray = this.fb.array(pricingCategoryFormGroups);

          g.setControl('pricingCategories', pricingCategoriesFormArray);
        });

        const itemsFormArray = this.fb.array(itemFormGroups);
        i.setControl('items', itemsFormArray);
      });

      this.days = daysFormArray;
      this.bundleForm.patchValue(bundle);
    }
  }
}
