<mat-form-field appearance="outline"
                fxFlex="grow">
  <mat-label>Category</mat-label>
  <mat-select [formControl]="bundleCategory"
              [compareWith]="compareCategoryOptionToSelection"
              [disabled]="!bundleCategories || isDisabled"
              [class.button-spinner]="!bundleCategories">
    <mat-option *ngFor="let category of bundleCategories"
                [value]="category">
      {{category.title}}
    </mat-option>
  </mat-select>
</mat-form-field>
