import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { forkJoin, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Activity } from '../models/activity';
import { Bundle } from '../models/bundle';
import { BundleDay } from '../models/bundle-day';
import { BundleItem } from '../models/bundle-item';
import { GetActivityRequest } from '../models/get-activity-request';

@Injectable({
  providedIn: 'root'
})
export class BundleEditApiService {

  private serverUrl = environment.serverUrl;

  constructor(private http: HttpClient, private snackBar: MatSnackBar) { }

  getBundle(id: string): Observable<Bundle> {
    return this.http.get<Bundle>(`${this.serverUrl}/api/Bundle/${id}`).pipe(
      map(res => ({ ...res, days: this.sortBundleDaysAndItems(res.days) })),
    );
  }

  saveBundle(bundle: Bundle): Observable<Bundle> {
    return this.http.post<Bundle>(`${this.serverUrl}/api/Bundle`, bundle).pipe(
      map(res => ({ ...res, days: this.sortBundleDaysAndItems(res.days) })),
      tap(() => this.showSuccessSnack('Bundle saved'))
    );
  }

  editBundle(bundle: Bundle): Observable<Bundle> {
    return this.http.put<Bundle>(`${this.serverUrl}/api/Bundle/${bundle.id}`, bundle).pipe(
      map(res => ({ ...res, days: this.sortBundleDaysAndItems(res.days) })),
      tap(() => this.showSuccessSnack('Bundle saved'))
    );
  }

  getActivity(params: GetActivityRequest): Observable<Activity> {
    return this.http.get<Activity>(`${this.serverUrl}/api/Activity/${params.id}`, { params: { invSys: params.invSys } });
  }

  getActivities(paramsArray: GetActivityRequest[]): Observable<Activity[]> {
    const requests = paramsArray.map(params => (this.getActivity(params)));

    return forkJoin(requests);
  }

  private showSuccessSnack(message: string): void {
    this.snackBar.open(
      message,
      'Close',
      { duration: 2000, panelClass: ['success-snack'] }
    );
  }

  private sortBundleDaysAndItems(days: BundleDay[]): BundleDay[] {
    const sortedDays = days.slice().sort((a: BundleDay, b: BundleDay) => a.dayNo - b.dayNo);

    sortedDays.forEach(day => day.items.sort((a: BundleItem, b: BundleItem) => a.position - b.position));

    return sortedDays;
  }
}
