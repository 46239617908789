<form [formGroup]="paymentSettingsForm" (ngSubmit)="onSubmit()">

  <mat-card class="settings-card">
    <mat-card-title class="card-title" id="payment">
      <span class="card-title-text">
        Payment
      </span>

      <mat-card-actions class="actions">
        <button mat-button color="accent" type="submit" matTooltip="Save channel settings"
          [disabled]="paymentSettingsForm.invalid || paymentSettingsForm.disabled"
          [class.button-spinner]="paymentSettingsForm.disabled">
          <mat-icon>save</mat-icon>
          Save Changes
        </button>

        <button (click)="$event.preventDefault()" mat-icon-button [matMenuTriggerFor]="menu" aria-label="More menu">
          <mat-icon>more_horiz</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item>
            <mat-icon>remove_red_eye</mat-icon>
            <span>Preview</span>
          </button>
          <button mat-menu-item>
            <mat-icon>code</mat-icon>
            <span>Source Code</span>
          </button>
        </mat-menu>
      </mat-card-actions>
    </mat-card-title>
    <hr class="divider" />
    <div fxLayout="row">
      <mat-form-field fxFlex="35" floatLabel="always">
        <mat-label>Description</mat-label>
        <input matInput placeholder="Enter description" formControlName="description" />
      </mat-form-field>
    </div>

    <p class="mat-h2 section-title title-under-description"><strong>Main</strong></p>

    <div class="form-row" fxLayout="row">
      <mat-form-field fxFlex="30" floatLabel="always">
        <mat-label>DOM Element Id</mat-label>
        <input matInput placeholder="Enter DOM element id" formControlName="selector" />
      </mat-form-field>
      <mat-form-field fxFlex="30" floatLabel="always">
        <mat-label>Payment Request ID</mat-label>
        <input matInput placeholder="Enter Payment Request ID" formControlName="paymentRequestGuid" />
      </mat-form-field>
      <mat-form-field fxFlex="30" floatLabel="always">
        <mat-label>Language</mat-label>
        <mat-select formControlName="lang">
          <mat-option *ngFor="let language of languageEnum" [value]="language.value">
            {{ language.label }}
          </mat-option>
        </mat-select>
        <mat-error>This field is required.</mat-error>
      </mat-form-field>
    </div>

    <div class="form-row" fxLayout="row">
      <mat-form-field fxFlex="30" floatLabel="always">
        <mat-label>Culture</mat-label>
        <mat-select formControlName="culture">
          <mat-option *ngFor="let culture of getCultureCodes()" [value]="culture.value">
            {{ culture.label }}
          </mat-option>
        </mat-select>
        <mat-error>This field is required.</mat-error>
      </mat-form-field>
      <mat-form-field fxFlex="30" floatLabel="always">
        <mat-label>API url</mat-label>
        <input matInput placeholder="Enter API url" formControlName="apiURL" />
      </mat-form-field>
      <mat-form-field fxFlex="30" floatLabel="always">
        <mat-label>Domain path</mat-label>
        <input matInput placeholder="Enter domain path" formControlName="domainPath" />
      </mat-form-field>
    </div>

    <div class="form-row" fxLayout="row">
      <mat-form-field fxFlex="30" floatLabel="always">
        <mat-label>Web page (WP)</mat-label>
        <input matInput placeholder="Enter WP" formControlName="wp" />
      </mat-form-field>
      <mat-form-field fxFlex="30" floatLabel="always">
        <mat-label>Tours page url</mat-label>
        <input matInput placeholder="Enter cart url" formControlName="toursPageUrl" />
      </mat-form-field>
      <mat-form-field fxFlex="30" floatLabel="always">
        <mat-label>Email</mat-label>
        <input matInput placeholder="Enter email" formControlName="email" />
      </mat-form-field>
    </div>

    <p class="mat-h2 section-title title-under-description"><strong>Payment</strong></p>

    <div class="form-row" fxLayout="row">
      <mat-form-field fxFlex="30" floatLabel="always">
        <mat-label>Payment Provider</mat-label>
        <mat-select formControlName="paymentProvider">
          <mat-option *ngFor="let paymentProvider of paymentProviders" [value]="paymentProvider.value">
            {{ paymentProvider.label }}
          </mat-option>
        </mat-select>
        <mat-error>This field is required.</mat-error>
      </mat-form-field>
      <mat-form-field fxFlex="30" floatLabel="always">
        <mat-label>Paypal account type</mat-label>
        <mat-select formControlName="paypalAccountType">
          <mat-option *ngFor="let paypalAccount of paypalAccounts" [value]="paypalAccount.value">
            {{ paypalAccount.label }}
          </mat-option>
        </mat-select>
        <mat-error>This field is required.</mat-error>
      </mat-form-field>
      <mat-form-field fxFlex="30" floatLabel="always">
        <mat-label>Default payment method</mat-label>
        <mat-select formControlName="paymentMethod">
          <mat-option *ngFor="let paymentMethod of paymentMethods" [value]="paymentMethod.value">
            {{ paymentMethod.label }}
          </mat-option>
        </mat-select>
        <mat-error>This field is required.</mat-error>
      </mat-form-field>
    </div>

    <div class="form-row" fxLayout="row">
      <mat-checkbox fxLayout="row" fxLayoutAlign="center center" color="primary" formControlName="is3dSecureEnabled">
        Enable 3-D Secure
      </mat-checkbox>
      <mat-checkbox fxLayout="row" fxLayoutAlign="center center" color="primary" formControlName="paypalEnabled">
        Enable PayPal
      </mat-checkbox>
      <mat-checkbox fxLayout="row" fxLayoutAlign="center center" color="primary" formControlName="alipayEnabled">
        Enable AliPay
      </mat-checkbox>
    </div>

    <p class="mat-h2 section-title"><strong>Analytics</strong></p>

    <div class="form-row" fxLayout="row">
      <mat-form-field fxFlex="30" floatLabel="always">
        <mat-label>Google Analytics currency</mat-label>
        <mat-select formControlName="analyticsCurrency">
          <mat-option *ngFor="let currency of analyticsCurrencies" [value]="currency.value">
            {{ currency.label }}
          </mat-option>
        </mat-select>
        <mat-error>This field is required.</mat-error>
      </mat-form-field>
      <mat-checkbox fxLayout="row" fxLayoutAlign="center center" color="primary"
        formControlName="googleAnalyticsDisabled">
        Disable Google analytics
      </mat-checkbox>
    </div>

    <div class="form-row" fxLayout="row">
      <mat-form-field fxFlex="30" floatLabel="always">
        <mat-label>Facebook Pixel currency</mat-label>
        <mat-select formControlName="facebookPixelCurrency">
          <mat-option *ngFor="let currency of analyticsCurrencies" [value]="currency.value">
            {{ currency.label }}
          </mat-option>
        </mat-select>
        <mat-error>This field is required.</mat-error>
      </mat-form-field>
      <mat-checkbox fxLayout="row" fxLayoutAlign="center center" color="primary" formControlName="facebookDisabled">
        Disable Facebook analytics
      </mat-checkbox>
    </div>

    <div class="form-row" fxLayout="row">
      <mat-form-field fxFlex="30" floatLabel="always">
        <mat-label>Bing Tracking Currency</mat-label>
        <mat-select formControlName="bingTrackingCurrency">
          <mat-option *ngFor="let currency of analyticsCurrencies" [value]="currency.value">
            {{ currency.label }}
          </mat-option>
        </mat-select>
        <mat-error>This field is required.</mat-error>
      </mat-form-field>
      <mat-checkbox fxLayout="row" fxLayoutAlign="center center" color="primary" formControlName="tapfiliateDisabled">
        Disable Tapfiliate analytics
      </mat-checkbox>
      <mat-checkbox fxLayout="row" fxLayoutAlign="center center" color="primary" formControlName="woopraDisabled">
        Disable Woopra analytics
      </mat-checkbox>
    </div>

  </mat-card>

</form>