<section class="editor-container">
  <h1 mat-dialog-title>{{ originalData.title }}</h1>

<form [formGroup]="successModalForm">
  <mat-form-field *ngIf="originalData.shouldShowFacebookField" floatLabel="always">
    <input matInput placeholder="Enter Facebook Page Embed" formControlName="facebookAppName" />
  </mat-form-field>

  <br/>

  <mat-form-field floatLabel="always">
    <mat-label>Language</mat-label>
    <mat-select (selectionChange)="onLangChange()" formControlName="lang">
      <mat-option *ngFor="let language of languageEnum" [value]="language.value">
        {{ language.label }}
      </mat-option>
    </mat-select>
    <mat-error>This field is required.</mat-error>
  </mat-form-field>
</form>

  <div mat-dialog-content>
    <angular-editor
      [(ngModel)]="originalData.content"
      [config]="richEditorConfig"
    ></angular-editor>
  </div>

  <div mat-dialog-actions align="end">
    <button mat-button (click)="onCancel()">Cancel</button>
    <button mat-raised-button (click)="onSaveRichDialog()" color="primary" class="">
      <mat-icon>save</mat-icon>&nbsp;
      Save
    </button>
  </div>

</section>
