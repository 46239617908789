import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { BundleCategory } from '../models/bundle-category';

@Injectable({
  providedIn: 'root'
})
export class SharedApiService {

  private serverUrl = environment.serverUrl;

  constructor(private http: HttpClient, private snackBar: MatSnackBar) { }

  enableBundle(id: number): Observable<null> {
    return this.http.put<null>(`${this.serverUrl}/api/Bundle/${id}/Enable`, null).pipe(
      tap(() => this.showSuccessSnack('Bundle enabled'))
    );
  }

  disableBundle(id: number): Observable<null> {
    return this.http.put<null>(`${this.serverUrl}/api/Bundle/${id}/Disable`, null).pipe(
      tap(() => this.showSuccessSnack('Bundle disabled'))
    );
  }

  enableBooking(id: number): Observable<null> {
    return this.http.put<null>(`${this.serverUrl}/api/booking/${id}/enable`, null).pipe(
      tap(() => this.showSuccessSnack('Booking enabled'))
    );
  }

  disableBooking(id: number): Observable<null> {
    return this.http.put<null>(`${this.serverUrl}/api/booking/${id}/disable`, null).pipe(
      tap(() => this.showSuccessSnack('Booking disabled'))
    );
  }

  getBundleCategories(): Observable<BundleCategory[]> {
    return this.http.get<BundleCategory[]>(`${this.serverUrl}/api/bundle/category`);
  }

  private showSuccessSnack(message: string): void {
    this.snackBar.open(
      message,
      'Close',
      { duration: 2000, panelClass: ['success-snack'] }
    );
  }
}
