import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BooleanModalData } from '../../models/boolean-modal-data';

@Component({
  selector: 'boolean-modal',
  templateUrl: './boolean-modal.component.html',
  styleUrls: ['./boolean-modal.component.scss']
})
export class BooleanModalComponent {
  constructor(
    public dialogRef: MatDialogRef<BooleanModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: BooleanModalData) { }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  onOkClick(): void {
    this.dialogRef.close(true);
  }
}
