import { Component, OnInit } from '@angular/core';
import { BundlesTableFilter } from './models/bundles-table-filter';
import { BundlesTableRow } from './models/bundles-table-row';
import { BundlesApiService as BundlesApiService } from './services/bundles-api.service';

@Component({
  selector: 'bundles',
  templateUrl: './bundles.component.html',
  styleUrls: ['./bundles.component.scss']
})
export class BundlesComponent implements OnInit {

  isLoading: boolean;
  allBundles: BundlesTableRow[];
  filteredBundles: BundlesTableRow[];

  constructor(private bundlesApiService: BundlesApiService) { }

  ngOnInit(): void {
    this.isLoading = true;

    this.bundlesApiService.getBundles().subscribe(res => {
      this.isLoading = false;
      this.allBundles = res;
      this.filteredBundles = res.filter(i => i.active);
    });
  }

  onBundleEnabled(id: number): void {
    const foundBundle = this.allBundles.find(i => i.id === id);
    foundBundle.active = true;
  }

  onBundleDisabled(id: number): void {
    const foundBundle = this.allBundles.find(i => i.id === id);
    foundBundle.active = false;
  }

  onBundlesTableFilter(filter: BundlesTableFilter): void {
    let bundles = [...this.allBundles];

    if (filter.shouldShowInactive !== undefined)
      bundles = bundles.filter(i => filter.shouldShowInactive ? true : i.active);

    if (!!filter.categories?.length)
      bundles = bundles.filter(i => filter.categories.some(c => c === i.category || c?.id === i.category?.id));

    if (filter.searchPhrase)
      bundles = bundles.filter(i =>
        i.title?.toLowerCase().includes(filter.searchPhrase.toLowerCase()) ||
        i.description?.toLowerCase().includes(filter.searchPhrase.toLowerCase())
      );

    this.filteredBundles = bundles;
  }
}
