import { Component, Inject, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  isIframe = false;
  private readonly _destroying$ = new Subject<void>();

  constructor() {}

  ngOnInit(): void {
    this.isIframe = window !== window.parent && !window.opener;
  }

  ngOnDestroy(): void {
    this._destroying$.next(null);
    this._destroying$.complete();
  }
}
