import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from '../app-routing.module';
import { SharedModule } from '../shared/shared.module';
import { ExtraImagesTableComponent } from './components/extra-images-table/extra-images-table.component';
import { ExtraImagesComponent } from './extra-images.component';
import { ExtraImagesApiService } from './services/extra-images-api.service';

@NgModule({
  providers: [
    ExtraImagesApiService
  ],
  declarations: [
    ExtraImagesComponent,
    ExtraImagesTableComponent
  ],
  imports: [
    AppRoutingModule,
    CommonModule,
    SharedModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class ExtraImagesModule { }
