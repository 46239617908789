<form (ngSubmit)="onOkClick()">
  <div mat-dialog-content>
    <p>Please enter the new item information below:</p>

    <mat-form-field floatLabel="always">
      <mat-label>Item Id in Inventory System</mat-label>
      <input matInput
             placeholder="Enter item id..."
             name="id"
             [(ngModel)]="request.id"
             [disabled]="isLoading">
    </mat-form-field>

    <mat-form-field *ngIf="data?.showTypeSelect">
      <mat-label>Item Type</mat-label>
      <mat-select name="type"
                  [(ngModel)]="request.type"
                  [disabled]="isLoading">
        <mat-option *ngFor="let type of bundleItemTypes | keyvalue"
                    [value]="type.value">
          {{type.value}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Inventory System</mat-label>
      <mat-select name="invSys"
                  [(ngModel)]="request.invSys"
                  [disabled]="isLoading">
        <mat-option *ngFor="let system of inventorySystems | keyvalue"
                    [value]="system.value">
          {{system.value}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div mat-dialog-actions
         align="center">
      <button mat-button
              type="button"
              [disabled]="isLoading"
              mat-dialog-close>Cancel</button>
      <button mat-button
              type="submit"
              [disabled]="isLoading"
              [class.button-spinner]="isLoading">Add</button>
    </div>
</form>
