import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ShouldLeaveFormModalData } from '../../models/should-leave-form-modal-data';

@Component({
  selector: 'should-leave-form-modal',
  templateUrl: './should-leave-form-modal.component.html',
  styleUrls: ['./should-leave-form-modal.component.scss']
})
export class ShouldLeaveFormModalComponent {
  isLoading: boolean;

  constructor(
    public dialogRef: MatDialogRef<ShouldLeaveFormModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ShouldLeaveFormModalData
  ) { }

  onDiscardClick(): void {
    this.dialogRef.close(true);
  }

  onSaveClick(): void {
    this.isLoading = true;
    this.data.saveFormFunc.subscribe(() => {
      this.isLoading = false;
      this.dialogRef.close(true);
    });
  }

  onStayClick(): void {
    this.dialogRef.close(false);
  }
}
