<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav" fixedInViewport [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'" [opened]="(isHandset$ | async) === false">
    <mat-toolbar id="toolbar-header" color="primary">Menu</mat-toolbar>
    <mat-nav-list>
      <mat-list-item [routerLink]="['']">Home</mat-list-item>
      <mat-list-item [routerLink]="['bundles']">Bundles</mat-list-item>
      <mat-list-item [routerLink]="['payments']">Payment Portal</mat-list-item>
      <mat-list-item [routerLink]="['extra-images']">Extra Images</mat-list-item>
      <mat-list-item [routerLink]="['booking-failures']">Booking Failures</mat-list-item>
      <mat-list-item [routerLink]="['api-settings']">API Settings</mat-list-item>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content id="sidenav-content">
    <mat-toolbar color="primary" class="sidenav__content__toolbar">
      <button aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()" *ngIf="isHandset$ | async">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <span>Sales Portal</span>
      <button mat-raised-button (click)="logout()">Log out</button>
    </mat-toolbar>
    <ng-content></ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>