import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InventorySystem } from 'src/app/shared/models/inventory-system.enum';
import { Booking } from '../../models/booking';
import { BookingForm } from '../../models/booking-form';
import { FormInitModalData } from '../../models/form-init-modal-data';
import { FormType } from '../../models/form-type.enum';
import { BookingEditApiService } from '../../services/booking-edit-api-service';
import {BookingAccount} from "../../models/booking-account";
import { Channel } from 'src/app/shared/models/channel.enum';
import { PaymentGateway } from 'src/app/shared/models/payment-gateways.enum';

@Component({
  selector: 'form-init-modal',
  templateUrl: './form-init-modal.component.html',
  styleUrls: ['./form-init-modal.component.scss']
})
export class FormInitModalComponent implements OnInit {

  form = this.fb.group({
    formType: ['', Validators.required],
    inventorySystem: ['', Validators.required],
    bookingNo: ['', Validators.required],
    bookingAccount: ['', Validators.required],
  });

  formTypeEnum = FormType;
  invSysEnum = InventorySystem;
  bookingAccounts: BookingAccount[] = [];
  selectedBookingAccount: string = '';
  loaded = false;

  get formType(): UntypedFormControl {
    return this.form.get('formType') as UntypedFormControl;
  }

  get inventorySystem(): UntypedFormControl {
    return this.form.get('inventorySystem') as UntypedFormControl;
  }

  get bookingAccount(): UntypedFormControl {
    return this.form.get('bookingAccount') as UntypedFormControl;
  }

  get bookingNo(): UntypedFormControl {
    return this.form.get('bookingNo') as UntypedFormControl;
  }

  constructor(
    public dialogRef: MatDialogRef<FormInitModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FormInitModalData,
    private fb: UntypedFormBuilder,
    private bookingEditApiService: BookingEditApiService
  ) { }

  ngOnInit(): void {
    this.form.patchValue(this.data);
    this.getBookingAccounts();
  }

  getBookingAccounts() {
    this.bookingEditApiService.getBookingAccounts().subscribe(res => {
      this.bookingAccounts = res;
      this.selectedBookingAccount = this.bookingAccounts[0].accountName;
      this.loaded = true;
      this.onFormTypeChange();
    });
  }

  onFormTypeChange(): void {
    if (!this.form.contains('bookingAccount')) {
      const bookingAccountCtrl: UntypedFormControl = this.fb.control(this.bookingAccounts, Validators.required);
      this.form.addControl('bookingAccount', bookingAccountCtrl);
    }

    if (this.formType.value === FormType.Standalone) {
      this.form.removeControl('inventorySystem');
      this.form.removeControl('bookingNo');
    } else {
      const invSysCtrl: UntypedFormControl = this.fb.control(InventorySystem.Bokun, Validators.required);
      const bookingNoCtrl: UntypedFormControl = this.fb.control('', Validators.required);

      this.form.addControl('inventorySystem', invSysCtrl);
      this.form.addControl('bookingNo', bookingNoCtrl);
    }
  }

  onSubmit(): void {
    let channelCode = this.bookingAccounts.find(x => x.accountName == this.selectedBookingAccount).bookingChannels[0].code;
    if (this.formType.value === FormType.Synchronized) {
      this.form.disable();

      this.bookingEditApiService.getBookingFromInvSys(this.inventorySystem.value, this.bookingNo.value, channelCode)
        .subscribe((res: Booking) => {
          this.dialogRef.close(res);
        },
        () => this.form.enable());
    } else {
      let newBooking = new BookingForm();
      newBooking.channel = channelCode as Channel;
      newBooking.paymentGateway = PaymentGateway.Stripe.value as string;
      this.dialogRef.close(newBooking);
    }
  }
}
