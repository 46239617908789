import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { format } from 'date-fns';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { GetBookingsRequest } from '../models/get-bookings-request';
import { SynchronizeBookingsData } from '../models/synchronize-bookings-data';
import { SynchronizeBookingsResponse } from '../models/synchronize-bookings-response';
import { TableBooking } from '../models/table-booking';

@Injectable({
  providedIn: 'root',
})
export class PaymentPortalApiService {
  private serverUrl = environment.serverUrl;

  constructor(private http: HttpClient, private snackBar: MatSnackBar) {}

  getBookings(
    getBookingsRequest?: GetBookingsRequest
  ): Observable<TableBooking[]> {
    let params = new HttpParams();

    if (getBookingsRequest) {
      const {
        travelDateFrom,
        travelDateTo,
        bookingDateFrom,
        bookingDateTo,
        creationMethod,
        paymentStatuses,
        daysBeforeExpiration,
        searchPhrase,
        dueDateFrom,
        dueDateTo,
        disabled,
      } = getBookingsRequest;

      if (travelDateFrom)
        params = params.set(
          'travelDateFrom',
          format(travelDateFrom, 'yyyy-MM-dd')
        );

      if (travelDateTo)
        params = params.set('travelDateTo', format(travelDateTo, 'yyyy-MM-dd'));

      if (bookingDateFrom)
        params = params.set(
          'bookingDateFrom',
          format(bookingDateFrom, 'yyyy-MM-dd')
        );

      if (bookingDateTo)
        params = params.set(
          'bookingDateTo',
          format(bookingDateTo, 'yyyy-MM-dd')
        );

      if (dueDateFrom)
        params = params.set('dueDateFrom', format(dueDateFrom, 'yyyy-MM-dd'));

      if (dueDateTo)
        params = params.set('dueDateTo', format(dueDateTo, 'yyyy-MM-dd'));

      if (creationMethod)
        params = params.set('creationMethod', `${creationMethod}`);

      if (paymentStatuses && !!paymentStatuses.length) {
        paymentStatuses.forEach((status, index) => {
          params = params.append(`paymentStatuses[${index}]`, `${status}`);
        });
      }

      if (daysBeforeExpiration)
        params = params.set('daysBeforeExpiration', `${daysBeforeExpiration}`);

      if (searchPhrase) params = params.set('searchPhrase', searchPhrase);

      if (disabled !== null && disabled !== undefined)
        params = params.set('disabled', `${disabled}`);
    }

    return this.http
      .get<TableBooking[]>(`${this.serverUrl}/api/booking`, { params })
      .pipe(
        tap((res: TableBooking[]) => {
          if (res && res.length === 1000) {
            const message =
              'You have received the maximum number of results, please narrow your search by using the filters above';
            this.showWarningSnack(message);
          }
        }),
        map((res) =>
          res.map((i) => ({
            ...i,
            travelDate: format(new Date(i.travelDate), 'yyyy-MM-dd'),
            bookingDate: format(new Date(i.bookingDate), 'yyyy-MM-dd'),
            dueDate: format(new Date(i.dueDate), 'yyyy-MM-dd'),
          }))
        )
      );
  }

  synchronizeBookings(
    syncData: SynchronizeBookingsData
  ): Observable<SynchronizeBookingsResponse> {
    return this.http.post(`${this.serverUrl}/api/booking/sync`, syncData).pipe(
      map((res: SynchronizeBookingsResponse) => ({
        errors: res.errors,
        updatedItems: res.updatedItems.map((i) => ({
          ...i,
          travelDate: format(new Date(i.travelDate), 'yyyy-MM-dd'),
          bookingDate: format(new Date(i.bookingDate), 'yyyy-MM-dd'),
          dueDate: format(new Date(i.dueDate), 'yyyy-MM-dd'),
        })),
      })),
      tap((res: SynchronizeBookingsResponse) => {
        const { errors, updatedItems } = res;

        this.showSuccessSnack(
          `Synchronization complete. ${updatedItems.length} items were updated.`
        );

        errors.forEach((i) => this.showWarningSnack(i));
      })
    );
  }

  private showSuccessSnack(message: string): void {
    this.snackBar.open(message, 'Close', {
      duration: 4000,
      panelClass: ['success-snack'],
    });
  }

  private showWarningSnack(message: string): void {
    this.snackBar.open(message, 'Close', {
      duration: 4000,
      panelClass: ['warning-snack'],
    });
  }

  emailInvoice(bookingId: number): void {
    this.http
      .post<any>(`${this.serverUrl}/api/invoice`, { Id: bookingId })
      .subscribe();
  }
}
