import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { addDays } from 'date-fns';
import { SynchronizeBookingsModalComponent } from './components/synchronize-bookings-modal/synchronize-bookings-modal.component';
import { DateType } from './models/date-type.enum';
import { GetBookingsRequest } from './models/get-bookings-request';
import { SynchronizeBookingsModalData } from './models/synchronize-bookings-modal-data';
import { SynchronizeBookingsResponse } from './models/synchronize-bookings-response';
import { TableBooking } from './models/table-booking';
import { PaymentPortalApiService } from './services/payment-portal-api.service';

@Component({
  selector: 'payment-portal',
  templateUrl: './payment-portal.component.html',
  styleUrls: ['./payment-portal.component.scss']
})
export class PaymentPortalComponent implements OnInit {

  isLoading = true;
  bookings: TableBooking[];

  constructor(private paymentPortalApiService: PaymentPortalApiService, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.getBookings(new GetBookingsRequest());
  }

  onFilter(getBookingsRequest?: GetBookingsRequest): void {
    this.getBookings(getBookingsRequest);
  }

  onSynchronizeBookings(): void {
    const data = {
      dateFrom: new Date(),
      dateTo: addDays(new Date(), 7),
      dateType: DateType.Travel
    } as SynchronizeBookingsModalData;

    const dialogRef = this.dialog.open(SynchronizeBookingsModalComponent, {
      width: '360px',
      data
    });

    dialogRef.afterClosed().subscribe((res: SynchronizeBookingsResponse) => {
      if (res) {
        const { updatedItems } = res;

        updatedItems.forEach(updatedItem => {
          const foundItemIndex = this.bookings.findIndex(p => p.id === updatedItem.id);

          if (foundItemIndex !== -1)
            this.bookings[foundItemIndex] = updatedItem;
        });
      }
    });
  }

  private getBookings(getBookingsRequest?: GetBookingsRequest): void {
    this.isLoading = true;

    this.paymentPortalApiService.getBookings(getBookingsRequest).subscribe(res => {
      this.isLoading = false;
      this.bookings = res;
    });
  }
}
