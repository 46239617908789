<form [formGroup]="form">
  <mat-table class="mat-elevation-z8"
             [dataSource]="dataSource"
             formArrayName="extraImages"
             aria-label="Extra Images">
    <ng-container matColumnDef="title">
      <mat-header-cell *matHeaderCellDef
                       matTooltip="Extra image mapping title. For internal use only. Does not impact mapping itself">Title
      </mat-header-cell>
      <mat-cell *matCellDef="let row; let index = index"
                [formGroupName]="index">
        <ng-template #saved>{{row.title}}</ng-template>
        <mat-form-field *ngIf="row.isInEditState; else saved"
                        appearance="outline">
          <input matInput
                 formControlName="title">
          <mat-error>This field is required.</mat-error>
        </mat-form-field>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="flag">
      <mat-header-cell *matHeaderCellDef
                       matTooltip="Case insensitive extra flag">Flag</mat-header-cell>
      <mat-cell *matCellDef="let row; let index = index"
                [formGroupName]="index">
        <ng-template #saved>{{row.flag}}</ng-template>
        <mat-form-field *ngIf="row.isInEditState; else saved"
                        appearance="outline">
          <input matInput
                 formControlName="flag">
          <mat-error>This field is required.</mat-error>
        </mat-form-field>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="keywords">
      <mat-header-cell *matHeaderCellDef
                       matTooltip="Case insensitive extra titles. Keyword must match full extra title">Keywords
      </mat-header-cell>
      <mat-cell *matCellDef="let row; let index = index"
                [formGroupName]="index">
        <ng-template #saved>{{row.keywords.join(', ')}}</ng-template>
        <mat-form-field *ngIf="row.isInEditState; else saved"
                        appearance="outline">
          <mat-chip-list #chipList
                         formControlName="keywords">
            <mat-chip *ngFor="let keyword of extraImagesFormArray.at(index).get('keywords').value; let keywordIndex = index;"
                      selectable
                      removable
                      (removed)="onRemoveKeyword(index, keywordIndex)">
              {{keyword}}
              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
            <input [matChipInputFor]="chipList"
                   [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                   matChipInputAddOnBlur
                   (matChipInputTokenEnd)="onAddKeyword($event, index)">
          </mat-chip-list>
        </mat-form-field>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="url">
      <mat-header-cell *matHeaderCellDef
                       matTooltip="Image url">Url</mat-header-cell>
      <mat-cell *matCellDef="let row; let index = index"
                [formGroupName]="index">
        <ng-template #saved><a [href]="row.url">{{row.url}}</a></ng-template>
        <mat-form-field *ngIf="row.isInEditState; else saved"
                        appearance="outline">
          <input matInput
                 formControlName="url">
          <mat-error>This field is required.</mat-error>
        </mat-form-field>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
      <mat-cell *matCellDef="let row; let index = index">
        <button *ngIf="!row.isInEditState"
                mat-icon-button
                matTooltip="Edit extra image"
                color="primary"
                (click)="onEditExtraImage(row)">
          <mat-icon aria-label="Edit">edit</mat-icon>
        </button>
        <button *ngIf="row.isInEditState"
                mat-icon-button
                color="primary"
                matTooltip="Save extra image"
                type="button"
                (click)="onSaveExtraImage(row, index)"
                [disabled]="extraImagesFormArray.at(index).invalid">
          <mat-icon aria-label="Save">save</mat-icon>
        </button>
        <button fxFlex="0 0 40px"
                type="button"
                color="warn"
                mat-icon-button
                matTooltip="Remove extra image"
                (click)="onRemoveExtraImage(row.id, index)">
          <mat-icon>clear</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>
</form>
