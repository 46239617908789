import { addDays } from 'date-fns';
import { CreationMethod } from './creation-method.enum';

export class GetBookingsRequest {
  travelDateFrom: Date;
  travelDateTo: Date;
  bookingDateFrom?: Date;
  bookingDateTo?: Date;
  creationMethod: CreationMethod;
  paymentStatuses: number[] = [1, 2];
  daysBeforeExpiration?: number;
  searchPhrase: string;
  dueDateFrom = addDays(new Date(), 1);
  dueDateTo = addDays(new Date(), 1);
  disabled: boolean[] = [false];
}
