import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { differenceInDays, format, isAfter } from 'date-fns';
import { Observable } from 'rxjs';
import { DateType } from '../../models/date-type.enum';
import { SynchronizeBookingsData } from '../../models/synchronize-bookings-data';
import { SynchronizeBookingsModalData } from '../../models/synchronize-bookings-modal-data';
import { SynchronizeBookingsResponse } from '../../models/synchronize-bookings-response';
import { PaymentPortalApiService } from '../../services/payment-portal-api.service';

@Component({
  selector: 'synchronize-bookings-modal',
  templateUrl: './synchronize-bookings-modal.component.html',
  styleUrls: ['./synchronize-bookings-modal.component.scss']
})
export class SynchronizeBookingsModalComponent implements OnInit {

  form = this.fb.group({
    dateType: [null, Validators.required],
    dateTo: [null, Validators.required],
    dateFrom: [null, Validators.required]
  });

  dateTypeEnum = DateType;

  get dateType(): UntypedFormControl {
    return this.form.get('dateType') as UntypedFormControl;
  }

  get dateTo(): UntypedFormControl {
    return this.form.get('dateTo') as UntypedFormControl;
  }

  get dateToValue(): Date {
    return this.form.get('dateTo').value;
  }

  get dateFrom(): UntypedFormControl {
    return this.form.get('dateFrom') as UntypedFormControl;
  }

  get dateFromValue(): Date {
    return this.form.get('dateFrom').value;
  }

  constructor(
    public dialogRef: MatDialogRef<SynchronizeBookingsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SynchronizeBookingsModalData,
    private fb: UntypedFormBuilder,
    private paymentPortalApiService: PaymentPortalApiService
  ) { }

  ngOnInit(): void {
    this.form.setValue(this.data);
  }

  onSubmit(): void {
    this.form.disable();

    this.synchronizeBookings(this.form.value).subscribe(res => this.dialogRef.close(res));
  }

  validateDate(): void {
    const maxDaysDifference = 14;
    const daysDifference = differenceInDays(this.dateToValue, this.dateFromValue);
    const isFromDateAfter = isAfter(this.dateFromValue, this.dateToValue);
    let errors = {};

    if (this.dateToValue && this.dateFromValue) {
      if (daysDifference > maxDaysDifference)
        errors = { ...errors, dateRangeExceeded: true };

      if (isFromDateAfter)
        errors = { ...errors, dateFromHigherThanDateTo: true };

      if (!!Object.keys(errors).length) {
        this.dateFrom.setErrors(errors);
        this.dateTo.setErrors(errors);
      }
      else {
        this.dateFrom.setErrors(null);
        this.dateTo.setErrors(null);
      }
    }
  }

  private synchronizeBookings(modalData: SynchronizeBookingsModalData): Observable<SynchronizeBookingsResponse> {
    const data = {
      ...modalData,
      dateFrom: format(modalData.dateFrom, 'yyyy-MM-dd'),
      dateTo: format(modalData.dateTo, 'yyyy-MM-dd')
    } as SynchronizeBookingsData;

    return this.paymentPortalApiService.synchronizeBookings(data).pipe();
  }
}
