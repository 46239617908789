<mat-card>
  <ng-template #loading>
    <div class="spinner-container">
      <mat-spinner></mat-spinner>
    </div>
  </ng-template>

  <div *ngIf="bundle; else loading">
    <mat-card-header>
      <div mat-card-avatar>
        <mat-icon class="bundle-icon">library_books</mat-icon>
      </div>
      <mat-card-title fxLayout="row"
                      fxLayoutGap="10px">
        <span fxLayout="row inline"
              fxLayoutAlign="start center">{{bundle.title || 'New Bundle'}}</span>
        <mat-button-toggle appearance="legacy"
                           matTooltip="Activate/deactivate bundle"
                           [checked]="bundle.active"
                           (change)="onBundleActiveToggle(bundle.active)"
                           [disabled]="isBundleActiveToggleLoading"
                           [class.button-spinner]="isBundleActiveToggleLoading"
                           [class.mat-button-toggle-checked]="bundle.active">
          {{bundle.active ? 'Active' : 'Inactive'}}
        </mat-button-toggle>
      </mat-card-title>
      <mat-card-subtitle *ngIf="routeId !== 'new'">
        <strong>Id: {{bundle.id}}</strong> | Bundle Description: {{bundle.description}}
      </mat-card-subtitle>
    </mat-card-header>

    <bundle-form #bundleFormComponent
                 [isNew]="routeId === 'new'"
                 [bundle]="bundle"
                 [isSubmitLoading]="isSubmitLoading"
                 (formSubmitted)="onSubmit()">
    </bundle-form>
  </div>
</mat-card>
