export enum Channel {
  GlacierGuides = 'GG',
  ArcticAdventures = 'AA',
  ChineseExtremeIceland = 'CNEI',
  AdventuresCom = 'AACOM',
  Trek = 'TREK',
  EI = 'EI',
  AST = 'AST',
  ITG = 'ITG',
  CNAA = 'CNAA'
}
