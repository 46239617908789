<div [formGroup]="createChannelForm" class="modal-input" mat-dialog-content>
        <p>Please, enter site name and url</p>
        <mat-form-field floatLabel="always">
                <mat-label>Site name:</mat-label>
                <input matInput placeholder="Enter site name" formControlName="name" />
        </mat-form-field>
        <mat-form-field floatLabel="always">
                <mat-label>Site url:</mat-label>
                <input matInput placeholder="Enter site url" formControlName="url" />
        </mat-form-field>
</div>
<div class="modal-buttons" mat-dialog-actions>
        <button mat-button (click)="onNoClick()">Cancel</button>
        <button color="primary" mat-button (click)="onOkClick()" cdkFocusInitial>Create</button>
</div>