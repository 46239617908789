<div mat-dialog-content>
  <p>Your form has unsaved changes. What would you like to do?</p>
  <p fxLayout="row"
     *ngIf="!data.isFormValid">
    <mat-icon color="error">error_outline</mat-icon>
    <span>The form is invalid, if you want to save please make sure that all fields have been filled in
      correctly.</span>
  </p>
</div>
<div mat-dialog-actions
     align="center">
  <button mat-button
          (click)="onStayClick()"
          [disabled]="isLoading"
          matTooltip="Stay on this page">Stay</button>
  <button mat-button
          (click)="onDiscardClick()"
          [disabled]="isLoading"
          matTooltip="Leave page and don't save your changes">Discard</button>
  <button mat-raised-button
          color="primary"
          (click)="onSaveClick()"
          matTooltip="Leave page and save your changes"
          cdkFocusInitial
          [disabled]="isLoading || !data.isFormValid"
          [class.button-spinner]="isLoading">Save</button>
</div>
