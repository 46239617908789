<div class="root-wrapper">
  <div class="wrapper">
    <div class="header-api-settings">
      Api Settings
    </div>
    <channels-settings [apiSettings]="apiSettings" [updateApiSettingsListCallback]="getApiSettingsRequest">
    </channels-settings>
    <hr class="divider" />
    <edit-global-settings></edit-global-settings>
  </div>
</div>