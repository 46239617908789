import { Component, OnInit } from '@angular/core';
import { Site } from './models/site';
import { SiteApiService } from './services/site-api.service';

@Component({
  selector: 'api-settings',
  templateUrl: './api-settings.component.html',
  styleUrls: ['./api-settings.component.scss'],
})
export class ApiSettingsComponent implements OnInit {
  apiSettings: Site[];
  isLoading: boolean;

  constructor(private apiSettingsService: SiteApiService) {}

  ngOnInit(): void {
    this.getApiSettingsRequest();
  }

  public getApiSettingsRequest: () => void = () => {
    this.isLoading = true;

    this.apiSettingsService.getSite().subscribe(
      (res) => {
        this.isLoading = false;
        this.apiSettings = res;
      },
      (err) => (this.isLoading = false),
      () => (this.isLoading = false)
    );
  }
}
