import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { format } from 'date-fns';
import { BookingFailure } from '../../models/booking-failure';

@Component({
  selector: 'booking-failures-table',
  templateUrl: './booking-failures-table.component.html',
  styleUrls: ['./booking-failures-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed, void => expanded', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class BookingFailuresTableComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() bookingFailures: BookingFailure[];
  @ViewChild(MatTable) table: MatTable<BookingFailure>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  dataSource: MatTableDataSource<BookingFailure>;
  displayedColumns = ['bookingRefNumber', 'channel', 'createdOn', 'lastRetryDate', 'retryCount', 'status'];
  format = format;
  expandedBookingFailure: BookingFailure;

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource<BookingFailure>(this.bookingFailures);
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  ngOnChanges(): void {
    if (this.table) {
      this.renderTable();
    }
  }

  onRowClick(row: BookingFailure): void {
    this.expandedBookingFailure = this.expandedBookingFailure?.id === row.id ? null : row;
  }

  private renderTable(): void {
    this.dataSource.data = this.bookingFailures;
    this.table.renderRows();
  }
}
