import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Channel } from 'src/app/shared/models/channel.enum';
import { TitleCode } from 'src/app/shared/models/title-code';
import { BookingEditApiService } from '../../services/booking-edit-api-service';
import {BookingAccount} from "../../models/booking-account";

@Component({
  selector: 'channels',
  templateUrl: './channels.component.html',
  styleUrls: ['./channels.component.scss']
})
export class ChannelsComponent implements OnInit {
  @Input() bookingChannel: UntypedFormControl;
  @Output() channelChanged = new EventEmitter<Channel>();

  channels: TitleCode[];
  private bookingAccount: BookingAccount;

  constructor(private bookingEditApiService: BookingEditApiService) { }

  get channelsDropdownDisabled(): boolean {
    return this.channels ? this.channels.length <= 1 : true;
  }

  ngOnInit(): void {
    this.bookingChannel.disable();
    this.bookingEditApiService.getBookingAccounts().subscribe(data => {
      this.bookingAccount = data.find(x => x.bookingChannels.some(y => y.code == this.bookingChannel.value));

      // inside so it would always have the booking accounts ready before creating the dropdown.
      this.bookingEditApiService.getChannels().subscribe(res => {
        this.bookingChannel.enable();
        this.channels = res.filter(x => this.bookingAccount.bookingChannels.some(y => y.code == x.code));
      });
    });
  }

  onChannelChange(value: Channel): void {
    this.channelChanged.emit(value);
  }
}
