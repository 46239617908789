import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { BooleanModalComponent } from 'src/app/shared/components/boolean-modal/boolean-modal.component';
import { BooleanModalData } from 'src/app/shared/models/boolean-modal-data';
import { SharedApiService } from 'src/app/shared/services/shared-api.service';
import { SynchronizeBookingsData } from '../../models/synchronize-bookings-data';
import { SynchronizeBookingsResponse } from '../../models/synchronize-bookings-response';
import { TableBooking } from '../../models/table-booking';
import { PaymentPortalApiService } from '../../services/payment-portal-api.service';

@Component({
  selector: 'bookings-table',
  templateUrl: './bookings-table.component.html',
  styleUrls: ['./bookings-table.component.scss'],
})
export class BookingsTableComponent
  implements OnInit, AfterViewInit, OnChanges
{
  @Input() bookings: TableBooking[];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatTable) table: MatTable<TableBooking>;

  dataSource: MatTableDataSource<TableBooking>;
  displayedColumns = [
    'actions',
    'travelDate',
    'bookingDate',
    'channel',
    'totalAmount',
    'paidAmount',
    'currency',
    'paymentStatus',
    'dueDate',
    'customerName',
    'customerEmail',
    'salesPerson',
    'bookingRefNumberInInvSystem',
    'bookingRefNumber',
    'manuallyCreated',
  ];

  constructor(
    private paymentPortalApiService: PaymentPortalApiService,
    private sharedApiService: SharedApiService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.dataSource = new MatTableDataSource<TableBooking>(this.bookings);
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  ngOnChanges() {
    if (this.table) {
      this.dataSource.data = this.bookings;
      this.table.renderRows();
    }
  }

  onSynchroniseBooking(id: number): void {
    const foundBooking = this.bookings.find((i) => i.id === id);
    foundBooking.isLoading = true;

    const data = {
      bookingId: id,
    } as SynchronizeBookingsData;

    this.synchronizeBookings(data).subscribe(
      () => (foundBooking.isLoading = false)
    );
  }

  onEnabledChange(value: boolean, id: number): void {
    const foundBooking = this.bookings.find((i) => i.id === id);
    if (value) {
      foundBooking.isLoading = true;
      this.sharedApiService.enableBooking(id).subscribe(() => {
        foundBooking.isLoading = false;
        foundBooking.disabled = false;
      });
    } else {
      const data = new BooleanModalData(
        `Are you sure you want to disable this booking?`
      );
      const dialogRef = this.dialog.open(BooleanModalComponent, {
        width: '360px',
        data,
      });

      dialogRef.afterClosed().subscribe((shouldDisable: boolean) => {
        if (shouldDisable) {
          foundBooking.isLoading = true;
          this.sharedApiService.disableBooking(id).subscribe(() => {
            foundBooking.disabled = true;
            foundBooking.isLoading = false;
          });
        }
      });
    }
  }

  private synchronizeBookings(
    data: SynchronizeBookingsData
  ): Observable<SynchronizeBookingsResponse> {
    return this.paymentPortalApiService.synchronizeBookings(data).pipe(
      tap((res) => {
        const { updatedItems } = res;

        updatedItems.forEach((updatedItem) => {
          const foundItemIndex = this.bookings.findIndex(
            (p) => p.id === updatedItem.id
          );
          if (foundItemIndex !== -1) {
            this.bookings[foundItemIndex] = updatedItem;
          }
        });

        this.dataSource.data = this.bookings;
        this.table.renderRows();
      })
    );
  }

  onEmailInvoice(bookingId: number): void {
    let item = this.bookings.find((item) => item.id === bookingId);
    if (!item.buttonDisabled) {
      this.disableBookingButton(item);
      this.paymentPortalApiService.emailInvoice(bookingId);
    }
  }

  disableBookingButton(booking: TableBooking) {
    booking.buttonDisabled = true;
    setTimeout(() => {
      booking.buttonDisabled = false;
    }, 10000);
  }
}
