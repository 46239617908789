<form [formGroup]="bundleForm"
      (ngSubmit)="onSubmitClick()">
  <p class="mat-h2"><strong>Bundle Global settings</strong></p>
  <div class="bundle-global-settings form-sub-section">
    <p class="mat-h3"><strong>Main</strong></p>
    <div class="bundle-main-settings"
         fxLayout="row"
         fxLayout.xs="column"
         fxLayoutAlign="space-between">
      <mat-form-field appearance="outline"
                      fxFlex="grow">
        <mat-label>Bundle Title</mat-label>
        <input matInput
               formControlName="title"
               required>
      </mat-form-field>
      <mat-form-field appearance="outline"
                      fxFlex="grow">
        <mat-label>Description</mat-label>
        <textarea matInput
                  matTextareaAutosize
                  matAutosizeMinRows=1
                  matAutosizeMaxRows=5
                  formControlName="description"></textarea>
      </mat-form-field>
      <mat-form-field appearance="outline"
                      fxFlex="grow">
        <mat-label>Bundle Discount</mat-label>
        <input matInput
               type="number"
               formControlName="discount">
        <span matSuffix>&nbsp; %</span>
      </mat-form-field>
      <bundle-category fxFlex="grow"
                       [bundleCategory]="bundleCategory"
                       [isDisabled]="isSubmitLoading"></bundle-category>
      <div fxFlex="0 0 80px"
           fxShow.xs="false"></div>
    </div>

    <p class="mat-h3"><strong>Pricing categories</strong></p>
    <div class="form-sub-section"
         formArrayName="pricingCategories">
      <div *ngFor="let category of pricingCategories.controls; let categoryIndex = index; let isLast = last"
           [formGroupName]="categoryIndex"
           fxLayout="row"
           fxLayout.xs="column"
           fxLayoutAlign="space-between">
        <mat-form-field fxFlex="grow">
          <mat-label>Title</mat-label>
          <input matInput
                 formControlName="title"
                 required>
        </mat-form-field>
        <mat-form-field fxFlex="grow">
          <mat-label>Minimum age</mat-label>
          <input matInput
                 formControlName="minAge">
        </mat-form-field>
        <mat-form-field fxFlex="grow">
          <mat-label>Maximum age</mat-label>
          <input matInput
                 formControlName="maxAge">
        </mat-form-field>
        <mat-checkbox fxLayout="row"
                      fxLayoutAlign="center center"
                      color="primary"
                      formControlName="defaultCategory"
                      (change)="onDefaultPricingCategoryChange(categoryIndex)">
          Default
        </mat-checkbox>

        <div class="action-buttons"
             fxFlexAlign="end"
             fxLayout="row"
             fxFlex="0 0 80px">
          <button fxFlex="0 0 40px"
                  type="button"
                  color="warn"
                  mat-icon-button
                  matTooltip="Remove pricing category"
                  (click)="removePricingCategory(categoryIndex, category.value.id)"
                  [disabled]="isSubmitLoading">
            <mat-icon>clear</mat-icon>
          </button>
          <button *ngIf="isLast"
                  fxFlex="0 0 40px"
                  type="button"
                  mat-icon-button
                  color="primary"
                  matTooltip="Add pricing category"
                  (click)="addPricingCategory()"
                  [disabled]="isSubmitLoading">
            <mat-icon>add</mat-icon>
          </button>
        </div>
      </div>
    </div>

    <p class="mat-h3"><strong>Master pickup/dropoff</strong></p>
    <div class="form-sub-section"
         fxLayout="row"
         fxLayout.xs="column">
      <mat-slide-toggle color="primary"
                        formControlName="pickupRequired">
        Pickup required
      </mat-slide-toggle>
      <mat-slide-toggle color="primary"
                        formControlName="dropoffRequired">
        Dropoff required
      </mat-slide-toggle>
    </div>
  </div>

  <div formArrayName="days">
    <p class="mat-h2"><strong>Bundle Item Settings</strong></p>
    <div class="form-sub-section">
      <mat-card-content>
        <p class="mat-h3">
          <strong>Days</strong>
        </p>
        <div *ngFor="let day of days.controls; let dayIndex = index"
             class="form-sub-section">
          <div fxLayout="row">
            <p class="mat-h4 day-header"><strong>Day {{day.value.dayNo}}</strong></p>
            <div class="action-buttons">
              <button type="button"
                      color="warn"
                      mat-icon-button
                      matTooltip="Remove day"
                      (click)="removeDay(dayIndex)"
                      [disabled]="isSubmitLoading">
                <mat-icon>clear</mat-icon>
              </button>
              <button type="button"
                      color="primary"
                      mat-icon-button
                      matTooltip="Add day before or after this day"
                      (click)="addDay(dayIndex)"
                      [disabled]="isSubmitLoading">
                <mat-icon>add</mat-icon>
              </button>
            </div>
          </div>

          <div [formGroupName]="dayIndex"
               class="form-sub-section">
            <mat-accordion multi
                           formArrayName="items">
              <div *ngFor="let item of day.get('items')['controls']; let itemIndex = index"
                   fxLayout="row">
                <bundle-item fxFlex="1 1 90%"
                             [itemForm]="days.at(dayIndex).get('items')['controls'][itemIndex]"
                             [bundlePricingCategories]="pricingCategories.value"
                             [bundleDiscount]="discount.value"
                             [disabled]="isSubmitLoading"></bundle-item>
                <button type="button"
                        color="warn"
                        mat-icon-button
                        matTooltip="Remove bundle item"
                        (click)="removeItem(day, itemIndex)"
                        [disabled]="isSubmitLoading">
                  <mat-icon>clear</mat-icon>
                </button>
              </div>
            </mat-accordion>
            <div>
              <button type="button"
                      mat-button
                      color="primary"
                      matTooltip="Add bundle item"
                      (click)="addItem(day)"
                      [disabled]="isSubmitLoading">
                ADD ITEM
              </button>
            </div>
          </div>

        </div>
        <div fxLayout="row"
             fxLayoutAlign="center">
          <button fxFlex="0 0 20%"
                  fxFlex.xs="grow"
                  fxFlexAlign="center"
                  (click)="addLastDay()"
                  type="button"
                  mat-raised-button
                  color="primary"
                  matTooltip="Add day after the current last day"
                  [disabled]="isSubmitLoading">
            ADD DAY
          </button>
        </div>
      </mat-card-content>
    </div>
  </div>

  <mat-card-actions align="end">
    <button type="submit"
            mat-raised-button
            color="accent"
            matTooltip="Save bundle"
            [disabled]="bundleForm.invalid || isSubmitLoading"
            [class.button-spinner]="isSubmitLoading">SAVE</button>
  </mat-card-actions>
</form>
