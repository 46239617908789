<div class="mat-elevation-z8">
  <mat-table matSort
             [dataSource]="dataSource"
             aria-label="Bundles">
    <ng-container matColumnDef="id">
      <mat-header-cell *matHeaderCellDef
                       mat-sort-header>Id</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.id}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="title">
      <mat-header-cell *matHeaderCellDef
                       mat-sort-header>Title</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.title}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="category">
      <mat-header-cell *matHeaderCellDef
                       mat-sort-header>Category</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.category?.title}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="description">
      <mat-header-cell *matHeaderCellDef
                       mat-sort-header>Description</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.description}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="active">
      <mat-header-cell *matHeaderCellDef
                       mat-sort-header>Active</mat-header-cell>
      <mat-cell *matCellDef="let row">
        <mat-icon *ngIf="row.active"
                  color="primary">done</mat-icon>
        <mat-icon *ngIf="!row.active"
                  color="warn">clear</mat-icon>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
      <mat-cell *matCellDef="let row">
        <button mat-icon-button
                matTooltip="Click to edit bundle"
                color="primary"
                [routerLink]="[row.id]"
                [disabled]="row.isLoading">
          <mat-icon aria-label="Edit">edit</mat-icon>
        </button>
        <button *ngIf="!row.active"
                mat-button
                color="primary"
                (click)="onEnableClick(row.id)"
                [class.button-spinner]="row.isLoading"
                [disabled]="row.isLoading">Activate</button>
        <button *ngIf="row.active"
                mat-button
                color="warn"
                (click)="onDisableClick(row)"
                [class.button-spinner]="row.isLoading"
                [disabled]="row.isLoading">Deactivate</button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>

  <mat-paginator #paginator
                 [length]="dataSource?.data.length"
                 [pageIndex]="0"
                 [pageSize]="50"
                 [pageSizeOptions]="[25, 50, 100, 250]">
  </mat-paginator>
</div>
