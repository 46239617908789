import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { tap } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import TextContext from '../models/text-content';

@Injectable({
  providedIn: 'root',
})
export class TextChannelDataApiService {
  private serverUrl = environment.serverUrl;

  constructor(private http: HttpClient, private snackBar: MatSnackBar) {}

  getTermsByLanguage(siteId: string, lang: string): Observable<TextContext> {
    let params = new HttpParams();

    return this.http
      .get<TextContext>(`${this.serverUrl}/api/site/${siteId}/terms/${lang}`, { params });
  }

  getCancelPageByLanguage(siteId: string, lang: string): Observable<TextContext> {
    let params = new HttpParams();

    return this.http
      .get<TextContext>(`${this.serverUrl}/api/site/${siteId}/cancel/${lang}`, { params });
  }

  getSuccessPageByLanguage(siteId: string, lang: string): Observable<TextContext> {
    let params = new HttpParams();

    return this.http
      .get<TextContext>(`${this.serverUrl}/api/site/${siteId}/successpage/${lang}`, { params });
  }

  updateTermsByLanguage(siteId: string, lang: string, textContent: TextContext): Observable<TextContext> {
    return this.http
      .post<TextContext>(`${this.serverUrl}/api/site/${siteId}/terms/${lang}`, textContent)
      .pipe(
        tap(() => this.showSuccessSnack('Terms saved'))
      );
  }

  updateCancelPageByLanguage(siteId: string, lang: string, textContent: TextContext): Observable<TextContext> {
    return this.http
      .post<TextContext>(`${this.serverUrl}/api/site/${siteId}/cancel/${lang}`, textContent)
      .pipe(
        tap(() => this.showSuccessSnack('Cancel page saved'))
      );
  }

  updateSuccessPageByLanguage(siteId: string, lang: string, textContent: TextContext): Observable<TextContext> {
    return this.http
      .post<TextContext>(`${this.serverUrl}/api/site/${siteId}/successpage/${lang}`, textContent)
      .pipe(
        tap(() => this.showSuccessSnack('Success page saved'))
      );
  }

  private showSuccessSnack(message: string): void {
    this.snackBar.open(
      message,
      'Close',
      { duration: 2000, panelClass: ['success-snack'] }
    );
  }
}
