import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { isEqual } from 'lodash-es';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Bundle } from '../bundle-edit/models/bundle';
import { BooleanModalComponent } from '../shared/components/boolean-modal/boolean-modal.component';
import { ShouldLeaveFormModalComponent } from '../shared/components/should-leave-form-modal/should-leave-form-modal.component';
import { BooleanModalData } from '../shared/models/boolean-modal-data';
import { ShouldLeaveFormModalData } from '../shared/models/should-leave-form-modal-data';
import { SharedApiService } from '../shared/services/shared-api.service';
import { BundleFormComponent } from './components/bundle-form/bundle-form.component';
import { BundleEditApiService } from './services/bundle-edit-api.service';

@Component({
  selector: 'bundle-edit',
  templateUrl: './bundle-edit.component.html',
  styleUrls: ['./bundle-edit.component.scss']
})
export class BundleEditComponent implements OnInit {
  @ViewChild(BundleFormComponent) bundleFormComponent: BundleFormComponent;

  isBundleActiveToggleLoading: boolean;
  isSubmitLoading: boolean;
  routeId: string = this.route.snapshot.paramMap.get('id');
  bundle: Bundle;

  constructor(
    private bundleEditApiService: BundleEditApiService,
    private sharedApiService: SharedApiService,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    if (this.routeId !== 'new') this.getBundle(this.routeId);
    else this.bundle = new Bundle();
  }

  canDeactivate() {
    const mergedValues = {
      ...this.bundle,
      ...this.bundleFormComponent.bundleForm.getRawValue()
    };
    const hasBundleChanged = !isEqual(this.bundle, mergedValues);
    const data = {
      saveFormFunc: this.routeId !== 'new' ? this.editBundle(mergedValues) : this.saveBundle(mergedValues),
      isFormValid: this.bundleFormComponent.bundleForm.valid
    } as ShouldLeaveFormModalData;

    if (hasBundleChanged) {
      const dialogRef = this.dialog.open(ShouldLeaveFormModalComponent, { width: '500px', data });

      return dialogRef.afterClosed().pipe(
        map((shouldLeave: boolean) => {
          this.isSubmitLoading = false;
          return shouldLeave;
        }));
    } else return true;
  }

  onBundleActiveToggle(currentActiveValue: boolean): void {
    if (this.bundle.id !== 0) {
      if (!currentActiveValue)
        this.enableBundle(this.bundle.id);
      else {
        const data = new BooleanModalData(`Are you sure you want to deactivate this bundle?`);
        const dialogRef = this.dialog.open(BooleanModalComponent, {
          width: '360px',
          data
        });

        dialogRef.afterClosed().subscribe((shouldDisable: boolean) => {
          if (shouldDisable)
            this.disableBundle(this.bundle.id);
        });
      }
    } else {
      this.bundle.active = !currentActiveValue;
    }
  }

  onSubmit(): void {
    const mergedValues = {
      ...this.bundle,
      ...this.bundleFormComponent.bundleForm.getRawValue()
    };

    if (this.routeId !== 'new') this.editBundle(mergedValues).subscribe();
    else this.saveBundle(mergedValues).subscribe((res) => {
      this.routeId = `${res.id}`;
      this.router.navigate([`../${res.id}`], { relativeTo: this.route });
    });
  }

  private disableBundle(id: number): void {
    this.isBundleActiveToggleLoading = true;

    this.sharedApiService.disableBundle(id).subscribe(() => {
      this.isBundleActiveToggleLoading = false;
      this.bundle.active = false;
    });
  }

  private editBundle(bundle: Bundle): Observable<Bundle> {
    this.isSubmitLoading = true;

    return this.bundleEditApiService.editBundle(bundle).pipe(
      tap(res => {
        this.isSubmitLoading = false;
        this.bundle = res;
      }));
  }

  private enableBundle(id: number): void {
    this.isBundleActiveToggleLoading = true;

    this.sharedApiService.enableBundle(id).subscribe(() => {
      this.isBundleActiveToggleLoading = false;
      this.bundle.active = true;
    });
  }

  private getBundle(id: string): void {
    this.bundleEditApiService.getBundle(id).subscribe((res: Bundle) => this.bundle = res);
  }

  private saveBundle(bundle: Bundle): Observable<Bundle> {
    this.isSubmitLoading = true;

    return this.bundleEditApiService.saveBundle(bundle).pipe(
      tap((res: Bundle) => {
        this.isSubmitLoading = false;
        this.bundle = res;

      }));
  }
}
