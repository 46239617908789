import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatOptionSelectionChange } from '@angular/material/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Activity } from '../../models/activity';
import { ActivityExtra } from '../../models/activity-extra';
import { ActivityPlaceSelectionType } from '../../models/activity-place-selection-type.enum';
import { BundleItem } from '../../models/bundle-item';
import { BundleItemExtra } from '../../models/bundle-item-extra';
import { BundleItemStartTime } from '../../models/bundle-item-start-time';
import { BundlePlaceSelectionType } from '../../models/bundle-place-selection-type.enum';
import { ExtraConfig } from '../../models/extra-config';
import { ExtraSelectionType } from '../../models/extra-selection-type.enum';
import { GetActivityRequest } from '../../models/get-activity-request';
import { PricingCategory } from '../../models/pricing-category';
import { Rate } from '../../models/rate';
import { StartTime } from '../../models/start-time';
import { InvSysDataService } from '../../services/inv-sys-data.service';

function customStartTimeValidator(): ValidatorFn {
  return (bundleItemForm: UntypedFormGroup) => {
    const customizedCtrl = bundleItemForm.get('customized');
    const startingTimesCtrl = bundleItemForm.get('startingTimes');

    if (!customizedCtrl || !startingTimesCtrl) {
      return null;
    }

    const customizedValue = customizedCtrl.value;

    if (!customizedValue) {
      return null;
    }

    const startingTimesValue = startingTimesCtrl.value as BundleItemStartTime[];

    if (!startingTimesValue) {
      return null;
    }

    if (!startingTimesValue.length) {
      startingTimesCtrl.setErrors({ required: true });
    }

    if (startingTimesValue.length === 1) {
      startingTimesCtrl.setErrors(null);
    }

    if (customizedValue && startingTimesValue.length > 1) {
      startingTimesCtrl.setErrors({ noMoreThanOneStartTimeWhenCustom: true });
      return { noMoreThanOneStartTimeWhenCustom: true };
    }

    return null;
  };
}

@Component({
  selector: 'bundle-item',
  templateUrl: './bundle-item.component.html',
  styleUrls: ['./bundle-item.component.scss']
})
export class BundleItemComponent implements OnInit {
  @ViewChild(MatExpansionPanel) private matExpansionPanel: MatExpansionPanel;
  @Input() itemForm: UntypedFormGroup;
  @Input() disabled: boolean;
  @Input() bundlePricingCategories: PricingCategory[];
  @Input() bundleDiscount: number;

  // TODO: move magic strings to a separate resource
  dataNotLoadedTooltip = 'To edit this bundle item fully please load data from inventory system by pressing the cloud icon';
  notAssignedToRateToolTip = 'Not assigned to rate. Check inventory system configuration';
  occupancyNotSupportedTooltip = 'Occupancy above 1 currently not supported';
  extraSelectionTypeTooltip = 'Note that preselected extras in inventory system will be added';
  pickupDropoffSelectionTypeEnum = BundlePlaceSelectionType;
  extraSelectionTypeEnum = ExtraSelectionType;

  get formValue(): BundleItem {
    return this.itemForm.getRawValue();
  }

  get idInInvSys(): number {
    return this.itemForm.get('idInInvSys').value;
  }

  get hasActivity(): boolean {
    return this.invSysDataService.loadedData.has(this.idInInvSys);
  }

  get activity(): Activity {
    return this.invSysDataService.loadedData.get(this.idInInvSys);
  }

  get isActivityLoading(): boolean {
    return this.invSysDataService.isLoading.get(this.idInInvSys);
  }

  get customized(): UntypedFormControl {
    return this.itemForm.get('customized') as UntypedFormControl;
  }

  get customizedTime(): UntypedFormControl {
    return this.itemForm.get('customizedTime') as UntypedFormControl;
  }

  get active(): UntypedFormControl {
    return this.itemForm.get('active') as UntypedFormControl;
  }

  get startingTimes(): UntypedFormControl {
    return this.itemForm.get('startingTimes') as UntypedFormControl;
  }

  get pricingCategories(): UntypedFormArray {
    return this.itemForm.get('pricingCategories') as UntypedFormArray;
  }

  get pickupSelectionType(): UntypedFormControl {
    return this.itemForm.get('pickupSelectionType') as UntypedFormControl;
  }

  get pickupPlaceIdInInvSys(): UntypedFormControl {
    return this.itemForm.get('pickupPlaceIdInInvSys') as UntypedFormControl;
  }

  get pickupPlaceTitleInInvSys(): UntypedFormControl {
    return this.itemForm.get('pickupPlaceTitleInInvSys') as UntypedFormControl;
  }

  get customPickupPlaceDescription(): UntypedFormControl {
    return this.itemForm.get('customPickupPlaceDescription') as UntypedFormControl;
  }

  get dropoffSelectionType(): UntypedFormControl {
    return this.itemForm.get('dropoffSelectionType') as UntypedFormControl;
  }

  get dropoffPlaceIdInInvSys(): UntypedFormControl {
    return this.itemForm.controls.dropoffPlaceIdInInvSys as UntypedFormControl;
  }

  get dropoffPlaceTitleInInvSys(): UntypedFormControl {
    return this.itemForm.controls.dropoffPlaceTitleInInvSys as UntypedFormControl;
  }

  get customDropoffPlaceDescription(): UntypedFormControl {
    return this.itemForm.controls.customDropoffPlaceDescription as UntypedFormControl;
  }

  get extras(): UntypedFormControl {
    return this.itemForm.controls.extras as UntypedFormControl;
  }

  get extraSelectionType(): UntypedFormControl {
    return this.itemForm.controls.extraSelectionType as UntypedFormControl;
  }

  constructor(
    private invSysDataService: InvSysDataService,
    private fb: UntypedFormBuilder,
    private snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    this.customizedTime.disable();
    this.itemForm.setValidators(customStartTimeValidator());
    this.itemForm.updateValueAndValidity();
  }

  onActiveClick(event: Event): void {
    event.stopPropagation();
  }

  onActiveChange(isChecked: boolean): void {
    this.active.setValue(isChecked);
  }

  onLoadItemData(): void {
    const request = { id: this.formValue.idInInvSys, invSys: this.formValue.invSys, type: this.formValue.type } as GetActivityRequest;

    this.invSysDataService.loadActivity(request).subscribe(() => {
      this.matExpansionPanel.open();
      this.customizedTime.enable();
      this.itemForm.markAllAsTouched();
      this.deselectIncorrectBundleItemFields();
    });
  }

  compareStartTimeOptionWithSelection(option: any, selection: BundleItemStartTime): boolean {
    // tslint:disable-next-line: triple-equals
    return option.id == selection.startTimeIdInInvSys || option.startTimeIdInInvSys == selection.startTimeIdInInvSys;
  }

  startTimeSelectSortComparator(): number {
    return 1;
  }

  isStartTimeAssignedToRate(startTimeIdInInvSys: number): boolean {
    const startTimeIdsAssignedToRate: number[] = [].concat(...this.activity.rates.map(i => i.startTimeIds));

    return startTimeIdsAssignedToRate.some(i => i === startTimeIdInInvSys);
  }

  onStartTimeChange(control: UntypedFormControl, event: MatOptionSelectionChange): void {
    if (event.isUserInput) {
      const { value: startTime, selected } = event.source as { value: StartTime, selected: boolean; };

      if (selected) {
        const { rates } = this.activity;
        const ratesWithStartTimes = rates.filter(i => i.startTimeIds.some(id => id === startTime.id));

        const bundleStartTime = {
          startTimeIdInInvSys: startTime.id,
          startTimeInInvSys: startTime.startTime,
          rateIdInInvSys: ratesWithStartTimes[0].id,
          rateTitleInInvSys: ratesWithStartTimes[0].title
        };
        const newValue = [...control.value, bundleStartTime];

        control.setValue(newValue);
      }
      else {
        const newValue = this.startingTimes.value.filter((i: BundleItemStartTime) => i.startTimeIdInInvSys !== startTime.id);
        control.setValue(newValue);
      }
    }
  }

  onCustomizedChange(checked: boolean): void {
    if (checked) {
      const startTimesValue = this.startingTimes.value as BundleItemStartTime[];

      if (startTimesValue && startTimesValue.length > 1) {
        this.startingTimes.setValue([]);
        this.startingTimes.markAllAsTouched();
      }
      if (!this.customizedTime) {
        const customizedStartTimeCtrl: UntypedFormControl = this.fb.control('', Validators.required);

        this.itemForm.addControl('customizedTime', customizedStartTimeCtrl);
      }
      else this.customizedTime.enable();
    }
    else
      this.itemForm.removeControl('customizedTime');
  }

  getBundleItemPricingCategoryRateOptions(startTimeIdInInvSys: number): Rate[] {
    if (!this.activity)
      return [];

    return this.activity.rates.filter(i => i.startTimeIds.some(id => id === startTimeIdInInvSys));
  }

  compareRateOptionToSelection(option: Rate, selection: BundleItemStartTime): boolean {
    // tslint:disable-next-line: triple-equals
    return option.id == selection.rateIdInInvSys;
  }

  onRateChange(rate: Rate, time: BundleItemStartTime): void {
    const newTime = {
      ...time,
      rateTitleInInvSys: rate.title,
      rateIdInInvSys: rate.id
    };
    const newStartingTimes: BundleItemStartTime[] = [...this.startingTimes.value];

    const timeIndex = this.startingTimes.value.findIndex((i: BundleItemStartTime) => i.startTimeIdInInvSys === time.startTimeIdInInvSys);

    newStartingTimes.splice(timeIndex, 1, newTime);

    this.startingTimes.setValue(newStartingTimes);
  }

  getBundleItemPricingCategoryLabel(id: number): string {
    const bundlePricingCategory = this.bundlePricingCategories.find(i => i.id === id);

    if (!bundlePricingCategory)
      return '';

    return bundlePricingCategory.title;
  }

  comparePricingCategoryOptionToSelection(option: any, selection: number): boolean {
    // tslint:disable-next-line: triple-equals
    return option?.pricingCategoryIdInInvSys ? option.pricingCategoryIdInInvSys == selection : option == selection;
  }

  onPricingCategoryChange(category: UntypedFormGroup, value: number): void {
    const foundCategory = this.activity.pricingCategories.find(i => i.id === value);

    const titleControl = category.get('pricingCategoryTitleInInvSys');
    titleControl.patchValue(foundCategory.title);
  }

  isPickupPlaceSelectionTypeDisabled(selectionType: BundlePlaceSelectionType): boolean {
    if (selectionType !== BundlePlaceSelectionType.Independent) {
      if (!this.activity)
        return true;

      const selectedRateIds: number[] = this.startingTimes.value.map((i: BundleItemStartTime) => i.rateIdInInvSys);
      // tslint:disable-next-line: triple-equals
      const selectedRates: Rate[] = this.activity.rates.filter(i => selectedRateIds.some(id => id == i.id));

      return !selectedRates.length || selectedRates.some(i => i.pickupSelectionType === ActivityPlaceSelectionType.Unavailable);
    }

    return false;
  }

  onPickupSelectionTypeChange(selectionType: BundlePlaceSelectionType): void {
    if (selectionType !== BundlePlaceSelectionType.Fixed) {
      this.pickupPlaceIdInInvSys.reset();
      this.pickupPlaceIdInInvSys.setErrors(null);
      this.pickupPlaceTitleInInvSys.reset();
      this.pickupPlaceTitleInInvSys.setErrors(null);
      this.customPickupPlaceDescription.reset();
      this.customPickupPlaceDescription.setErrors(null);
    }
  }

  onFixedPickupChange(placeId: number): void {
    if (placeId !== -2) {
      this.customPickupPlaceDescription.reset();
      this.customPickupPlaceDescription.setErrors(null);
    }

    const foundPlace = this.activity?.places?.pickupPlaces?.find(i => i.id === placeId);

    if (foundPlace)
      this.pickupPlaceTitleInInvSys.setValue(foundPlace.title);
  }

  isPickupPlaceOptionDisabled(placeId: number): boolean {
    if (!this.activity)
      return true;

    if (placeId === -1) {
      const selectedRateIds: number[] = this.startingTimes.value.map((i: BundleItemStartTime) => i.rateIdInInvSys);
      // tslint:disable-next-line: triple-equals
      const selectedRates: Rate[] = this.activity.rates.filter(i => selectedRateIds.some(id => id == i.id));

      return !selectedRates.length || selectedRates.some(i => i.pickupSelectionType === ActivityPlaceSelectionType.Preselected);
    }

    return false;
  }

  isDropoffPlaceSelectionTypeDisabled(selectionType: BundlePlaceSelectionType): boolean {
    if (selectionType !== BundlePlaceSelectionType.Independent) {
      if (!this.activity)
        return true;

      const selectedRateIds: number[] = this.startingTimes.value.map((i: BundleItemStartTime) => i.rateIdInInvSys);
      // tslint:disable-next-line: triple-equals
      const selectedRates: Rate[] = this.activity.rates.filter(i => selectedRateIds.some(id => id == i.id));

      return !selectedRates.length || selectedRates.some(i => i.dropoffSelectionType === ActivityPlaceSelectionType.Unavailable);
    }

    return false;
  }

  onDropoffSelectionTypeChange(selectionType: BundlePlaceSelectionType): void {
    if (selectionType !== BundlePlaceSelectionType.Fixed) {
      this.dropoffPlaceIdInInvSys.reset();
      this.dropoffPlaceIdInInvSys.setErrors(null);
      this.dropoffPlaceTitleInInvSys.reset();
      this.dropoffPlaceTitleInInvSys.setErrors(null);
      this.customDropoffPlaceDescription.reset();
      this.customDropoffPlaceDescription.setErrors(null);
    }
  }

  onFixedDropoffChange(placeId: number): void {
    if (placeId !== -2) {
      this.customDropoffPlaceDescription.reset();
      this.customDropoffPlaceDescription.setErrors(null);
    }

    const foundPlace = this.activity?.places?.dropoffPlaces?.find(i => i.id === placeId);

    if (foundPlace) {
      this.dropoffPlaceTitleInInvSys.setValue(foundPlace.title);
    }
  }

  isDropoffPlaceOptionDisabled(placeId: number): boolean {
    if (!this.activity)
      return true;

    if (placeId === -1) {
      const selectedRateIds: number[] = this.startingTimes.value.map((i: BundleItemStartTime) => i.rateIdInInvSys);
      // tslint:disable-next-line: triple-equals
      const selectedRates: Rate[] = this.activity.rates.filter(i => selectedRateIds.some(id => id == i.id));

      return !selectedRates.length || selectedRates.some(i => i.dropoffSelectionType === ActivityPlaceSelectionType.Preselected);
    }

    return false;
  }

  comparePlaceOptionToSelection(option: number, selection: number): boolean {
    // tslint:disable-next-line: triple-equals
    return option == selection;
  }

  onExtraSelectionTypeChange(selectionType: ExtraSelectionType): void {
    if (selectionType !== ExtraSelectionType.Selected) {
      this.extras.reset([]);
      this.extras.setErrors(null);
    }
  }

  isExtraSelectionTypeOptionDisabled(selectionType: ExtraSelectionType): boolean {
    if (selectionType === ExtraSelectionType.Selected)
      return !this.activity;

    return false;
  }

  isExtraOptionDisabled(extraIdInInvSys: number): boolean {
    if (!this.activity)
      return true;

    const selectedRateIds: number[] = this.startingTimes.value.map((i: BundleItemStartTime) => i.rateIdInInvSys);
    // tslint:disable-next-line: triple-equals
    const selectedRates: Rate[] = this.activity.rates.filter(i => selectedRateIds.some(id => id == i.id));
    const selectedExtraConfigs: ExtraConfig[] = [].concat(...selectedRates.map(i => i.extraConfigs));

    return !selectedExtraConfigs.some(i => i.activityExtraId === extraIdInInvSys);
  }

  compareExtraOptionToSelection(option: any, selection: BundleItemExtra): boolean {
    // tslint:disable-next-line: triple-equals
    return option.extraIdInInvSys === selection.extraIdInInvSys || option.id == selection.extraIdInInvSys;
  }

  onExtrasChange(activityExtras: ActivityExtra[]): void {
    const newValue: BundleItemExtra[] = activityExtras.map(i => ({
      extraIdInInvSys: i.id,
      extraTitleInInvSys: i.title
    }));

    this.extras.setValue(newValue);
  }

  private deselectIncorrectBundleItemFields(): void {
    const currentStartTimes: BundleItemStartTime[] = this.startingTimes.value;
    const filteredStartTimes = currentStartTimes.filter((i) => this.isStartTimeAssignedToRate(i.startTimeIdInInvSys));

    if (currentStartTimes.length > filteredStartTimes.length) {
      this.startingTimes.setValue(filteredStartTimes);
      this.showWarningSnack(`Some start times have been de-selected due to inventory system configuration change. Please review this bundle and press save.`);
    }
  }

  private showWarningSnack(message: string): void {
    this.snackBar.open(
      message,
      'Close',
      { duration: 5000, panelClass: ['warning-snack'] }
    );
  }
}
