<form (ngSubmit)="onSubmit()"
      #newPaymentLinkForm="ngForm">
  <div mat-dialog-content>
    <p>Please fill in your preferred payment link parameters</p>
    <mat-form-field>
      <mat-label>Deposit</mat-label>
      <input matInput
             type="number"
             placeholder="Enter percentage..."
             [disabled]="isLoading"
             [(ngModel)]="data.depositPercentage"
             name="data.depositPercentage">
      <span matSuffix>&nbsp; %</span>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Expiration date</mat-label>
      <input matInput
             [matDatepicker]="expirationDatePicker"
             [disabled]="isLoading"
             [(ngModel)]="data.expirationDate"
             name="data.expirationDate"
             placeholder="Travel From">
      <mat-datepicker-toggle matSuffix
                             [for]="expirationDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #expirationDatePicker></mat-datepicker>
    </mat-form-field>
  </div>

  <div mat-dialog-actions
       align="center">
    <button mat-button
            type="button"
            [disabled]="isLoading"
            mat-dialog-close>Cancel</button>
    <button mat-button
            color="primary"
            type="submit"
            [disabled]="isLoading"
            [class.button-spinner]="isLoading">Save</button>
  </div>
</form>
