import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ExtraImage } from '../models/ExtraImage';

@Injectable({
  providedIn: 'root'
})
export class ExtraImagesApiService {
  private serverUrl = environment.serverUrl;

  constructor(private http: HttpClient, private snackBar: MatSnackBar) { }

  getExtraImages(): Observable<ExtraImage[]> {
    return this.http.get<ExtraImage[]>(`${this.serverUrl}/api/ExtraImage`).pipe(
      tap((res: ExtraImage[]) => res.sort((a, b) => a.title.localeCompare(b.title))),
    );
  }

  createExtraImage(extraImage: ExtraImage): Observable<ExtraImage> {
    return this.http.post<ExtraImage>(`${this.serverUrl}/api/ExtraImage`, extraImage).pipe(
      tap(() => this.showSuccessSnack('Extra image saved'))
    );
  }

  updateExtraImage(extraImage: ExtraImage): Observable<ExtraImage> {
    return this.http.put<ExtraImage>(`${this.serverUrl}/api/ExtraImage/${extraImage.id}`, extraImage).pipe(
      tap(() => this.showSuccessSnack('Extra image saved'))
    );
  }

  deleteExtraImage(id: number): Observable<null> {
    return this.http.delete<null>(`${this.serverUrl}/api/ExtraImage/${id}`).pipe(
      tap(() => this.showSuccessSnack('Extra image deleted'))
    );
  }

  private showSuccessSnack(message: string): void {
    this.snackBar.open(
      message,
      'Close',
      { duration: 2000, panelClass: ['success-snack'] }
    );
  }
}
