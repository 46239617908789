import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from '../app-routing.module';
import { SharedModule } from '../shared/shared.module';
import { ApiSettingsComponent } from './api-settings.component';
import { BookingEditFormComponent } from './components/booking-card-form/booking-card-form.component';
import { CalendarEditFormComponent } from './components/calendar-card-form/calendar-card-form.component';
import { ChannelEditFormComponent } from './components/channel-edit-form/channel-edit-form.component';
import { EditChannelsSettingsComponent } from './components/channels-settings/channels-settings.component';
import { CreateChannelModalComponent } from './components/create-channel-modal/create-channel-modal.component';
import { EditGlobalSettingsComponent } from './components/edit-global-settings/edit-global-settings.component';
import { PaymentEditFormComponent } from './components/payment-card-form/payment-card-form.component';
import {RichTextEditorComponent} from "./components/rich-text-editor/rich-text-editor";
import { AngularEditorModule } from '@kolkov/angular-editor';

@NgModule({
  declarations: [
    ApiSettingsComponent,
    EditGlobalSettingsComponent,
    EditChannelsSettingsComponent,
    ChannelEditFormComponent,
    CalendarEditFormComponent,
    BookingEditFormComponent,
    PaymentEditFormComponent,
    RichTextEditorComponent,
    PaymentEditFormComponent,
    CreateChannelModalComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    RouterModule,
    AppRoutingModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    AngularEditorModule],
})
export class ApiSettingsModule {
}
