import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog } from '@angular/material/dialog';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { BooleanModalComponent } from 'src/app/shared/components/boolean-modal/boolean-modal.component';
import { BooleanModalData } from 'src/app/shared/models/boolean-modal-data';
import { ExtraImage } from '../../models/ExtraImage';
import { ExtraImageTableRow } from '../../models/ExtraImageTableRow';
import { ExtraImagesApiService } from '../../services/extra-images-api.service';

@Component({
  selector: 'extra-images-table',
  templateUrl: './extra-images-table.component.html',
  styleUrls: ['./extra-images-table.component.scss']
})
export class ExtraImagesTableComponent implements OnInit, OnChanges {
  @Input() extraImages: ExtraImageTableRow[];
  @Input() form: UntypedFormGroup;
  @Output() saved = new EventEmitter<ExtraImageTableRow>();
  @ViewChild(MatTable) table: MatTable<ExtraImage>;

  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  dataSource: MatTableDataSource<ExtraImageTableRow>;
  displayedColumns = ['title', 'flag', 'keywords', 'url', 'actions'];

  get extraImagesFormArray(): UntypedFormArray {
    return this.form.get('extraImages') as UntypedFormArray;
  }

  constructor(public dialog: MatDialog, private extraImagesApiService: ExtraImagesApiService) { }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource<ExtraImageTableRow>(this.extraImages);
  }

  ngOnChanges() {
    this.resetTableView(this.extraImages);
  }

  onEditExtraImage(row: ExtraImageTableRow): void {
    row.isInEditState = true;
  }

  onSaveExtraImage(row: ExtraImageTableRow, index: number): void {
    row.isInEditState = false;
    const extraImage = { ...this.extraImagesFormArray.at(index).value, id: row.id } as ExtraImage;

    if (!row.id) {
      this.extraImagesApiService.createExtraImage(extraImage).subscribe((res: ExtraImage) => {
        this.saved.emit({ ...res, isInEditState: false, fakeId: row.fakeId });
        this.resetTableView(this.extraImages);
      });
    } else {
      this.extraImagesApiService.updateExtraImage(extraImage).subscribe((res: ExtraImage) => {
        this.saved.emit({ ...res, isInEditState: false });
        this.resetTableView(this.extraImages);
      });
    }
  }

  onRemoveExtraImage(id: number, index: number): void {
    const removeExtraImage = () => {
      this.extraImages.splice(index, 1);
      this.extraImagesFormArray.removeAt(index);
      this.resetTableView(this.extraImages);
    };

    if (!id)
      removeExtraImage();
    else {
      const question = 'Are you sure you want to remove this extra image?';
      const data = new BooleanModalData(question);

      const dialogRef = this.dialog.open(BooleanModalComponent, {
        width: '300px',
        data
      });

      dialogRef.afterClosed().subscribe((res: boolean) => {
        if (res)
          this.extraImagesApiService.deleteExtraImage(id).subscribe(() => removeExtraImage());
      });
    }
  }

  onAddKeyword(event: MatChipInputEvent, extraImageIndex: number): void {
    const { input, value } = event;
    const keywordsControl = this.extraImagesFormArray.at(extraImageIndex).get('keywords') as UntypedFormControl;

    if ((value || '').trim()) {
      keywordsControl.setValue([...keywordsControl.value, value.trim()]);
      keywordsControl.updateValueAndValidity();
    }

    if (input) {
      input.value = '';
    }
  }

  onRemoveKeyword(extraImageIndex: number, keywordIndex: number): void {
    const keywordsControl = this.extraImagesFormArray.at(extraImageIndex).get('keywords') as UntypedFormControl;

    keywordsControl.value.splice(keywordIndex, 1);
    keywordsControl.updateValueAndValidity();
  }

  private resetTableView(extraImages: ExtraImageTableRow[]): void {
    if (this.table) {
      this.dataSource.data = extraImages;
      this.table.renderRows();
    }
  }
}
