<div fxLayout="row"
     [formGroup]="itemForm">
  <mat-expansion-panel class="mat-elevation-z2">
    <mat-expansion-panel-header fxLayout="row"
                                class="mat-elevation-z8">
      <mat-panel-title class="mat-body-1"
                       fxFlex="1 1 100%"
                       fxLayout="row"
                       fxLayoutGap="10px">
        <mat-button-toggle appearance="legacy"
                           class="circle-toggle"
                           matTooltip="Activate/deactivate bundle item"
                           [checked]="active.value"
                           (change)="onActiveChange($event.source.checked)"
                           (click)="onActiveClick($event)"></mat-button-toggle>
        <ng-container *ngIf="!customized.value; else customTime;">
          <span fxLayout="row inline"
                fxLayoutAlign="start center"
                *ngFor="let time of formValue.startingTimes">
            {{time.startTimeInInvSys}}&nbsp;
          </span>
        </ng-container>
        <ng-template #customTime>
          <span fxLayout="row inline"
                fxLayoutAlign="start center">
            {{customizedTime.value}}&nbsp;
          </span>
        </ng-template>
      </mat-panel-title>
      <mat-panel-description fxFlex="1 1 100%"
                             fxLayout="row inline"
                             fxLayoutAlign="start center">
        {{formValue.invSys}} - {{formValue.titleInInvSys}}
      </mat-panel-description>
    </mat-expansion-panel-header>

    <div fxLayout="column"
         [matTooltip]="!hasActivity ? dataNotLoadedTooltip : ''">
      <div fxLayout="row"
           fxLayout.xs="column"
           fxLayoutAlign="space-between">
        <div fxFlex="1 1 100%"
             fxLayout="column">
          <p class="mat-h4"><strong>Start times and rates</strong></p>
          <div fxLayout="row">
            <mat-form-field fxFlex="grow">
              <mat-label>Start times</mat-label>
              <mat-select multiple
                          [formControl]="startingTimes"
                          [compareWith]="compareStartTimeOptionWithSelection"
                          [sortComparator]="startTimeSelectSortComparator"
                          [disabled]="!hasActivity"
                          required>
                <ng-container *ngIf="!hasActivity">
                  <mat-option *ngFor="let time of startingTimes.value"
                              [value]="time">
                    {{time.startTimeInInvSys}} {{time.rateTitleInInvSys}}
                  </mat-option>
                </ng-container>
                <mat-option *ngFor="let time of activity?.startTimes"
                            (onSelectionChange)="onStartTimeChange(startingTimes, $event)"
                            [value]="time"
                            [disabled]="!isStartTimeAssignedToRate(time.id)"
                            [matTooltip]="!isStartTimeAssignedToRate(time.id) ? notAssignedToRateToolTip : ''">
                  {{time.startTime}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="startingTimes.hasError('required')">Select at least one start time</mat-error>
              <mat-error *ngIf="startingTimes.hasError('noMoreThanOneStartTimeWhenCustom')">
                No more than one start time allowed when using custom start time option
              </mat-error>
            </mat-form-field>
          </div>

          <div fxLayout="row wrap"
               fxLayout.lt-lg="column">
            <ng-container *ngFor="let time of startingTimes.value; let timeIndex = index">
              <mat-form-field fxFlex="1 1 33%"
                              fxFlex.lt-lg="grow"
                              *ngIf="getBundleItemPricingCategoryRateOptions(time.startTimeIdInInvSys).length > 1">
                <mat-label>{{time.startTimeInInvSys}} time rate</mat-label>
                <mat-select [value]="time"
                            [compareWith]="compareRateOptionToSelection"
                            (selectionChange)="onRateChange($event.value, time)">
                  <mat-option *ngFor="let rate of getBundleItemPricingCategoryRateOptions(time.startTimeIdInInvSys)"
                              [value]="rate">
                    {{rate.title}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </ng-container>
          </div>

          <div fxLayout="row">
            <mat-checkbox color="primary"
                          fxFlex="grow"
                          fxLayout="row"
                          fxLayoutAlign="start center"
                          formControlName="customized"
                          [disabled]="!hasActivity || !activity.allowCustomizedBookings"
                          [matTooltip]="hasActivity && !activity.allowCustomizedBookings ? 'Customized bookings are not allowed on this tour.' : ''"
                          (change)="onCustomizedChange($event.checked)">
              Customize start time
            </mat-checkbox>
            <mat-form-field fxFlex="grow"
                            *ngIf="customizedTime && customized.value">
              <mat-label>Custom start time</mat-label>
              <input matInput
                     mask="Hh:m0"
                     [dropSpecialCharacters]="false"
                     type="text"
                     formControlName="customizedTime"
                     placeholder="Enter a custom start time..."
                     required
                     matTooltip="One regular start time still needs to be selected above for pricing.">
              <mat-hint><strong>e.g. 18:30</strong></mat-hint>
            </mat-form-field>
          </div>
        </div>

        <div fxFlex="1 1 100%">
          <p class="mat-h4"><strong>Pricing category mappings</strong></p>
          <div fxLayout="row wrap"
               fxLayout.lt-lg="column"
               formArrayName="pricingCategories">
            <mat-form-field fxFlex="1 1 33%"
                            fxFlex.lt-lg="grow"
                            *ngFor="let category of pricingCategories.controls; let categoryIndex = index"
                            [formGroupName]="categoryIndex">
              <mat-label>
                {{getBundleItemPricingCategoryLabel(category.value.bundlePricingCategoryId)}}
              </mat-label>
              <mat-select formControlName="pricingCategoryIdInInvSys"
                          [compareWith]="comparePricingCategoryOptionToSelection"
                          (selectionChange)="onPricingCategoryChange(category, $event.value)"
                          required
                          [disabled]="!hasActivity">
                <mat-option *ngIf="!hasActivity"
                            [value]="category.value">
                  {{category.value.pricingCategoryTitleInInvSys}}
                </mat-option>
                <mat-option *ngFor="let activityCategory of activity?.pricingCategories"
                            [value]="activityCategory.id"
                            [disabled]="activityCategory.occupancy > 1"
                            [matTooltip]="activityCategory.occupancy > 1 ? occupancyNotSupportedTooltip : ''">
                  {{activityCategory.title}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div fxLayout="row"
           fxLayout.xs="column"
           fxLayoutAlign="space-between">
        <div fxFlex="1 1 100%">
          <p class="mat-h4"><strong>Pickup</strong></p>
          <div fxLayout="row wrap"
               fxLayout.lt-lg="column">
            <mat-form-field fxFlex="1 1 33%"
                            fxFlex.lt-lg="grow">
              <mat-label>Pickup selection type</mat-label>
              <mat-select formControlName="pickupSelectionType"
                          (selectionChange)="onPickupSelectionTypeChange($event.value)">
                <mat-option *ngFor="let type of pickupDropoffSelectionTypeEnum | keyvalue"
                            [value]="type.value"
                            [disabled]="isPickupPlaceSelectionTypeDisabled(type.value)">
                  {{type.key}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field fxFlex="1 1 33%"
                            fxFlex.lt-lg="grow"
                            *ngIf="pickupSelectionType.value === pickupDropoffSelectionTypeEnum.Fixed"
                            floatLabel="always">
              <mat-label>Fixed pickup place</mat-label>
              <mat-select placeholder="Select a pickup place"
                          formControlName="pickupPlaceIdInInvSys"
                          (selectionChange)="onFixedPickupChange($event.value)"
                          [compareWith]="comparePlaceOptionToSelection"
                          required>
                <mat-option *ngIf="!hasActivity"
                            [value]="pickupPlaceIdInInvSys.value">
                  {{pickupPlaceTitleInInvSys.value}}
                </mat-option>
                <mat-option *ngFor="let place of activity?.places?.pickupPlaces"
                            [value]="place.id"
                            [disabled]="isPickupPlaceOptionDisabled(place.id)">
                  {{place.title}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field fxFlex="1 1 33%"
                            fxFlex.lt-lg="grow"
                            *ngIf="pickupPlaceIdInInvSys.value == -2">
              <mat-label>Custom pickup place</mat-label>
              <input matInput
                     type="text"
                     formControlName="customPickupPlaceDescription"
                     placeholder="Enter custom address..."
                     required>
            </mat-form-field>
            <mat-checkbox color="primary"
                          fxLayout="row"
                          fxLayoutAlign="center center"
                          formControlName="skipDiscountForPickupAndDropoff">
              Skip discount for pickup/dropoff
            </mat-checkbox>
          </div>
        </div>
        <div fxFlex="1 1 100%">
          <p class="mat-h4"><strong>Dropoff</strong></p>
          <div fxLayout="row wrap"
               fxLayout.lt-lg="column">
            <mat-form-field fxFlex="1 1 33%"
                            fxFlex.lt-lg="grow">
              <mat-label>Dropoff selection type</mat-label>
              <mat-select formControlName="dropoffSelectionType"
                          (selectionChange)="onDropoffSelectionTypeChange($event.value)">
                <mat-option *ngFor="let type of pickupDropoffSelectionTypeEnum | keyvalue"
                            [value]="type.value"
                            [disabled]="isDropoffPlaceSelectionTypeDisabled(type.value)">
                  {{type.key}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field fxFlex="1 1 33%"
                            fxFlex.lt-lg="grow"
                            *ngIf="dropoffSelectionType.value === pickupDropoffSelectionTypeEnum.Fixed"
                            floatLabel="always">
              <mat-label>Fixed dropoff place</mat-label>
              <mat-select placeholder="Select a dropoff place"
                          formControlName="dropoffPlaceIdInInvSys"
                          (selectionChange)="onFixedDropoffChange($event.value)"
                          [compareWith]="comparePlaceOptionToSelection"
                          required>
                <mat-option *ngIf="!hasActivity"
                            [value]="dropoffPlaceIdInInvSys.value">
                  {{dropoffPlaceTitleInInvSys.value}}
                </mat-option>
                <mat-option *ngFor="let place of activity?.places?.dropoffPlaces"
                            [value]="place.id"
                            [disabled]="isDropoffPlaceOptionDisabled(place.id)">
                  {{place.title}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field fxFlex="1 1 33%"
                            fxFlex.lt-lg="grow"
                            *ngIf="dropoffPlaceIdInInvSys.value == -2">
              <mat-label>Custom dropoff place</mat-label>
              <input matInput
                     type="text"
                     formControlName="customDropoffPlaceDescription"
                     placeholder="Enter custom address..."
                     required>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div fxLayout="row"
           fxLayout.xs="column"
           fxLayoutAlign="space-between">
        <div fxFlex="1 1 100%">
          <p class="mat-h4"><strong>Extras</strong></p>
          <div fxLayout="row wrap"
               fxLayout.lt-lg="column">
            <mat-form-field fxFlex="1 1 33%"
                            fxFlex.lt-lg="grow">
              <mat-label>Extras selection type</mat-label>
              <mat-select formControlName="extraSelectionType"
                          (selectionChange)="onExtraSelectionTypeChange($event.value)">
                <mat-option *ngFor="let type of extraSelectionTypeEnum | keyvalue"
                            [value]="type.value"
                            [disabled]="isExtraSelectionTypeOptionDisabled(type.value)"
                            [matTooltip]="type.value !== extraSelectionTypeEnum.All ? extraSelectionTypeTooltip : ''">
                  {{type.key}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field fxFlex="1 1 33%"
                            fxFlex.lt-lg="grow"
                            *ngIf="extraSelectionType.value === extraSelectionTypeEnum.Selected">
              <mat-label>Extras</mat-label>
              <mat-select formControlName="extras"
                          multiple
                          required
                          (selectionChange)="onExtrasChange($event.value)"
                          [compareWith]="compareExtraOptionToSelection">
                <ng-container *ngIf="!hasActivity">
                  <mat-option *ngFor="let extra of extras.value"
                              [value]="extra">
                    {{extra.extraTitleInInvSys}}
                  </mat-option>
                </ng-container>
                <mat-option *ngFor="let extra of activity?.bookableExtras"
                            [value]="extra"
                            [disabled]="isExtraOptionDisabled(extra.id)">
                  {{extra.title}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-checkbox color="primary"
                          fxLayout="row"
                          fxLayoutAlign="center center"
                          formControlName="skipDiscountForExtras">
              Skip discount for extras
            </mat-checkbox>
          </div>
        </div>

        <div fxFlex="1 1 100%">
          <p class="mat-h4"><strong>Other</strong></p>
          <div fxLayout="row wrap"
               fxLayout.lt-lg="column">
            <mat-form-field fxFlex="1 1 33%"
                            fxFlex.lt-lg="grow"
                            floatLabel="always">
              <mat-label>Title</mat-label>
              <input matInput
                     formControlName="customTitle">
              <mat-hint align="start"><strong>This will override inventory system title.</strong></mat-hint>
            </mat-form-field>
            <mat-form-field fxFlex="1 1 33%"
                            fxFlex.lt-lg="grow"
                            floatLabel="always">
              <mat-label>Discount</mat-label>
              <input matInput
                     type="number"
                     formControlName="discount"
                     [placeholder]="bundleDiscount">
              <span matSuffix>&nbsp; %</span>
              <mat-hint align="start"><strong>This will override the global bundle discount (leave
                  blank to use global discount)</strong></mat-hint>
            </mat-form-field>
            <mat-form-field fxFlex="1 1 33%"
                            fxFlex.lt-lg="grow"
                            floatLabel="always">
              <mat-label>Latitude</mat-label>
              <input matInput
                     type="number"
                     formControlName="latitude">
            </mat-form-field>
            <mat-form-field fxFlex="1 1 33%"
                            fxFlex.lt-lg="grow"
                            floatLabel="always">
              <mat-label>Longitude</mat-label>
              <input matInput
                     type="number"
                     formControlName="longitude">
            </mat-form-field>
            <mat-checkbox color="primary"
                          fxFlex="1 1 33%"
                          fxLayout="row"
                          fxLayoutAlign="start center"
                          formControlName="attachVoucherToEmail">
              Attach voucher
            </mat-checkbox>
            <mat-checkbox color="primary"
                          fxFlex="1 1 33%"
                          fxLayout="row"
                          fxLayoutAlign="start center"
                          formControlName="hideDayNoForExtras">
              Hide extras day no.
            </mat-checkbox>
          </div>
        </div>
      </div>
    </div>
  </mat-expansion-panel>

  <button type="button"
          mat-icon-button
          matTooltip="Load item data from inventory system"
          (click)="onLoadItemData()"
          [disabled]="disabled"
          [class.button-spinner]="isActivityLoading">
    <mat-icon *ngIf="!hasActivity || isActivityLoading; else dataLoadDone">cloud_download</mat-icon>
    <ng-template #dataLoadDone>
      <mat-icon class="success-text">cloud_done</mat-icon>
    </ng-template>
  </button>
</div>
