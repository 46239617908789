<mat-form-field fxFlex="grow"
                floatLabel="always">
  <mat-label>Channel</mat-label>
  <mat-select [formControl]="bookingChannel"
              required
              [disabled]="channelsDropdownDisabled"
              (selectionChange)="onChannelChange($event.value)"
              [class.button-spinner]="!channels">
    <mat-option *ngFor="let channel of channels"
                [value]="channel.code">
      {{channel.title}}
    </mat-option>
  </mat-select>
  <mat-error>This field is required.</mat-error>
</mat-form-field>
