<div fxLayout="column">
  <div fxLayout="row"
       fxLayoutGap="10px">
    <mat-form-field>
      <mat-label>Search</mat-label>
      <input matInput
             placeholder="Enter text..."
             [disabled]="isDisabled"
             [(ngModel)]="filters.searchPhrase">
    </mat-form-field>
    <mat-form-field>
      <mat-label>Payment status</mat-label>
      <mat-select placeholder="Select status..."
                  multiple
                  [disabled]="isDisabled"
                  [(ngModel)]="filters.paymentStatuses">
        <mat-option *ngFor="let status of paymentStatuses"
                    [value]="status.id">{{status.title}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <input matInput
             [matDatepicker]="travelDateFromPicker"
             [disabled]="isDisabled"
             [(ngModel)]="filters.travelDateFrom"
             placeholder="Travel from">
      <mat-datepicker-toggle matSuffix
                             [for]="travelDateFromPicker"></mat-datepicker-toggle>
      <mat-datepicker #travelDateFromPicker></mat-datepicker>
    </mat-form-field>
    <mat-form-field>
      <input matInput
             [matDatepicker]="travelDateToPicker"
             [disabled]="isDisabled"
             [(ngModel)]="filters.travelDateTo"
             placeholder="Travel to">
      <mat-datepicker-toggle matSuffix
                             [for]="travelDateToPicker"></mat-datepicker-toggle>
      <mat-datepicker #travelDateToPicker></mat-datepicker>
    </mat-form-field>
  </div>

  <div fxLayout="row"
       fxLayoutGap="10px">
    <mat-form-field>
      <mat-label>Days before expiration</mat-label>
      <input matInput
             type="number"
             placeholder="Enter number..."
             [disabled]="isDisabled"
             [(ngModel)]="filters.daysBeforeExpiration">
    </mat-form-field>
    <mat-form-field>
      <mat-label>Creation method</mat-label>
      <mat-select placeholder="Select method..."
                  [disabled]="isDisabled"
                  [(ngModel)]="filters.creationMethod"
                  name="filters.creationMethod">
        <mat-option [value]="''"></mat-option>
        <mat-option *ngFor="let method of creationMethod | keyvalue"
                    [value]="method.value">{{method.key}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <input matInput
             [matDatepicker]="bookingDateFromPicker"
             [disabled]="isDisabled"
             [(ngModel)]="filters.bookingDateFrom"
             name="filters.bookingDateFrom"
             placeholder="Booking from">
      <mat-datepicker-toggle matSuffix
                             [for]="bookingDateFromPicker"></mat-datepicker-toggle>
      <mat-datepicker #bookingDateFromPicker></mat-datepicker>
    </mat-form-field>
    <mat-form-field>
      <input matInput
             [matDatepicker]="bookingDateTopicker"
             [disabled]="isDisabled"
             [(ngModel)]="filters.bookingDateTo"
             name="filters.bookingDateTo"
             placeholder="Booking to">
      <mat-datepicker-toggle matSuffix
                             [for]="bookingDateTopicker"></mat-datepicker-toggle>
      <mat-datepicker #bookingDateTopicker></mat-datepicker>
    </mat-form-field>
  </div>

  <div fxLayout="row"
       fxLayoutGap="10px"
       fxLayoutAlign="start center">
    <mat-form-field>
      <input matInput
             [matDatepicker]="dueDateFromPicker"
             [disabled]="isDisabled"
             [(ngModel)]="filters.dueDateFrom"
             placeholder="Due date from">
      <mat-datepicker-toggle matSuffix
                             [for]="dueDateFromPicker"></mat-datepicker-toggle>
      <mat-datepicker #dueDateFromPicker></mat-datepicker>
    </mat-form-field>
    <mat-form-field>
      <input matInput
             [matDatepicker]="dueDateToPicker"
             [disabled]="isDisabled"
             [(ngModel)]="filters.dueDateTo"
             placeholder="Due date to">
      <mat-datepicker-toggle matSuffix
                             [for]="dueDateToPicker"></mat-datepicker-toggle>
      <mat-datepicker #dueDateToPicker></mat-datepicker>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Booking type</mat-label>
      <mat-select placeholder="Select booking type..."
                  multiple
                  [disabled]="isDisabled"
                  [(ngModel)]="filters.disabled">
        <mat-option [value]="false">
          Enabled
        </mat-option>
        <mat-option [value]="true">
          Disabled
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div>
      <button type="submit"
              mat-stroked-button
              color="primary"
              [disabled]="isDisabled"
              [class.button-spinner]="isDisabled"
              (click)="onFilterClick()">
        Filter
      </button>
    </div>
  </div>
</div>
