import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PaymentLink } from '../../models/payment-link';
import { PaymentLinkModalData } from '../../models/payment-link-modal-data';
import { BookingEditApiService } from '../../services/booking-edit-api-service';

@Component({
  selector: 'payment-link-modal',
  templateUrl: './payment-link-modal.component.html',
  styleUrls: ['./payment-link-modal.component.scss']
})
export class PaymentLinkModalComponent implements OnInit {

  isLoading: boolean;

  constructor(
    public dialogRef: MatDialogRef<PaymentLinkModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PaymentLinkModalData,
    private bookingEditApiService: BookingEditApiService
  ) { }

  ngOnInit(): void {
  }

  onSubmit(): void {
    const paymentLinkData = {
      ...this.data,
      depositPercentage: this.data.depositPercentage === 0 ? undefined : this.data.depositPercentage
    } as PaymentLink;
    this.isLoading = true;

    if (this.data.id) {
      this.bookingEditApiService.editPaymentLink(
        paymentLinkData, this.data.bookingId, this.data.id
      ).subscribe((res) => this.dialogRef.close(res),
        (err) => this.isLoading = false,
        () => this.isLoading = false
      );
    } else
      this.bookingEditApiService.createPaymentLink(paymentLinkData, this.data.bookingId).subscribe(res => this.dialogRef.close(res),
        (err) => this.isLoading = false,
        () => this.isLoading = false
      );
  }

}
