import {Component, Inject, OnInit} from "@angular/core";
import { FormBuilder, FormControl } from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import { LANG_OPTIONS } from "../../models/form-constants";
import {TextEditorData} from "../../models/text-editor-data";
import { RichEditorConfig } from "./rich-editor-config";

@Component({
  selector: 'rich-text-editor',
  templateUrl: './rich-text-editor.html',
  styleUrls: ['./rich-text-editor.scss']
})
export class RichTextEditorComponent implements OnInit {
  public originalData: TextEditorData;

  public richEditorConfig = RichEditorConfig;

  constructor(
    public dialogRef: MatDialogRef<RichTextEditorComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: TextEditorData) {
    this.originalData = {...data};
  }

  languageEnum = LANG_OPTIONS;

  successModalForm = this.fb.group({
    facebookAppName: [''],
    lang: [''],
  });

  ngOnInit(): void {
    this.successModalForm.get('lang').setValue(LANG_OPTIONS[0].value);

    if (!!this.originalData.content) {
      return;
    }

    this.originalData
      .getDataCallback(this.originalData.siteId, this.language.value)
      .subscribe(x => {
        this.originalData = {
          ...this.originalData,
          content: x.text,
          facebookAppName: x.facebookAppName
        };

        this.facebookAppName.setValue(this.originalData.facebookAppName);
    });
  }

  get language(): FormControl {
    return this.successModalForm.get('lang') as FormControl;
  }

  get facebookAppName(): FormControl {
    return this.successModalForm.get('facebookAppName') as FormControl;
  }

  onSaveRichDialog() {
    const data = {
      text: this.originalData.content || '',
      facebookAppName: this.facebookAppName.value || ''
    } as any;

    this.originalData.updateDataCallback(
      this.originalData.siteId,
      this.language.value,
      data
    ).subscribe(_ => {});
  }

  onLangChange() {
    this.originalData
      .getDataCallback(this.originalData.siteId, this.language.value)
      .subscribe(x => {
        this.originalData = {
          ...this.originalData,
          content: x.text,
          facebookAppName: x.facebookAppName
        };

        this.facebookAppName.setValue(this.originalData.facebookAppName);
    });
  }

  onCancel() {
    this.dialogRef.close(this.originalData);
  }
}
