import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ExtraImage } from './models/ExtraImage';
import { ExtraImageTableRow } from './models/ExtraImageTableRow';
import { ExtraImagesApiService } from './services/extra-images-api.service';

@Component({
  selector: 'extra-images',
  templateUrl: './extra-images.component.html',
  styleUrls: ['./extra-images.component.scss']
})
export class ExtraImagesComponent implements OnInit {

  allExtraImages: ExtraImageTableRow[];
  filteredExtraImages: ExtraImageTableRow[];
  form: UntypedFormGroup;
  isLoading = true;

  get extraImagesFormArray(): UntypedFormArray {
    return this.form.get('extraImages') as UntypedFormArray;
  }

  constructor(private extraImagesApiService: ExtraImagesApiService, private fb: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.extraImagesApiService.getExtraImages().subscribe((res: ExtraImage[]) => {
      this.form = this.fb.group({
        extraImages: this.fb.array(res.map(i => this.getExtraImageFormGroup(i)))
      });
      this.allExtraImages = res as ExtraImageTableRow[];
      this.filteredExtraImages = res as ExtraImageTableRow[];
      this.isLoading = false;
    });
  }

  onFilter(searchPhrase: string): void {
    this.filteredExtraImages = this.allExtraImages.filter(i =>
      i.title?.includes(searchPhrase) ||
      i.flag?.includes(searchPhrase) ||
      i.keywords?.includes(searchPhrase) ||
      i.url.includes(searchPhrase)
    );
    this.form = this.fb.group({
      extraImages: this.fb.array(this.filteredExtraImages.map(i => this.getExtraImageFormGroup(i)))
    });
  }

  onAddExtraImage(): void {
    const lowestFakeId = Math.min(...this.allExtraImages.map(i => i.fakeId).filter(i => i !== undefined));

    const newExtraImage = {
      title: '',
      flag: '',
      keywords: [],
      url: '',
      isInEditState: true,
      fakeId: isNaN(lowestFakeId) || lowestFakeId === Infinity ? -1 : lowestFakeId - 1
    } as ExtraImageTableRow;

    const newExtraImageFormGroup = this.getExtraImageFormGroup(newExtraImage);
    newExtraImageFormGroup.markAllAsTouched();

    this.extraImagesFormArray.push(newExtraImageFormGroup);
    this.allExtraImages = [...this.allExtraImages, newExtraImage];
    this.filteredExtraImages = [...this.filteredExtraImages, newExtraImage];
  }

  onSaveExtraImage(extraImage: ExtraImageTableRow): void {
    const filteredArray = this.filteredExtraImages.slice(0);
    const allArray = this.allExtraImages.slice(0);

    if (extraImage.fakeId !== undefined && extraImage.fakeId < 0) {
      const indexInAllExtraImages = this.allExtraImages.findIndex(i => i.fakeId === extraImage.fakeId);
      allArray[indexInAllExtraImages] = extraImage;
      this.allExtraImages = allArray;

      const indexInFilteredExtraImages = this.filteredExtraImages.findIndex(i => i.fakeId === extraImage.fakeId);
      filteredArray[indexInFilteredExtraImages] = extraImage;
      this.filteredExtraImages = filteredArray;
    } else if (extraImage.id) {
      const indexInAllExtraImages = this.allExtraImages.findIndex(i => i.id === extraImage.id);
      allArray[indexInAllExtraImages] = extraImage;
      this.allExtraImages = allArray;

      const indexInFilteredExtraImages = this.filteredExtraImages.findIndex(i => i.id === extraImage.id);
      filteredArray[indexInFilteredExtraImages] = extraImage;
      this.filteredExtraImages = filteredArray;
    }
  }

  private getExtraImageFormGroup(extraImage: ExtraImage): UntypedFormGroup {
    return this.fb.group({
      title: [extraImage.title, Validators.required],
      flag: [extraImage.flag, Validators.required],
      url: [extraImage.url, Validators.required],
      keywords: [extraImage.keywords]
    });
  }
}
