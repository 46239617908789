import {
  HttpErrorResponse, HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ErrorIntercept implements HttpInterceptor {
  constructor(private snackBar: MatSnackBar) { }
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMessage = '';
          if (error.error instanceof ErrorEvent) {
            errorMessage = `Error: ${error.error.message}`;
          } else {
            const { Errors: errors } = error.error;
            if (errors) {
              errorMessage = errors.join('\n');
            }
            else {
              errorMessage = error.message;
            }
          }
          this.showErrorSnack(errorMessage);
          return throwError(errorMessage);
        })
      );
  }

  private showErrorSnack(message: string): void {
    this.snackBar.open(
      message,
      'Close',
      { duration: 4000, panelClass: ['error-snack'] }
    );
  }
}
