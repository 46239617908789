import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { BookingFailuresComponent } from './booking-failures.component';
import { BookingFailureLogsTableComponent } from './components/booking-failure-logs-table/booking-failure-logs-table.component';
import { BookingFailuresTableFiltersComponent } from './components/booking-failures-table-filters/booking-failures-table-filters.component';
import { BookingFailuresTableComponent } from './components/booking-failures-table/booking-failures-table.component';

@NgModule({
  declarations: [
    BookingFailuresComponent,
    BookingFailuresTableComponent,
    BookingFailureLogsTableComponent,
    BookingFailuresTableFiltersComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
  ]
})
export class BookingFailuresModule { }
