import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from '../app-routing.module';
import { SharedModule } from '../shared/shared.module';
import { BookingsTableFiltersComponent } from './components/bookings-table-filters/bookings-table-filters.component';
import { BookingsTableComponent } from './components/bookings-table/bookings-table.component';
import { SynchronizeBookingsModalComponent } from './components/synchronize-bookings-modal/synchronize-bookings-modal.component';
import { PaymentPortalComponent } from './payment-portal.component';
import { PaymentPortalApiService } from './services/payment-portal-api.service';

@NgModule({
  providers: [
    PaymentPortalApiService
  ],
  declarations: [
    PaymentPortalComponent,
    BookingsTableComponent,
    BookingsTableFiltersComponent,
    SynchronizeBookingsModalComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    AppRoutingModule,
    FlexLayoutModule,
    ReactiveFormsModule,
  ]
})
export class PaymentPortalModule { }
