import { Component, OnInit } from '@angular/core';
import { BookingFailure } from './models/booking-failure';
import { GetBookingFailuresRequestFilters } from './models/get-booking-failures-request-filters';
import { BookingFailuresApiService } from './services/booking-failures-api.service';

@Component({
  selector: 'booking-failures',
  templateUrl: './booking-failures.component.html',
  styleUrls: ['./booking-failures.component.scss']
})
export class BookingFailuresComponent implements OnInit {
  bookingFailures: BookingFailure[];
  isLoading: boolean;

  constructor(private bookingFailuresApiService: BookingFailuresApiService) { }

  ngOnInit(): void {
    this.getBookingFailuresRequest(new GetBookingFailuresRequestFilters());
  }

  onFilter(getBookingFailuresRequest?: GetBookingFailuresRequestFilters): void {
    this.getBookingFailuresRequest(getBookingFailuresRequest);
  }

  private getBookingFailuresRequest(getBookingFailuresRequest): void {
    this.isLoading = true;

    this.bookingFailuresApiService.getBookingFailures(getBookingFailuresRequest).subscribe((res) => {
      this.isLoading = false;
      this.bookingFailures = res;
    }, (err) => this.isLoading = false, () => this.isLoading = false);
  }
}
