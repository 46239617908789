import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { differenceInCalendarDays, format } from 'date-fns';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { InventorySystem } from 'src/app/shared/models/inventory-system.enum';
import { TitleCode } from 'src/app/shared/models/title-code';
import { environment } from 'src/environments/environment';
import { BookingForm } from '../models/booking-form';
import { BookingItem } from '../models/booking-item';
import { BookingRequestData } from '../models/booking-request-data';
import { Payment } from '../models/payment';
import { PaymentLink } from '../models/payment-link';
import { Sending } from '../models/sending';
import { TablePaymentLink } from '../models/table-payment-link';
import {BookingAccount} from "../models/booking-account";

@Injectable({
  providedIn: 'root'
})
export class BookingEditApiService {

  private serverUrl = environment.serverUrl;

  constructor(private http: HttpClient, private snackBar: MatSnackBar) { }

  getChannels(): Observable<TitleCode[]> {
    return this.http.get<TitleCode[]>(`${this.serverUrl}/api/booking/channel`);
  }

  getBooking(id: string): Observable<BookingForm> {
    return this.http.get<BookingForm>(`${this.serverUrl}/api/booking/${id}`).pipe(
      map(this.mutateBookingForm),
    );
  }

  getBookingFromInvSys(invSys: InventorySystem, bookingNo: string, channelCode: string): Observable<BookingForm> {
    return this.http.get<BookingForm>(`${this.serverUrl}/api/booking/${invSys}/${bookingNo}/${channelCode}`).pipe(
      map(this.mutateBookingForm),
    );
  }

  getBookingAccounts(): Observable<BookingAccount[]> {
    return this.http.get<BookingAccount[]>(`${this.serverUrl}/api/booking/AccountsWithChannels`);
  }

  saveBooking(booking: BookingRequestData): Observable<BookingForm> {
    const newBooking = {
      ...booking,
      items: booking.items.map(this.mutateBookingItem)
    };
    return this.http.post<BookingForm>(`${this.serverUrl}/api/booking`, newBooking).pipe(
      map(this.mutateBookingForm),
      tap(() => this.showSuccessSnack('Booking saved'))
    );
  }

  editBooking(booking: BookingRequestData): Observable<BookingForm> {
    const newBooking = {
      ...booking,
      items: booking.items.map(this.mutateBookingItem)
    };
    return this.http.put<BookingForm>(`${this.serverUrl}/api/booking/${booking.id}`, newBooking).pipe(
      map(this.mutateBookingForm),
      tap(() => this.showSuccessSnack('Booking saved'))
    );
  }

  createPaymentLink(paymentLink: PaymentLink, bookingId: number): Observable<TablePaymentLink> {
    return this.http.post<TablePaymentLink>(`${this.serverUrl}/api/booking/${bookingId}/paymentLink`, paymentLink).pipe(
      map((res) => ({ ...res, expirationDate: res.expirationDate ? new Date(res.expirationDate) : null })),
      tap(() => this.showSuccessSnack('Payment link created'))
    );
  }

  editPaymentLink(paymentLink: PaymentLink, bookingId: number, paymentLinkId: number): Observable<TablePaymentLink> {
    return this.http.put<TablePaymentLink>(`${this.serverUrl}/api/booking/${bookingId}/paymentLink/${paymentLinkId}`, paymentLink).pipe(
      map((res) => ({ ...res, expirationDate: res.expirationDate ? new Date(res.expirationDate) : null })),
      tap(() => this.showSuccessSnack('Payment link editted'))
    );
  }

  sendPaymentLink(bookingId: number, id: number): Observable<null> {
    return this.http.get<null>(`${this.serverUrl}/api/booking/${bookingId}/paymentLink/${id}/send`).pipe(
      tap(() => this.showSuccessSnack('Payment link sent'))
    );
  }

  enablePaymentLink(bookingId: number, id: number): Observable<null> {
    return this.http.put<null>(`${this.serverUrl}/api/booking/${bookingId}/paymentLink/${id}/enable`, null).pipe(
      tap(() => this.showSuccessSnack('Payment link enabled'))
    );
  }

  disablePaymentLink(bookingId: number, id: number): Observable<null> {
    return this.http.put<null>(`${this.serverUrl}/api/booking/${bookingId}/paymentLink/${id}/disable`, null).pipe(
      tap(() => this.showSuccessSnack('Payment link disabled'))
    );
  }

  getPaymentLinkSendings(bookingId: number, id: number): Observable<Sending[]> {
    return this.http.get<Sending[]>(`${this.serverUrl}/api/booking/${bookingId}/paymentLink/${id}/sendings`).pipe(
      map(res => res.map(i => ({ ...i, dateSent: format(new Date(i.dateSent), 'yyyy-MM-dd') }))),
    );
  }

  createBookingItem(bookingId: number, item: BookingItem): Observable<BookingForm> {
    const newItem = this.mutateBookingItem(item);
    return this.http.post<BookingForm>(`${this.serverUrl}/api/booking/${bookingId}/item`, newItem).pipe(
      map(this.mutateBookingForm),
      tap(() => this.showSuccessSnack('New item created'))
    );
  }

  editBookingItem(bookingId: number, itemId: number, item: BookingItem): Observable<BookingForm> {
    const newItem = this.mutateBookingItem(item);
    return this.http.put<BookingForm>(`${this.serverUrl}/api/booking/${bookingId}/item/${itemId}`, newItem).pipe(
      map(this.mutateBookingForm),
      tap(() => this.showSuccessSnack('Existing item updated'))
    );
  }

  deleteBookingItem(bookingId: number, itemId: number): Observable<BookingForm> {
    return this.http.delete<BookingForm>(`${this.serverUrl}/api/booking/${bookingId}/item/${itemId}`).pipe(
      map(this.mutateBookingForm),
      tap(() => this.showSuccessSnack('Item deleted'))
    );
  }

  private showSuccessSnack(message: string): void {
    this.snackBar.open(
      message,
      'Close',
      { duration: 2000, panelClass: ['success-snack'] }
    );
  }

  private mutateBookingItem(item: BookingItem): BookingItem {
    return {
      ...item,
      vatIncluded: !item.vatRate ? null : !!item.vatIncluded
    };
  }

  private mutateBookingForm(form: BookingForm): BookingForm {
    return {
      ...form,
      travelDate: new Date(form.travelDate),
      dueDate: differenceInCalendarDays(new Date(form.travelDate), new Date(form.dueDate)),
      bookingDate: new Date(form.bookingDate),
      remainingBalanceEmailDate: differenceInCalendarDays(new Date(form.travelDate), new Date(form.remainingBalanceEmailDate)),
      paymentLinks: form.paymentLinks.map(i => ({ ...i, expirationDate: i.expirationDate ? new Date(i.expirationDate) : null })),
      payments: form.payments.map<Payment>(i => ({ ...i, paymentDate: new Date(i.paymentDate) }))
    };
  }
}
