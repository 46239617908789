import { PaymentLink } from './payment-link';

export class PaymentLinkModalData extends PaymentLink {
  id: number;
  bookingId: number;

  constructor(id: number) {
    super();
    this.bookingId = id;
  }
}
