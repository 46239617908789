import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs/internal/Observable';
import { map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Site } from '../models/site';

@Injectable({
  providedIn: 'root',
})
export class SiteApiService {
  private serverUrl = environment.serverUrl;

  constructor(private http: HttpClient, private snackBar: MatSnackBar) {}

  getSite(id?: string): Observable<Site[]> {
    let params = new HttpParams();

    return this.http
      .get<Site[]>(`${this.serverUrl}/api/sites`, { params })
      .pipe(
        map((res) => {
          if (id) {
            return res.filter(x => x.id === id).map(this.mapApiSettings);
          }

          return res.map(this.mapApiSettings);
        }));
  }

  private mapApiSettings(apiSettings: Site): Site {
    return {
      ...apiSettings,
      createdOn: new Date(apiSettings.createdOn),
    };
  }

  deleteSite(id: string): Observable<object> {
    return this.http.delete<object>(`${this.serverUrl}/api/site/${id}`).pipe(
      tap((x) => this.showSuccessSnack('Site deleted'))
    );
  }

  createSite(id: Site): Observable<Site> {
    return this.http.post<Site>(`${this.serverUrl}/api/sites`, [id]).pipe(
      tap((x) => this.showSuccessSnack('Site created'))
    );
  }

  private showSuccessSnack(message: string): void {
    this.snackBar.open(
      message,
      'Close',
      { duration: 2000, panelClass: ['success-snack'] }
    );
  }
}
