import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { format } from 'date-fns';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { BookingFailure } from '../models/booking-failure';
import { GetBookingFailuresRequestFilters } from '../models/get-booking-failures-request-filters';

@Injectable({
  providedIn: 'root'
})
export class BookingFailuresApiService {
  private serverUrl = environment.serverUrl;

  constructor(private http: HttpClient) { }

  getBookingFailures(getBookingFailuresRequest: GetBookingFailuresRequestFilters): Observable<BookingFailure[]> {
    let params = new HttpParams();

    if (getBookingFailuresRequest) {
      const {
        createdOnFrom,
        createdOnTo,
        bookingRefNumber,
        bookingRetryStatuses
      } = getBookingFailuresRequest;

      if (createdOnFrom)
        params = params.set('createdOnFrom', format(createdOnFrom, 'yyyy-MM-dd'));

      if (createdOnTo)
        params = params.set('createdOnTo', format(createdOnTo, 'yyyy-MM-dd'));

      if (bookingRetryStatuses && !!bookingRetryStatuses.length) {
        bookingRetryStatuses.forEach((status, index) => {
          params = params.append(`bookingRetryStatuses[${index}]`, `${status}`);
        });
      }
      if (bookingRefNumber)
        params = params.set('bookingRefNumber', bookingRefNumber);
    }

    return this.http.get<BookingFailure[]>(`${this.serverUrl}/api/BookingRetry`, { params }).pipe(
      map(res => res.map(this.mapBookingFailure))
    );
  }

  private mapBookingFailure(bookingFailure: BookingFailure): BookingFailure {
    return {
      ...bookingFailure,
      createdOn: new Date(bookingFailure.createdOn),
      lastRetryDate: new Date(bookingFailure.lastRetryDate),
      logs: bookingFailure.logs.map((log) => ({ ...log, date: new Date(log.date) }))
    };
  }
}
