<ng-template #loading>
  <div class="spinner-container">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>

<div class="table-header-container"
     fxLayout="row">
  <bookings-table-filters [isDisabled]="isLoading"
                          (filtered)="onFilter($event)">
  </bookings-table-filters>
  <div fxFlex="grow"
       fxLayout="row"
       fxLayoutAlign="end end"
       fxLayoutGap="10px">
    <button mat-raised-button
            color="primary"
            matTooltip="Synchronize bookings"
            (click)="onSynchronizeBookings()">
      Synchronize
    </button>
    <button mat-raised-button
            color="accent"
            matTooltip="Create new booking"
            [routerLink]="['new']">
      NEW
    </button>
  </div>
</div>

<bookings-table *ngIf="!isLoading; else loading"
                [bookings]="bookings">
</bookings-table>
