<p class="mat-h2"><strong>Payments</strong></p>
<div class="mat-elevation-z8 sub-section">
  <mat-table [dataSource]="dataSource"
             aria-label="Payments">
    <ng-container matColumnDef="id">
      <mat-header-cell *matHeaderCellDef>Id</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.id}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="paymentDate">
      <mat-header-cell *matHeaderCellDef>Payment date</mat-header-cell>
      <mat-cell *matCellDef="let row">{{format(row.paymentDate, 'yyyy-MM-dd HH:mm')}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="paidAmount">
      <mat-header-cell *matHeaderCellDef>Paid amount</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.paidAmount}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="currency">
      <mat-header-cell *matHeaderCellDef>Currency</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.currency}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="transactionId">
      <mat-header-cell *matHeaderCellDef>Transaction id</mat-header-cell>
      <mat-cell *matCellDef="let row">{{row.transactionId}}</mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>
</div>
