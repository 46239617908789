import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BundlesTableFilter } from '../../models/bundles-table-filter';
import { BundleCategory } from 'src/app/shared/models/bundle-category';
import { SharedApiService } from 'src/app/shared/services/shared-api.service';

@Component({
  selector: 'bundles-table-filters',
  templateUrl: './bundles-table-filters.component.html',
  styleUrls: ['./bundles-table-filters.component.scss']
})
export class BundlesTableFiltersComponent implements OnInit {
  @Input() isDisabled: boolean;
  @Output() filtered = new EventEmitter<BundlesTableFilter>();

  filter = {} as BundlesTableFilter;
  bundleCategories: BundleCategory[];

  constructor(private sharedApiService: SharedApiService) { }

  ngOnInit(): void {
    this.sharedApiService.getBundleCategories().subscribe(res => this.bundleCategories = res);
  }

  onFilter(): void {
    this.filtered.emit(this.filter);
  }
}
