import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from '../app-routing.module';
import { SharedModule } from '../shared/shared.module';
import { BookingEditComponent } from './booking-edit.component';
import { BookingEditFormComponent } from './components/booking-edit-form/booking-edit-form.component';
import { ChannelsComponent } from './components/channels/channels.component';
import { FormInitModalComponent } from './components/form-init-modal/form-init-modal.component';
import { PaymentLinkModalComponent } from './components/payment-link-modal/payment-link-modal.component';
import { PaymentLinksTableComponent } from './components/payment-links-table/payment-links-table.component';
import { PaymentsTableComponent } from './components/payments-table/payments-table.component';
import { SendingsTableComponent } from './components/sendings-table/sendings-table.component';
import { BookingEditApiService } from './services/booking-edit-api-service';

@NgModule({
  providers: [
    BookingEditApiService
  ],
  declarations: [
    BookingEditComponent,
    BookingEditFormComponent,
    FormInitModalComponent,
    ChannelsComponent,
    PaymentLinksTableComponent,
    PaymentsTableComponent,
    PaymentLinkModalComponent,
    SendingsTableComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    AppRoutingModule,
    FlexLayoutModule,
    ReactiveFormsModule,
  ]
})
export class BookingEditModule { }
