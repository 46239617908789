import { Component, Input } from '@angular/core';
import {
  FormControl,
  FormGroup,
} from '@angular/forms';
import { ANALYTICS_CURRENCY_OPTIONS, BOOKING_SYSTEM_OPTIONS, CULTURE_CODES, CURRENCY_OPTIONS, LANG_OPTIONS } from '../../models/form-constants';

@Component({
  selector: 'calendar-card-form',
  templateUrl: './calendar-card-form.component.html',
  styleUrls: ['../channel-edit-form/channel-edit-form.component.scss'],
})
export class CalendarEditFormComponent {
  @Input() calendarSettingsForm: FormGroup;
  @Input() onSubmit: () => void;

  bookingSystemEnum = BOOKING_SYSTEM_OPTIONS;
  currencyEnum = CURRENCY_OPTIONS;
  languageEnum = LANG_OPTIONS;
  culturesEnum = CULTURE_CODES;
  analyticsCurrencies = ANALYTICS_CURRENCY_OPTIONS;

  constructor() {}

  get language(): FormControl {
    return this.calendarSettingsForm.get('lang') as FormControl;
  }

  getCultureCodes() {
    return this.culturesEnum[this.language.value];
  }
}
