import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserModule } from '@angular/platform-browser';
import { BooleanModalComponent } from './components/boolean-modal/boolean-modal.component';
import { ShouldLeaveFormModalComponent } from './components/should-leave-form-modal/should-leave-form-modal.component';
import { TagComponent } from './components/tag/tag.component';
import { MaterialModule } from './modules/material.module';

@NgModule({
  declarations: [
    BooleanModalComponent,
    TagComponent,
    ShouldLeaveFormModalComponent
  ],
  imports: [
    MaterialModule,
    FlexLayoutModule,
    BrowserModule
  ],
  exports: [
    BooleanModalComponent,
    MaterialModule,
    TagComponent
  ]
})
export class SharedModule { }
