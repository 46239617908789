import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { format } from 'date-fns';
import { Payment } from '../../models/payment';

@Component({
  selector: 'payments-table',
  templateUrl: './payments-table.component.html',
  styleUrls: ['./payments-table.component.scss']
})
export class PaymentsTableComponent implements OnInit {
  @Input() payments: Payment[];
  @ViewChild(MatTable) table: MatTable<Payment>;

  dataSource: MatTableDataSource<Payment>;
  displayedColumns = ['id', 'paymentDate', 'paidAmount', 'currency', 'transactionId'];
  format = format;

  constructor() { }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource<Payment>(this.payments);
  }
}
