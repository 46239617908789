import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { BooleanModalComponent } from '../shared/components/boolean-modal/boolean-modal.component';
import { BooleanModalData } from '../shared/models/boolean-modal-data';
import { FormInitModalComponent } from './components/form-init-modal/form-init-modal.component';
import { Booking } from './models/booking';
import { FormInitModalData } from './models/form-init-modal-data';
import { BookingEditApiService } from './services/booking-edit-api-service';
import { SharedApiService } from '../shared/services/shared-api.service';

@Component({
  selector: 'booking-edit',
  templateUrl: './booking-edit.component.html',
  styleUrls: ['./booking-edit.component.scss']
})
export class BookingEditComponent implements OnInit {

  booking: Booking;
  isLoading: boolean;
  isBookingDisabledLoading: boolean;
  routeId: string = this.route.snapshot.paramMap.get('id');

  constructor(
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private location: Location,
    private bookingEditApiService: BookingEditApiService,
    private sharedApiService: SharedApiService
  ) { }

  ngOnInit(): void {
    if (this.routeId === 'new') {
      const data = new FormInitModalData();

      const dialogRef = this.dialog.open(FormInitModalComponent, {
        width: '360px',
        data
      });

      dialogRef.afterClosed().subscribe((res: Booking) => {
        if (!res)
          this.location.back();
        else
          this.booking = res;
      });
    } else {
      this.isLoading = true;

      this.bookingEditApiService.getBooking(this.routeId).subscribe(res => {
        this.isLoading = false;
        this.booking = res;
      });
    }
  }

  onBookingActiveToggle(currentvalue: boolean) {
    if (!!this.booking.id) {
      if (!currentvalue)
        this.enableBooking(this.booking.id);
      else {
        const data = new BooleanModalData(`Are you sure you want to disable this booking?`);
        const dialogRef = this.dialog.open(BooleanModalComponent, {
          width: '360px',
          data
        });

        dialogRef.afterClosed().subscribe((shouldDisable: boolean) => {
          if (shouldDisable)
            this.disableBooking(this.booking.id);
        });
      }
    } else
      this.booking.disabled = !currentvalue;
  }

  onBookingEditFormSubmit(booking: Booking){
    this.booking = booking;
  }

  private enableBooking(id: number): void {
    this.isBookingDisabledLoading = true;

    this.sharedApiService.enableBooking(id).subscribe(() => {
      this.isBookingDisabledLoading = false;
      this.booking.disabled = false;
    });
  }

  private disableBooking(id: number): void {
    this.isBookingDisabledLoading = true;

    this.sharedApiService.disableBooking(id).subscribe(() => {
      this.isBookingDisabledLoading = false;
      this.booking.disabled = true;
    });
  }
}
