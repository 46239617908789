export const environment = {
  production: false,
  serverUrl: 'https://widgets-dev.planner.is',
  clientId: '4556b092-370d-4c00-8bb0-6065084b2d29',
  authority:
    'https://login.microsoftonline.com/aa1fa4ce-6b3d-400c-9760-2f3567fb5327',
  scopes: {
    methodsUse: ['api://0aace1b8-4812-48eb-af13-9f5188373448/Methods.Use'],
  },
};
