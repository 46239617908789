import { Channel } from 'src/app/shared/models/channel.enum';
import { Currency } from 'src/app/shared/models/currency.enum';
import { IdTitle } from 'src/app/shared/models/id-title';
import { InventorySystem } from 'src/app/shared/models/inventory-system.enum';
import { BookingItem } from './booking-item';
import { Payment } from './payment';
import { TablePaymentLink } from './table-payment-link';
import { BookingAccount } from './booking-account';
import { PaymentGateway } from 'src/app/shared/models/payment-gateways.enum';

export abstract class Booking {
  id: number;
  travelDate: Date;
  bookingDate = new Date();
  channel: Channel;
  totalAmount: number;
  amountExclVat: number;
  vatAmount: number;
  paidAmount = 0;
  currency: Currency = Currency.IcelandicKrona;
  pricingCurrency: Currency = Currency.IcelandicKrona;
  customerName: string;
  customerEmail: string;
  bookingRefNumberInInvSystem: string;
  bookingRefNumber: string;
  inventorySystem: InventorySystem;
  bookingAccount: BookingAccount;
  salesPerson?: string;
  salesPersonEmail?: string;
  cancelled: boolean;
  disabled: boolean;
  manuallyCreated: boolean;
  canBeSynced: boolean;
  paymentStatus: IdTitle;
  paymentLinks: TablePaymentLink[];
  payments: Payment[];
  paymentGateway: PaymentGateway;
  title: string;
  items: BookingItem[];
  showInvoiceLinesFromInvSys?: boolean;
  autoCancelInInvSys?: boolean;
  buttonDisabled?: boolean;
}
